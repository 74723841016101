import { RouteRecordRaw } from "vue-router";
import MeansOfPayment from "@/views/config/financial/meansofpayment/Index.vue";
import MeansOfPayments from "@/views/config/financial/meansofpayment/List.vue";
import FinancialMenu from "@/components/menu/FinancialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/financial/meansofpayment/:id",
  name: "MeansOfPayment",
  components: {
    default: MeansOfPayment,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/financial/meansofpayment/list",
  name: "MeansOfPayments",
  components: {
    default: MeansOfPayments,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
