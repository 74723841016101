import { RouteRecordRaw } from "vue-router";

import PerformingPhysician from "./performingphysician";
import RequestingPhysician from "./requestingphysician";
import Partnership from "./partnership";

const itens: Array<RouteRecordRaw> = [
  ...PerformingPhysician,
  ...RequestingPhysician,
  ...Partnership,
];
export default itens;
