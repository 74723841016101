import { API_URL } from "@/constants/env";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import { Gift } from "@/models/interfaces/commercial/gift.interface";

export const update = async (id: string, entity: Gift): Promise<Gift[]> => {
  const r = (await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity)))
    .data;

  return r;
};
