import { API_URL } from "@/constants/env";
import { MedicalCertificate } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";
import axios from "axios";

export const getMedicalCertificate = async (
  id: string,
  customer: string
): Promise<MedicalCertificate> => {
  const credential = { ...store.state.login.credential };

  const url = `${API_URL}/prontuario/getatestado?id=${id}&paciente=${customer}&profissional= + ${credential.userId}`;

  const entity: MedicalCertificate = {
    description: "",
    text: "",
  };

  entity.text = (await axios.get(url)).data;

  return entity;
};
