import {
  ProductOrigin,
  ProductOriginState,
} from "@/models/interfaces/shared/productorigin.interface";

const productOrigin: ProductOrigin = {
  id: "",
  description: "",
  code: "",
};

const productOrigins: Array<ProductOrigin> = [];

export const state: ProductOriginState = {
  productOrigin,
  productOrigins,
};
