import {
  Reason,
  ReasonState,
} from "@/models/interfaces/schedule/reason.interface";

const reason: Reason = {
  id: "",
  description: "",
};

const reasons: Array<Reason> = [];

export const state: ReasonState = {
  reason,
  reasons,
};
