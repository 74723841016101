import {
  PrescriptionState,
  Prescription,
} from "@/models/interfaces/medicalconsultation/prescription.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PrescriptionState> = {
  setPrescriptions(state, payload: Prescription[]) {
    state.prescriptions = payload;
  },

  setPrescription(state, payload: Prescription) {
    state.prescription = payload;
  },

  insertPrescription(state, payload: Prescription) {
    state.prescription = payload;
  },

  removePrescription(state, payload: Prescription) {
    state.prescription = payload;
  },

  updatePrescription(state, payload: Prescription) {
    state.prescription = payload;
  },
};
