import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Service,
  ServiceState,
} from "@/models/interfaces/commercial/service.interface";

export const getters: GetterTree<ServiceState, RootState> = {
  getService(state): Service {
    return state.service;
  },
};
