import messages from "@/helpers/validate/messages";
import * as yup from "yup";
import store from "@/store";

export default yup.object({
  payee: yup
    .object()
    .test(
      "payee",
      messages.compile({ id: "required", value: "Favorecido" }),
      (value: any) => {
        const result = !!value?.id;
        console.log(value);
        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
  competitionDate: yup
    .string()
    .test(
      "competitionDate",
      messages.compile({ id: "required", value: "Data competência" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  financialCategory: yup
    .number()
    .test(
      "financialCategory",
      messages.compile({ id: "required", value: "Categoria" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  description: yup
    .string()
    .test(
      "description",
      messages.compile({ id: "required", value: "Descrição" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  duedate: yup
    .string()
    .test(
      "duedate",
      messages.compile({ id: "required", value: "Data vencimento" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  installmentvalue: yup
    .number()
    .test(
      "installmentvalue",
      messages.compile({ id: "required", value: "Valor" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
});
