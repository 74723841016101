import {
  Loading,
  LoadingState,
} from "@/models/interfaces/shared/loading.interface";

const loading: Loading = {
  value: false,
};

export const state: LoadingState = {
  loading,
};
