import { RouteRecordRaw } from "vue-router";
import FinancialCategory from "@/views/config/financial/financialcategory/Index.vue";
import FinancialCategories from "@/views/config/financial/financialcategory/List.vue";
import FinancialMenu from "@/components/menu/FinancialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/financial/financialcategory/:id",
  name: "FinancialCategory",
  components: {
    default: FinancialCategory,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/financial/financialcategory/list",
  name: "FinancialCategories",
  components: {
    default: FinancialCategories,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
