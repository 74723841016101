import {
  CivilStatusState,
  CivilStatus,
} from "@/models/interfaces/shared/civilstatus.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CivilStatusState> = {
  setCivilStatuss(state, payload: CivilStatus[]) {
    state.civilStatuss = payload;
  },

  setCivilStatus(state, payload: CivilStatus) {
    state.civilStatus = payload;
  },
};
