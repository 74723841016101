import { RouteRecordRaw } from "vue-router";
import StockTransfer from "../../views/material/stocktransfer/Index.vue";
import StockTransfers from "../../views/material/stocktransfer/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/stock-transfer",
  name: "StockTransfer",
  component: StockTransfer,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/stock-transfer/list",
  name: "StockTransfers",
  component: StockTransfers,
  beforeEnter: Auth,
});

export default itens;
