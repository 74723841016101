import {
  PerformingPhysician,
  PerformingPhysicianState,
} from "@/models/interfaces/billing/performingphysician.interface";

const performingPhysician: PerformingPhysician = {
  id: "",
  description: "",
};

const performingPhysicians: Array<PerformingPhysician> = [];

export const state: PerformingPhysicianState = {
  performingPhysician,
  performingPhysicians,
};
