import { API_URL } from "@/constants/env";
import { Purchase } from "@/models/interfaces/material/purchase.interface";
import axios from "axios";
import { treatedData } from "./treateddata.service";

export const get = async (id: string): Promise<Purchase> => {
  if (id == "new") {
    const entity: Purchase = {
      /* purchase */
      id: "",
      storeId: undefined,
      supplierId: undefined,
      note: "",
      purchasedate: "",
      userId: undefined,
      Id: 0,
      Itens: [],
      ContasAPagar: [],
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/compra/getcompra?id=${id}`)).data;

  return treatedData(entity);
};
