import { RouteRecordRaw } from "vue-router";
import Room from "@/views/config/administration/room/Index.vue";
import Rooms from "@/views/config/administration/room/List.vue";
import AdministrationMenu from "@/components/menu/AdministrationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/administration/room/:id",
  name: "Room",
  components: {
    default: Room,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/administration/room/list",
  name: "Rooms",
  components: {
    default: Rooms,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
