import {
  ReasonState,
  Reason,
} from "@/models/interfaces/schedule/reason.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ReasonState> = {
  setReasons(state, payload: Reason[]) {
    state.reasons = payload;
  },

  setReason(state, payload: Reason) {
    state.reason = payload;
  },

  insertReason(state, payload: Reason) {
    state.reason = payload;
  },

  removeReason(state, payload: Reason) {
    state.reason = payload;
  },

  updateReason(state, payload: Reason) {
    state.reason = payload;
  },
};
