import {
  BlockState,
  Block,
} from "@/models/interfaces/schedule/block.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<BlockState> = {
  setBlocks(state, payload: Block[]) {
    state.blocks = payload;
  },

  setBlock(state, payload: Block) {
    state.block = payload;
  },

  insertBlock(state, payload: Block) {
    state.block = payload;
  },

  removeBlock(state, payload: Block) {
    state.block = payload;
  },

  updateBlock(state, payload: Block) {
    state.block = payload;
  },
};
