import {
  DailyCashFlow,
  CashManagementState,
} from "@/models/interfaces/financial/cashmanagement.interface";

const dailyCashFlow: DailyCashFlow = {
  id: "0",
  dateofcompetence: "",
  openingvalue: 0,
  closingdate: undefined,
  responsibleuserId: undefined,
  userresponsibleclosingId: undefined,
  moneylaunch: [],
  error: "",
};

const dailyCashFlows: Array<DailyCashFlow> = [];

export const state: CashManagementState = {
  dailyCashFlow,
  dailyCashFlows,
};
