import { API_URL } from "@/constants/env";
import { WorkOrder } from "@/models/interfaces/commercial/workorder.interface";
import axios from "axios";

export const get = async (id: string): Promise<WorkOrder> => {
  if (id == "new") {
    const entity: WorkOrder = {
      id: "",
      serialnumber: "",
      newserialnumber: undefined,
      productdescription: "",
      productwarranty: false,
      expecteddate: undefined,
      deliverydate: undefined,
      serviceId: 0,
      storeId: 0,
      userId: 0,
      saleId: 0,
      ordernote: [],
      orderAuthorization: undefined,
      internalorder: undefined,
      customerorder: undefined,
      Serie: "",
      Descricao: "",
      Id: "",
      receiptcode: "",
    };
    return entity;
  }
  const url = `${API_URL}/ordemservico?id=${id}`;

  const r = await (await axios.get(url)).data;

  /*   console.log(r); */

  return r;
};
