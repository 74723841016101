import { API_URL } from "@/constants/env";
import store from "@/store";
import axios from "axios";

export const getByNameForAutoComplete = async (
  name: string
): Promise<any[]> => {
  if (name.length > 2 || Number(name)) {
    const credential = { ...store.state.login.credential };
    const list = (
      await axios.get(
        `${API_URL}/paciente?cliente=${credential.tenantId}&pos=1&tipoRegistro=Ativo&filtro=${name}`
      )
    ).data;

    const customers: string[] = list.map((item: any) => {
      const entity = {
        id: item.Id,
        idbycustomer: item.IdporClienteNotePro,
        name: item.Nome,
        description: `${item.IdporClienteNotePro} - ${item.Nome}`,
        scheduled: null,
        idCriptografado: item.IdCriptografado,
      };
      return entity;
    });
    /*     {  "id": 2459019, "status": 1 } */

    return customers;
  }
  return [];
};
