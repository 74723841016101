import { API_URL } from "@/constants/env";
import { Voucher } from "@/models/interfaces/commercial/voucher.interface";
import axios from "axios";

export const get = async (id: string): Promise<Voucher> => {
  if (id == "new") {
    const entity: Voucher = {
      id: "",
      discountinreais: 0,
      note: "",
      codevoucher: "",
      storeId: 0,
      customerId: 0,
      requestuserId: 0,
      responseuserId: 0,
      voucheritem: [],
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/cupomdesconto?id=${id}`)).data;

  entity.voucheritem = (
    await axios.get(`${API_URL}/cupomdesconto/getitens?voucher=${entity.Id}`)
  ).data;

  return entity;
};
