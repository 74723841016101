import { Customer } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";

export const prepareData = (entity: Customer) => {
  const credential = { ...store.state.login.credential };

  const {
    Id,
    //  birthdate,
    // finalconsumer,
    contacts,
    documents,
    address,
    addressnf,
    // cpf,
    ...updateEntity
  } = entity;

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  const saveContact = {
    TelefoneResidencial: "",
    TelefoneCelular: "",
    Email: "",
  };
  for (let i = 0; i < contacts.length; i++) {
    const c = contacts[i];
    if (c.id == 1) saveContact.TelefoneCelular = c.description;
    if (c.id == 2) saveContact.TelefoneResidencial = c.description;
    if (c.id == 5) saveContact.Email = c.description;
  }

  const saveDocument = {
    CarteiraIdentidade: "",
    CartaoNacionalSaude: "",
  };
  for (let i = 0; i < documents.length; i++) {
    const d = documents[i];
    if (d.id == "1") saveDocument.CarteiraIdentidade = d.description;
    if (d.id == "2") saveDocument.CartaoNacionalSaude = d.description;
  }

  const saveEntity = {
    ...updateEntity,

    Id: id,
    IdCliente,
    usuario,

    /*  Cpf: cpf ? cpf : null, */

    Cep: address?.zipcode,
    UfId: address?.stateId,
    CidadeId: address?.cityId,
    Bairro: address?.neighborhood,
    Logradouro: address?.street,
    Numero: address?.number,
    Complemento: address?.complement,

    CepNf: addressnf?.zipcode,
    UfNfId: addressnf?.stateId,
    CidadeNfId: addressnf?.cityId,
    BairroNf: addressnf?.neighborhood,
    LogradouroNf: addressnf?.street,
    NumeroNf: addressnf?.number,
    ComplementoNf: addressnf?.complement,

    TelefoneResidencial: saveContact.TelefoneResidencial,
    TelefoneCelular: saveContact.TelefoneCelular,
    Email: saveContact.Email,

    CarteiraIdentidade: saveDocument.CarteiraIdentidade,
    CartaoNacionalSaude: saveDocument.CartaoNacionalSaude,
  };

  return saveEntity;
};
