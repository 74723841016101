import { Gender } from "@/models/interfaces/shared/gender.interface";

export const constants = {
  SET_GENDERS: "setGenders",
  SET_GENDER: "setGender",
};

export const data: Gender[] = [
  { id: "F", description: "Feminino" },
  { id: "M", description: "Masculino)" },
];
