import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PatientRecord,
  PatientRecordState,
} from "@/models/interfaces/medicalconsultation/patientrecord.interface";

export const getters: GetterTree<PatientRecordState, RootState> = {
  getPatientRecord(state): PatientRecord {
    return state.patientrecord;
  },
};
