import {
  Fiscal,
  FiscalState,
} from "@/models/interfaces/commercial/fiscal.interface";

const fiscal: Fiscal = {
  id: "",
  description: "",
};

const fiscals: Array<Fiscal> = [];

export const state: FiscalState = {
  fiscal,
  fiscals,
};
