import {
  Block,
  BlockState,
} from "@/models/interfaces/schedule/block.interface";

const block: Block = {
  id: "",
  description: "",
};

const blocks: Array<Block> = [];

export const state: BlockState = {
  block,
  blocks,
};
