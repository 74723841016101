import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  AccountReceivable,
  AccountReceivableState,
} from "@/models/interfaces/financial/accountreceivable.interface";

export const getters: GetterTree<AccountReceivableState, RootState> = {
  getAccountReceivable(state): AccountReceivable {
    return state.accountReceivable;
  },
};
