import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Inventory,
  InventoryState,
} from "@/models/interfaces/material/inventory.interface";
import { InventoryService as service } from "@/services/material/inventory";
import { constants } from "@/constants/material/inventory";
import { getSerialNumber } from "@/services/material/inventory/getserialnumber.service";

export const actions: ActionTree<InventoryState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Inventory[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_INVENTORIES, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Inventory) => {
      commit(constants.SET_INVENTORY, res);
    });
  },

  areThereInvetory({ commit }, { id, serial, unit, amount }): Promise<any[]> {
    return service.areThereInvetory(id, serial, unit, amount);
  },
};
