import { DocumentType } from "@/models/interfaces/shared/documenttype.interface";

export const constants = {
  SET_DOCUMENTTYPES: "setDocumentTypes",
  SET_DOCUMENTTYPE: "setDocumentType",
};

export const data: DocumentType[] = [
  { id: "1", description: "Carteira de identidade" },
  /*   { id: "2", description: "CPF)" }, */
  { id: "2", description: "CNS" },
  /*  { id: "3", description: "Matricula do convênio" }, */
  { id: "3", description: "Conselho profissional" },
];
