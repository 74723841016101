import useLocalStorage from "@/composables/localStorage";
import { data } from "@/constants/shared/documenttype";
import { DocumentType } from "@/models/interfaces/shared/documenttype.interface";

const storage = useLocalStorage("documentType", data);

const getAll = async (): Promise<DocumentType[]> => storage.getAll();

const get = async (id: string): Promise<DocumentType> => storage.get(id);

const getAllExcept = async (ids: string[]): Promise<DocumentType[]> => {
  const list: DocumentType[] = await storage.getAll();

  const allExcept: DocumentType[] = list.filter(item => !ids.includes(item.id));
  return allExcept;
};

export const DocumentTypeService = {
  getAll,
  get,
  getAllExcept,
};
