import store from "@/store";
import { useDate } from "@/composables/date";
import { Gift } from "@/models/interfaces/commercial/gift.interface";

export const prepareData = (entity: Gift) => {
  const credential = { ...store.state.login.credential };
  console.log(entity);
  const items = entity.saleitem.reduce((resultado: any, item) => {
    const r = {
      Quantidade: 0,
      Valor: 0,
      Desconto: 0,
      ProdutoId: 0,
      Serie: "",
      VendaId: "",
    };

    r.Quantidade = item.Quantidade;
    r.Valor = Number(item.Valor);
    r.Desconto = item.Desconto;
    r.ProdutoId = item.id ? item.id : item.ProdutoId;
    r.Serie = item.Serie;
    r.VendaId = entity.Id;

    resultado.push(r);

    return resultado;
  }, []);

  const DataFormatada = entity.Id
    ? entity.DataFormatada
    : useDate("pt-BR").today().format;

  const entitySave = {
    Id: entity.Id,
    DataFormatada, //"01/04/2024",
    ClienteId: entity.ClienteId,
    UnidadeId: credential.storeId,
    ProfissionalId: credential.userId,
    Itens: items,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
    ContaId: entity.ContaId,
    Observacao: entity.Observacao,
    Tipovenda: 3,
  };

  return entitySave;
};
