import { RouteRecordRaw } from "vue-router";
import Partnership from "@/views/config/billing/partnership/Index.vue";
import Partnerships from "@/views/config/billing/partnership/List.vue";
import BillingMenu from "@/components/menu/BillingMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/billing/partnership/:id",
  name: "Partnership",
  component: {
    default: Partnership,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/billing/partnership/list",
  name: "Partnerships",
  components: {
    default: Partnerships,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});

export default itens;
