import {
  CustomerState,
  Customer,
  MedicalRecords,
  MedicalRecord,
  Anamnesis,
  MedicalCertificate,
  MedicalPrescription,
} from "@/models/interfaces/customer/customer.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CustomerState> = {
  setCustomers(state, payload: Customer[]) {
    state.customers = payload;
  },

  setCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  insertCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  removeCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  updateCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  setMedicalRecords(state, payload: MedicalRecords) {
    state.medicalRecords = payload;
  },

  setMedicalRecord(state, payload: MedicalRecord) {
    state.medicalRecord = payload;
  },

  setAnamnesis(state, payload: Anamnesis) {
    state.anamnesis = payload;
  },

  setMedicalCertificate(state, payload: MedicalCertificate) {
    state.medicalCertificate = payload;
  },

  setMedicalPrescription(state, payload: MedicalPrescription) {
    state.medicalPrescription = payload;
  },
};
