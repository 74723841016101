import { RouteRecordRaw } from "vue-router";
import Task from "../../views/others/task/Index.vue";
import Tasks from "../../views/others/task/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/others/task",
  name: "Task",
  component: Task,
  beforeEnter: Auth,
});
itens.push({
  path: "/others/task/list",
  name: "Tasks",
  component: Tasks,
  beforeEnter: Auth,
});

export default itens;
