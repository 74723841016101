import { RouteRecordRaw } from "vue-router";
import Schedule from "../../views/schedule/Index.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/schedule",
  name: "Schedule",
  component: Schedule,
  beforeEnter: Auth,
});

export default itens;
