import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Checklist,
  ChecklistState,
} from "@/models/interfaces/commercial/checklist.interface";

export const getters: GetterTree<ChecklistState, RootState> = {
  getChecklist(state): Checklist {
    return state.checklist;
  },
};
