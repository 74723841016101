import useLocalStorage from "@/composables/localStorage";
import { data } from "@/constants/shared/gender";
import { Gender } from "@/models/interfaces/shared/gender.interface";

const storage = useLocalStorage("gender", data);

const getAll = async (): Promise<Gender[]> => storage.getAll();

const get = async (id: string): Promise<Gender> => storage.get(id);

export const GenderService = {
  getAll,
  get,
};
