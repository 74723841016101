/* export const getPis = (taxation: any, item: any) => {
  return {
    cst: taxation.CstPisCodigo.toString(),
    baseCalculo: {
      valor: item.Valor,
      quantidade: item.Quantidade,
    },
    aliquota: taxation.PPis,
    valor: item.Valor,
  };
};
 */

export const getPis = (taxation: any, product: any, item: any, valor: any) => {
  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const cst = ncm.CstPisCodigo.toString();

  const aliquota = ncm.AliquotaPis
    ? ncm.AliquotaPis /* parseFloat(ncm.AliquotaPis.replace(",", ".")) */
    : 0;

  switch (cst) {
    case "06":
    case "08":
      return {
        cst,
      };
    default:
      return {
        cst,
        baseCalculo: {
          valor,
          quantidade: item.Quantidade,
        },
        aliquota,
        valor,
      };
  }
};
