import {
  CostCenter,
  CostCenterState,
} from "@/models/interfaces/financial/costcenter.interface";

const costCenter: CostCenter = {
  id: "",
  description: "",
};

const costsCenter: Array<CostCenter> = [];

export const state: CostCenterState = {
  costCenter,
  costsCenter,
};
