import {
  InventoryState,
  Inventory,
} from "@/models/interfaces/material/inventory.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<InventoryState> = {
  setInventories(state, payload: Inventory[]) {
    state.inventories = payload;
  },

  setInventory(state, payload: Inventory) {
    state.inventory = payload;
  },

  insertInventory(state, payload: Inventory) {
    state.inventory = payload;
  },

  removeInventory(state, payload: Inventory) {
    state.inventory = payload;
  },

  updateInventory(state, payload: Inventory) {
    state.inventory = payload;
  },
};
