import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Person,
  PersonState,
} from "@/models/interfaces/shared/person.interface";

export const getters: GetterTree<PersonState, RootState> = {
  getPerson(state): Person {
    return state.person;
  },
};
