import {
  ServiceState,
  Service,
} from "@/models/interfaces/commercial/service.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ServiceState> = {
  setServices(state, payload: Service[]) {
    state.services = payload;
  },

  setService(state, payload: Service) {
    state.service = payload;
  },

  insertService(state, payload: Service) {
    state.service = payload;
  },

  removeService(state, payload: Service) {
    state.service = payload;
  },

  updateService(state, payload: Service) {
    state.service = payload;
  },
};
