import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import store from "@/store";
import axios from "axios";

export const stockTransfer = async (items: any): Promise<ProductOrder[]> => {
  const selectedItems: string[] = items.map((item: { id: any }) => item.id);
  const credential = { ...store.state.login.credential };

  return (
    await axios.patch(
      `${API_URL}/material/productorder/stocktransfer/${credential.storeId}/${credential.userId}/${selectedItems}`
    )
  ).data;
};
