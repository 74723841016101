import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import axios from "axios";
import { treatedEntity } from "./treatedEntity.service";

export const get = async (id: string): Promise<ProductOrder> => {
  if (id == "new") {
    const entity: ProductOrder = {
      Id: "",
      Observacao: "",
      UnidadeDestinoId: 0,
      ProfissionalId: 0,
      UnidadeId: 0,
      ProfissionalAcatadoId: 0,
      Itens: [],
      ItensAcatado: [],
      DataHoraFormatada: "",
      Codigo: 0,
      StatusPedido: 0,
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/pedido?id=${id}`)).data;

  const treated = treatedEntity(entity);

  return treated;
};
