import useLocalStorage from "@/composables/localStorage";
import { State } from "@/models/interfaces/shared/state.interface";

const storage = useLocalStorage("state");

const getAll = async (): Promise<State[]> => storage.getAll("/uf/todos");

const get = async (id: string): Promise<State> => storage.get(id, "/uf/todos");

export const StateService = {
  getAll,
  get,
};
