import {
  TransferAccount,
  TransferAccountState,
} from "@/models/interfaces/financial/transferaccount.interface";

const transferAccount: TransferAccount = {
  id: "",
  description: "",
  value: 0,
  sourcefinancialaccountId: undefined,
  destinationfinancialaccountId: undefined,
  createddate: undefined,
  userId: 0,
  sourceBank: 0,
  sourceDescription: "",
  destinationBank: 0,
  destinationDescription: "",
};

const transferAccounts: Array<TransferAccount> = [];

export const state: TransferAccountState = {
  transferAccount,
  transferAccounts,
};
