import * as Yup from "yup";
//import messages from "../messages";
interface Yup {
  matches: any;
  test: any;
}

const cpf = (yup: Yup): any =>
  yup
    .matches(
      /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
      "O CPF deve estar no formato 000.000.000-00"
    )
    .test("valid-cpf", "CPF inválido", (value: string | any) => {
      const cpf = value.replace(/\D/g, "");

      if (cpf.length !== 11) {
        return false;
      }

      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cpf.substring(9, 10))) {
        return false;
      }

      sum = 0;

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cpf.substring(10, 11))) {
        return false;
      }

      return true;
    });

export default cpf;
