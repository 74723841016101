import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  OtherGuide,
  OtherGuideState,
} from "@/models/interfaces/billing/otherguide.interface";

export const getters: GetterTree<OtherGuideState, RootState> = {
  getOtherGuide(state): OtherGuide {
    return state.otherGuide;
  },
};
