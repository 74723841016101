import {
  IssuingAuthorityState,
  IssuingAuthority,
} from "@/models/interfaces/shared/issuingauthority.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<IssuingAuthorityState> = {
  setIssuingAuthorities(state, payload: IssuingAuthority[]) {
    state.issuingAuthorities = payload;
  },

  setIssuingAuthority(state, payload: IssuingAuthority) {
    state.issuingAuthority = payload;
  },
};
