import { RouteRecordRaw } from "vue-router";
import MedicalCertificate from "@/views/config/medicalconsultation/medicalcertificate/Index.vue";
import MedicalCertificates from "@/views/config/medicalconsultation/medicalcertificate/List.vue";
import MedicalConsultationMenu from "@/components/menu/MedicalConsultationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/medical-consultation/medical-certificate/:id",
  name: "MedicalCertificate",
  components: {
    default: MedicalCertificate,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/medical-consultation/medical-certificate/list",
  name: "MedicalCertificate",
  components: {
    default: MedicalCertificates,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
