import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  supplier: yup
    .number()
    .test(
      "supplier",
      messages.compile({ id: "required", value: "Fornecedor" }),
      (value: any) => {
        return !!value;
      }
    ),
  purchaseDate: yup
    .date()
    .test(
      "purchaseDate",
      messages.compile({ id: "required", value: "Data" }),
      (value: any) => {
        return !!value;
      }
    ),
  purchaseitem: yup
    .array()
    .test(
      "purchaseitem",
      messages.compile({ id: "requiredList", value: "de itens da compra" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    )
    .test(
      "purchaseitem",
      "Há produto(s) na lista que tem Serial mas está sem. Favor conferir e informar os números que estão faltando.",
      (value: any) => {
        console.log(value);
        for (let i = 0; i < value.length; i++) {
          if (!value[i].Serie) return false;
        }
        return true;
      }
    ),
  /*  .test(
      "purchaseitem",
      messages.compile({ id: "requiredList", value: "aff" }),
      (value: any) => {
        return false;
      }
    ), */
});
