import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import store from "@/store";
import axios from "axios";

export const remove = async (id: string) => {
  const credential = { ...store.state.login.credential };

  await axios.delete(
    `${API_URL}/pedido/apagar?ids=${id}&usuario=${credential.userId}`
  );
};
