import messages from "@/helpers/validate/messages";
import store from "@/store";
import * as yup from "yup";

export default yup.object({
  description: yup
    .string()
    .test(
      "description",
      messages.compile({ id: "required", value: "Descrição" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  origin: yup
    .number()
    .test(
      "origin",
      messages.compile({ id: "required", value: "Origem" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  destination: yup
    .number()
    .test(
      "destination",
      messages.compile({ id: "required", value: "Destino" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    )
    .test(
      "destination",
      "A conta de destino tem que ser diferente da conta de origem.",
      (value: any) => {
        const transfer: any = store.state.transferAccount.transferAccount;
        const result = transfer.ContaId != value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  value: yup
    .number()
    .test(
      "value",
      messages.compile({ id: "required", value: "Valor" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
});
