import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductType,
  ProductTypeState,
} from "@/models/interfaces/shared/producttype.interface";

export const getters: GetterTree<ProductTypeState, RootState> = {
  getProductType(state): ProductType {
    return state.productType;
  },
};
