import { API_URL } from "@/constants/env";
import { InvoiceConsumer } from "@/models/interfaces/commercial/invoiceconsumer.interface";
import store from "@/store";
import axios from "axios";
import { getAll } from "./getall.service";
import { NF_TYPE } from "@/helpers/filters";

export const remove = async (id: string): Promise<InvoiceConsumer[]> => {
  const credential = { ...store.state.login.credential };
  await (
    await axios.delete(
      `${API_URL}/nfe/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;

  const filterTypeNf = NF_TYPE;
  filterTypeNf.model.value = 3;

  const r = await getAll(1, "", [[filterTypeNf]]);

  return r;
};
