import { getJson } from "./cst";
import { identifyDocument } from "./identifyDocument.service";

export const getIcms = (
  product: any,
  taxation: any,
  valor: any,
  recipient: any,
  consumidorFinal: any
) => {
  const ufRecipient = recipient.endereco.estado;

  const documentType = identifyDocument(recipient.cpfCnpj);

  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const icmsType = documentType == "CPF" ? 3 : consumidorFinal ? 2 : 1;

  const cenariosIcms = ncm.CenarioIcmss.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient && cenario.tipo == icmsType
  );
  const cenarioIcms = cenariosIcms[0];

  return getJson(
    product,
    valor,
    cenarioIcms,
    taxation,
    recipient,
    consumidorFinal
  );
};
