import {
  PassingOn,
  PassingOnState,
} from "@/models/interfaces/financial/passingon.interface";

const passingOn: PassingOn = {
  id: "",
  description: "",
};

const passingOns: Array<PassingOn> = [];

export const state: PassingOnState = {
  passingOn,
  passingOns,
};
