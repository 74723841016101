import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { CONSULTATION_DATE } from "@/helpers/fields";
import { Loan } from "@/models/interfaces/commercial/loan.interface";
import store from "@/store";
import axios from "axios";
import { date } from "yup";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "emprestimo").fetchData();

const get = async (id: string): Promise<Loan> => {
  if (id == "new") {
    const entity: Loan = {
      id: "",
      customerId: 0,
      note: "",
      loanitem: [],
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/emprestimo?id=${id}`)).data;

  entity.loanitem = (
    await axios.get(`${API_URL}/emprestimo/getitens?emprestimo=${entity.Id}`)
  ).data;

  return entity;
};

const insert = async (entity: Loan): Promise<Loan[]> => {
  return (await axios.post(`${API_URL}/emprestimo/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Loan[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/emprestimo/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: Loan): Promise<Loan[]> => {
  return (await axios.post(`${API_URL}/emprestimo/Salvar`, prepareData(entity)))
    .data;
};

const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /*   const { id, ...updateEntity } = entity; */

  const credential = { ...store.state.login.credential };

  const items = entity.loanitem.reduce((resultado: any, item: any) => {
    const r = {
      Quantidade: 0,
      ProdutoId: 0,
      Serie: "",
      EmprestimoId: 0,
    };

    r.Quantidade = item.Quantidade;
    r.ProdutoId = item.id ? item.id : item.ProdutoId;
    r.Serie = item.Serie;

    resultado.push(r);

    return resultado;
  }, []);

  const entitySave = {
    IdEmprestimo: entity.IdEmprestimo,
    ClienteId: entity.ClienteId,
    DataDevolucaoFormatada: entity.DataDevolucaoFormatada,
    DataEmprestimoFormatada: entity.DataEmprestimoFormatada
      ? entity.DataEmprestimoFormatada
      : new Date(),
    Id: entity.Id,
    Itens: items,
    Observacao: entity.Observacao,
    ProfissionalId: entity.Id ? entity.ProfissionalId : credential.userId,
    Status: entity.Id ? entity.Status : 1,
    UnidadeId: credential.storeId,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
  };

  return entitySave;
};

export const LoanService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
