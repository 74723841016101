import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  CostCenter,
  CostCenterState,
} from "@/models/interfaces/financial/costcenter.interface";

export const getters: GetterTree<CostCenterState, RootState> = {
  getCostCenter(state): CostCenter {
    return state.costCenter;
  },
};
