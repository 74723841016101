import {
  PartnershipReportState,
  PartnershipReport,
} from "@/models/interfaces/medicalconsultation/partnershipreport.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PartnershipReportState> = {
  setPartnershipReports(state, payload: PartnershipReport[]) {
    state.partnershipreports = payload;
  },

  setPartnershipReport(state, payload: PartnershipReport) {
    state.partnershipreport = payload;
  },

  insertPartnershipReport(state, payload: PartnershipReport) {
    state.partnershipreport = payload;
  },

  removePartnershipReport(state, payload: PartnershipReport) {
    state.partnershipreport = payload;
  },

  updatePartnershipReport(state, payload: PartnershipReport) {
    state.partnershipreport = payload;
  },
};
