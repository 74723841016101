import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Fiscal,
  FiscalState,
} from "@/models/interfaces/commercial/fiscal.interface";

export const getters: GetterTree<FiscalState, RootState> = {
  getFiscal(state): Fiscal {
    return state.fiscal;
  },
};
