import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductGenre,
  ProductGenreState,
} from "@/models/interfaces/shared/productgenre.interface";

export const getters: GetterTree<ProductGenreState, RootState> = {
  getProductGenre(state): ProductGenre {
    return state.productGenre;
  },
};
