import {
  RequestingPhysician,
  RequestingPhysicianState,
} from "@/models/interfaces/billing/requestingphysician.interface";

const requestingphysician: RequestingPhysician = {
  id: "",
  description: "",
};

const requestingphysicians: Array<RequestingPhysician> = [];

export const state: RequestingPhysicianState = {
  requestingphysician,
  requestingphysicians,
};
