import {
  AccountPayableState,
  AccountPayable,
  Payee,
} from "@/models/interfaces/financial/accountpayable.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<AccountPayableState> = {
  setAccountsPayable(state, payload: AccountPayable[]) {
    state.accountsPayable = payload;
  },

  setAccountPayable(state, payload: AccountPayable) {
    state.accountPayable = payload;
  },

  insertAccountPayable(state, payload: AccountPayable) {
    state.accountPayable = payload;
  },

  removeAccountPayable(state, payload: AccountPayable) {
    state.accountPayable = payload;
  },

  updateAccountPayable(state, payload: AccountPayable) {
    state.accountPayable = payload;
  },

  setPayees(state, payload: Payee[]) {
    state.payees = payload;
  },
};
