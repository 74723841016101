import { API_URL } from "@/constants/env";
import { Person } from "@/models/interfaces/shared/person.interface";
import axios from "axios";

const getAll = async (): Promise<Person[]> => {
  const r = (await axios.get(`${API_URL}/shared/person`)).data;
  return r;
};

const get = async (id: string): Promise<Person> => {
  return (await axios.get(`${API_URL}/shared/person/${id}`)).data;
};

const getByNameDocument = async (value: string): Promise<Person> => {
  return (await axios.get(`${API_URL}/shared/person/namedocument/${value}`))
    .data;
};

export const PersonService = {
  getAll,
  get,
  getByNameDocument,
};
