import { API_URL } from "@/constants/env";
import { Product } from "@/models/interfaces/material/product.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

export const insert = async (entity: Product): Promise<Product[]> => {
  return (
    await axios.post(`${API_URL}/produtoconf/Salvar`, prepareData(entity))
  ).data;
};
