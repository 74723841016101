import {
  Purchase,
  PurchaseState,
} from "@/models/interfaces/material/purchase.interface";

const purchase: Purchase = {
  /* purchase */
  id: "",
  storeId: undefined,
  supplierId: undefined,
  note: "",
  purchasedate: "",
  userId: undefined,
  Id: 0,
  Itens: [],
  ContasAPagar: [],
};

const purchases: Array<Purchase> = [];

export const state: PurchaseState = {
  purchase,
  purchases,
};
