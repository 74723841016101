export const action = {
  VIEW: 1, // Visualizar
  INSERT: 2, // Inserir
  UPDATE: 3, // Alterar
  DELETE: 4, // Apagar
  VIEW_ONLY_YOUR_CALENDAR: 5, // Visualizar somente a sua agenda
  VIEW_ALL_CALENDARS: 6, // Visualizar todas as agendas
  ACCOUNTS_RECEIVABLE_FOR_THE_DAY: 7, // Receita do dia
  ACCOUNTS_PAYABLE_OF_THE_DAY: 8, // Despesa do dia
  MEDICAL_RECORD: 9, // Prontuário
  ADD_PROCEDURE: 10, // Adicionar Procedimento
  CHANGE_VALUE: 11, // Alterar Valor
  ACCEPT: 12, // Acatar
  CHANGE_STATUS: 13, // Alterar Situacao
};
