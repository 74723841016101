import { API_URL } from "@/constants/env";
import { SupplierType } from "@/models/interfaces/material/suppliertype.interface";
import axios from "axios";

const getAll = async (): Promise<SupplierType[]> =>
  (await axios.get(`${API_URL}/config/material/suppliertype`)).data;

const get = async (id: string): Promise<SupplierType> => {
  if (id == "new") {
    const entity: SupplierType = { id: "0", description: "" };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/material/suppliertype/${id}`))
    .data;
};

const insert = async (entity: SupplierType): Promise<SupplierType[]> => {
  return (
    await axios.post(
      `${API_URL}/config/material/suppliertype`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<SupplierType[]> =>
  (await axios.delete(`${API_URL}/config/material/suppliertype/${id}`)).data;

const update = async (
  id: string,
  entity: SupplierType
): Promise<SupplierType[]> => {
  return (
    await axios.put(
      `${API_URL}/config/material/suppliertype/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: SupplierType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  return updateEntity;
};

export const SupplierTypeService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
