import { API_URL } from "@/constants/env";
import { Supplier } from "@/models/interfaces/material/supplier.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

export const update = async (
  id: string,
  entity: Supplier
): Promise<Supplier[]> => {
  const a = (await axios.post(`${API_URL}/contato/salvar`, prepareData(entity)))
    .data;

  return a;
};
