import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TypeOfPaymentMethod,
  TypeOfPaymentMethodState,
} from "@/models/interfaces/financial/typeofpaymentmethod.interface";
import { TypeOfPaymentMethodService as service } from "@/services/financial/typeOfPaymentMethod.services";
import { constants } from "@/constants/financial/typeofpaymentmethod";

export const actions: ActionTree<TypeOfPaymentMethodState, RootState> = {
  getAll({ commit }): void {
    service.getAll().then((res: TypeOfPaymentMethod[]) => {
      commit(constants.SET_TYPESOFPAYMENTMETHOD, res);
    });
  },

  get({ commit }, id): void {
    service.get(id).then((res: TypeOfPaymentMethod) => {
      commit(constants.SET_TYPEOFPAYMENTMETHOD, res);
    });
  },
};
