import { API_URL } from "@/constants/env";
import { Gift } from "@/models/interfaces/commercial/gift.interface";

import axios from "axios";

export const get = async (id: string): Promise<Gift> => {
  if (id == "new" || id == "" || id == undefined) {
    const entity: Gift = {
      id: "",
      personId: 0,
      storeId: 0,
      salemanId: 0,
      userId: 0,
      canceldate: undefined,
      noteofsale: [],
      saleitem: [],
      ProfissionalId: 0,
      ContaId: 0,
      Id: "",
      ClienteId: 0,
      ClienteIdCriptografado: "",
      TipoVenda: 3,
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/venda?id=${id}`)).data;

  entity.saleitem = (
    await axios.get(`${API_URL}/venda/getitens?venda=${entity.Id}`)
  ).data;

  return entity;
};
