import { RouteRecordRaw } from "vue-router";

import AccountsPayable from "./accountspayableRoute";
import AccountsReceivable from "./accountsreceivableRoute";
import AccountStatement from "./accountstatementRoute";
import BankReconciliation from "./bankreconciliationRoute";
import CashManagement from "./cashmanagementRoute";
import TransferAccounts from "./transferaccountsRoute";
import PassingOn from "./passingonRoute";
import CashFlow from "./cashflowRoute";

const itens: Array<RouteRecordRaw> = [
  ...AccountsPayable,
  ...AccountsReceivable,
  ...AccountStatement,
  ...BankReconciliation,
  ...CashManagement,
  ...TransferAccounts,
  ...PassingOn,
  ...CashFlow,
];

export default itens;
