import { RouteRecordRaw } from "vue-router";

import Service from "./serviceRoute";
import Checklist from "./checklistRoute";
import Treatment from "./treatmentRoute";
import Fiscal from "./fiscalRoute";

const itens: Array<RouteRecordRaw> = [
  ...Service,
  ...Checklist,
  ...Treatment,
  ...Fiscal,
];
export default itens;
