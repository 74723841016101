import {
  LoginState,
  Login,
  Credential,
  Browsing,
  SharedVariables,
} from "@/models/interfaces/login/login.interface";

const login: Login = {
  login: "",
  password: "",
};

const credential: Credential = {
  storeId: 0,
  storeDescription: "",
  userId: 0,
  userName: "",
  userEmail: "",
  userAvatar: "",
  tenantId: 0,
  tenantDescription: "",
  token: "",
  permission: "",
  profileDescription: "",
  profileId: 0,
  scheduleType: 0,
  stores: [],
  tenantCnpj: "",
  cashier: 0,
  storeCnpj: "",
  financialStatus: 0,
};

const browsing: Browsing = {
  lastUrl: "",
  lastFilters: [],
  lastSearchText: "",
  lastPage: 1,
  wait: 0,
};

const sharedVariables: SharedVariables = {
  processBankslips: [],
};

export const state: LoginState = {
  login,
  credential,
  browsing,
  periodNumber: 5,
  sharedVariables,
};
