import {
  SupplierTypeState,
  SupplierType,
} from "@/models/interfaces/material/suppliertype.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<SupplierTypeState> = {
  setSupplierTypes(state, payload: SupplierType[]) {
    state.supplierTypes = payload;
  },

  setSupplierType(state, payload: SupplierType) {
    state.supplierType = payload;
  },

  insertSupplierType(state, payload: SupplierType) {
    state.supplierType = payload;
  },

  removeSupplierType(state, payload: SupplierType) {
    state.supplierType = payload;
  },

  updateSupplierType(state, payload: SupplierType) {
    state.supplierType = payload;
  },
};
