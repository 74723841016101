import { RouteRecordRaw } from "vue-router";
import Sale from "@/views/commercial/sale/consumer/Index.vue";
import Sales from "@/views/commercial/sale/consumer/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/sale/consumer/:id",
  name: "SaleConsumer",
  component: Sale,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/sale/consumer/list",
  name: "SalesConsumer",
  component: Sales,
  beforeEnter: Auth,
});

export default itens;
