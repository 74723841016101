import { API_BANKSLIP_URL, API_URL, BANKSLIP } from "@/constants/env";
import axios from "axios";

export const downloadPdf = async (id: string, accountId: number) => {
  const account: any = (
    await axios.get(`${API_URL}/conta/getid?id=${accountId}`)
  ).data;

  const credentialsBankslip = BANKSLIP.filter(
    item => item.beneficiario == account.Carteira
  );

  const credentialBankslip = credentialsBankslip[0];

  const invoice = {
    clientId: credentialBankslip.clientId, //"2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
    urlCoperativa: credentialBankslip.urlCoperativa, //"svc.bnf.homolog", //usuario
    codCoperativa: credentialBankslip.codCoperativa, //"uL&CtJ5J", //senha
    idBeneficiario: account.Carteira, //"162B62923A8B46CFB5E567A23E2D621A",
    beneficiarioVariacaoCarteira: account.VariacaoCarteira, //38869,
    cooperativa: account.CedenteCodigo, //9184, */
    id,
  };

  /* const invoice = {
    clientId: "2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
    urlCoperativa: "svc.bnf.homolog", //usuario
    codCoperativa: "uL&CtJ5J", //senha
    cooperativa: 9184,
    idBeneficioario: "162B62923A8B46CFB5E567A23E2D621A",
    id,
  };

  console.log("oi"); */

  const urlInvoice = `${API_BANKSLIP_URL}/boletos/unicred/find`;

  console.log(urlInvoice);

  // await axios.post(urlInvoice, invoice);

  try {
    const response = await axios.post(urlInvoice, invoice, {
      responseType: "arraybuffer", // Para lidar com dados binários
    });

    // Converter a resposta para um blob
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Criar uma URL para o blob
    const url = URL.createObjectURL(blob);

    // Abrir a URL em uma nova janela
    window.open(url);

    console.log("PDF criado e exibido no navegador");
  } catch (ex) {
    console.log(ex);
  }
};
