import { Loan, LoanState } from "@/models/interfaces/commercial/loan.interface";

const loan: Loan = {
  id: "",
  customerId: 0,
  note: "",
  loanitem: [],
};

const loans: Array<Loan> = [];

export const state: LoanState = {
  loan,
  loans,
};
