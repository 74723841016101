import { API_URL } from "@/constants/env";
import {
  Markoff,
  Schedule,
  Reschedule,
} from "@/models/interfaces/schedule/schedule.interface";
import store from "@/store";
import useScheduleStatus from "@/composables/schedule/schedulestatus";
import axios from "axios";

const { statusFunctions } = useScheduleStatus();

const get = async (
  unit: any,
  employee: any,
  date: any,
  periodNumber: any
): Promise<any> => {
  let url = `${API_URL}/agenda/getTimeTable`;
  url += `?unidade=${unit}`;
  url += `&profissional=${employee}`;
  url += `&data=${date.format}`;
  url += `&periodNumber=${periodNumber}`;

  const timeTable = (await axios.get(url)).data;

  return timeTable;
};

const getScheduled = async (
  unit: any,
  employee: any,
  date: any,
  periodNumber: any
): Promise<any> => {
  const list = await get(unit, employee, date, periodNumber);

  const filterScheduleds = (scheduled: any) =>
    scheduled.status != 4 && scheduled.status != 5 && scheduled.status != 11;

  const justScheduled = list.map((day: any) => {
    day.schedules = day.schedules.map((schedule: any) => {
      schedule.scheduleds = schedule.scheduleds.filter(filterScheduleds);

      schedule.fit.scheduleds =
        schedule.fit.scheduleds?.filter(filterScheduleds);
      return schedule;
    });
    return day;
  });

  return justScheduled;
};

const setStatus = async (id: string, status: string) => {
  const credential = { ...store.state.login.credential };

  const nStatus = statusFunctions[status]();

  const stateSchedule = store.state.schedule.schedule;

  stateSchedule.scheduleds[stateSchedule.i].status = nStatus;

  let url = `${API_URL}/agenda/AlterarStatus`;
  url += `?id=${id}`;
  url += `&status=${nStatus}`;
  url += `&usuario=${credential.userId}`;

  return (await axios.get(url)).data;
};

const markoff = async (
  id: string,
  obs: string,
  status: string,
  motivo: string,
  modo: string
) => {
  const credential = { ...store.state.login.credential };

  let url = `${API_URL}/agenda/DesmarcarSafira`;
  url += `?id=${id}`;
  url += `&obs=${obs}`;
  url += `&status=${status}`;
  url += `&idProfissionalCancelou=${credential.userId}`;
  url += `&motivo=${motivo}`;
  url += `&mododesmarcado=${modo}`;

  return (await axios.get(url)).data;
};
const reschedule = async (
  schedule: Schedule,
  reschedule: Reschedule,
  markOff: Markoff
) => {
  const credential = { ...store.state.login.credential };

  /*   const fitting = !!schedule.scheduleds[reschedule.i].id; */

  let url = `${API_URL}/agenda/SalvarReagendamento`;
  url += `?id=${reschedule.id}`;
  url += `&horario=${
    reschedule.newtime
      ? reschedule.newtime.replace(":", "")
      : schedule.time.replace(":", "")
  }`;
  url += `&data=${schedule.date}`;
  url += `&posicao=${schedule.i}`;
  url += `&cliente=${credential.tenantId}`;
  url += `&profissional=${credential.userId}`;
  url += `&newProfissional=${reschedule.employee}`;

  await axios.get(url);

  if (reschedule.fit?.id) {
    url = `${API_URL}/agenda/SalvarReagendamento`;
    url += `?id=${reschedule.fit.id}`;
    url += `&horario=${reschedule.time.replace(":", "")}`;
    url += `&data=${reschedule.date}`;
    url += `&posicao=${0}`;
    url += `&cliente=${credential.tenantId}`;
    url += `&profissional=${credential.userId}`;
    url += `&newProfissional=${reschedule.employee}`;

    await axios.get(url);
  }

  url = `${API_URL}/agenda/salvarmotivo2`;
  url += `?cliente=${credential.tenantId}`;
  url += `&id=${reschedule.id}`;
  url += `&motivo=${markOff.reason}`;
  url += `&modo=${markOff.modo}`;
  url += `&unidade=${reschedule.unit}`;
  url += `&profissional=${reschedule.employee}`;
  url += `&data=${reschedule.date}`;
  url += `&hora=${reschedule.time}`;
  url += `&novadata=${schedule.date}`;
  url += `&novahora=${schedule.time}`;

  return (await axios.get(url)).data;
};

const url = `${API_URL}/agenda/Salvar`;

const insert = async (entity: Schedule): Promise<Schedule[]> => {
  console.log(prepareData(entity));
  const r = (await axios.post(url, prepareData(entity))).data;

  return r;
};

const update = async (id: string, entity: any) => {
  return (await axios.post(url, prepareData(entity))).data;
};

const insertFitting = async (entity: Schedule): Promise<Schedule[]> => {
  const r = (await axios.post(url, prepareData(entity))).data;

  return r;
};

const updateFitting = async (id: string, entity: any) => {
  entity.fitting = true;
  entity.scheduleds[entity.i].service.averagetime =
    entity.scheduleds[entity.i].service.averagetime == 0
      ? 1
      : entity.averagetime;

  return (await axios.post(url, prepareData(entity))).data;
};

const prepareData = (entity: Schedule) => {
  const credential = { ...store.state.login.credential };
  const stateTimetable = store.state.timeTable.timeTable;

  const scheduled = entity.scheduleds[entity.i];

  const usuario = credential.userId;
  const Data = stateTimetable.date;
  /* const DataAux = item[6]; */
  const Hora = stateTimetable.time;
  const UnidadeId = stateTimetable.unitId;
  const ProfissionalId = stateTimetable.employeeId;
  const ClienteId = scheduled.customer.id;
  const ProcedimentoId = scheduled.service.id;
  const TempoMedio = scheduled.service.averagetime;
  const Observacao = scheduled.note;
  const NumeroPaciente = entity.i;
  const IdCliente = credential.tenantId; //clientenotepro
  const Id = Number(scheduled.id);
  const IdAgenda = Id;
  const FormaAtualizar = Id ? 1 : 0;

  const ModalidadeAtendimento = scheduled.modality;
  const StatusHorario = scheduled.status;

  const IdProfissioanalAlterouAgenda = credential.userId;
  const ProcedimentoRepasseId = scheduled.service.id;
  const valorrepasse = 0;
  const DataMarcacao = new Date();
  const Apagado = false;

  const TipoAgendamento = scheduled.type; // 1: agendamento normal, 3: repetir
  const QuantidadeAuto = scheduled.amount == undefined ? "" : scheduled.amount;
  const DiasSemanaAuto = scheduled.daysofweek; // RecuperaDias();

  const Encaixe = scheduled.fitting;

  const saveEntity = {
    Id,
    IdAgenda,
    FormaAtualizar,
    usuario,
    Data,
    Hora,
    UnidadeId,
    ProfissionalId,
    ClienteId,
    ProcedimentoId,
    TempoMedio,
    Observacao,
    NumeroPaciente,
    IdCliente,
    ModalidadeAtendimento,
    StatusHorario,
    IdProfissioanalAlterouAgenda,
    ProcedimentoRepasseId,
    valorrepasse,
    DataMarcacao,
    Apagado,
    TipoAgendamento,
    QuantidadeAuto,
    DiasSemanaAuto,
    Encaixe,
  };

  return saveEntity;
};

const getMedicalCareHistory = async (id: string): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const list = (
    await axios.get(
      `${API_URL}/agenda/obterhistoricoatendimento?id=${id}&pos=1&clientenotepro=${credential.tenantId}`
    )
  ).data;
  return list;
};

/* var path = url + "agenda/Salvar";
					
						$scope.entity.usuario = $rootScope.usuario.Id;//usuario logado no sistema


						$scope.entity.Data = toDate(item[6]);
						$scope.entity.DataAux = item[6];
						$scope.entity.Hora = item[3].substr(0, 2) + ":" + item[3].substr(2, 2);
						$scope.entity.UnidadeId = $scope.unidade.Id;
						$scope.entity.ProfissionalId = $scope.profissional;
						$scope.entity.ClienteId = $scope.Id;
						$scope.entity.ProcedimentoId = $scope.procedimento[0].Id;
						$scope.entity.TempoMedio = $scope.TempoMedio;
						$scope.entity.Observacao = $scope.Observacao;
						$scope.entity.NumeroPaciente = item[1];
						$scope.entity.IdCliente = $rootScope.usuario.Cliente;//clientenotepro



						if ($scope.entity.Id != 0) {
							$scope.entity.ProcedimentoRepasseId = $scope.procedimentorepasse[0].Id;
						}
						else {
							$scope.entity.ProcedimentoRepasseId = $scope.entity.ProcedimentoId;
						}

						$scope.entity.QtdeAtendimento = $scope.QtdeAtendimento;


						$scope.entity.ModalidadeAtendimento = $scope.Modalidade;
						$scope.entity.DataValidadeConvenio = toDate($scope.DataValidade);
						$scope.entity.Titular = $scope.Titular;

						$scope.entity.DataAutorizacaoFormatada = $scope.DataAutorizacao;
						$scope.entity.DataValidadeAutorizacaoFormatada = $scope.DataValidadeAutorizacao;
						$scope.entity.SenhaAutorizacao = $scope.SenhaAutorizacao;
						$scope.entity.NumGuia = $scope.NumeroGuia;
						$scope.entity.NumGuiaOperadora = $scope.NumeroGuiaOperadora;
						$scope.entity.Token = $scope.Token;
						$scope.entity.GuiaAssinada = $scope.GuiaAssinada;
						$scope.entity.Pago = $scope.Pago;

						$scope.entity.DataNascimento = $scope.DataNascimento;
						$scope.entity.Cpf = $scope.Cpf;
						$scope.entity.TelefoneResidencial = $scope.TelefoneResidencial;
						$scope.entity.ObservacaoCadastro = $scope.ObservacaoCadastro;
						$scope.entity.Email = $scope.Email;

						$scope.entity.ClienteTelefone = $scope.Telefone;

						$scope.entity.ValorRepasse = $scope.entity.ValorRepasse.toString().replace(/,/gi, ".");


						$scope.entity.PlanoAtendimentoNome = $scope.planoNome;

						$scope.entity.Itens = $scope.listaProcedimento;

						$scope.entity.Datas = $scope.datas;

						

						$scope.entity.MatriculaConvenio = $scope.MatriculaConvenio;
						debugger
						switch ($scope.StatusHorario) {
							case '3':
								$scope.entity.StatusHorario = $scope.statusFalta
								break
							case '9':
								$scope.entity.StatusHorario = $scope.statusAtendimento
								break;
							default:
								$scope.entity.StatusHorario = $scope.StatusHorario;
								break;
						}
						$scope.entity.IdProfissioanalAlterouAgenda = $rootScope.usuario.Id;

						$scope.entity.FormaAtualizar = $scope.FormaAtualizar;

						if ($scope.TipoAgendamento == 3) {
							$scope.entity.DiasSemanaAuto = RecuperaDias();
						} else {
							$scope.entity.QuantidadeAuto = "";
							$scope.entity.DiasSemanaAuto = "";
						}

						$scope.entity.Encaixe = $scope.Encaixe;

						if ($scope.solicitante.length > 0)
							$scope.entity.ProfissionalSolicitanteId = $scope.solicitante[0].Id;

						if ($scope.meiopagto.length > 0)
							$scope.entity.MeioPagamentoId = $scope.meiopagto[0].Id;
						if ($scope.convenio.length > 0)
							$scope.entity.IdConvenio = $scope.convenio[0].Id;

						// if ($scope.procedimento.length > 0)

						if ($scope.IdAgendaMais != "") $scope.entity.Id = 0;

						$http.post(path, $scope.entity).then(function (data) {
							//apos salvar ele verifica qual opcao de salvar foi escolhido


							if (data.data == "Existe atendimentos agendados neste período. Favor verificar."
								|| data.data == "Esse procedimento para ser agendado precisa de um Plano de Atendimento."
								|| data.data == "Não há horário disponível para agendamento. Favor verificar.") {
								$scope.cadastroValido = false;
								$scope.alerta.push(data.data);
								$rootScope.entityTentativaAgendamento = $scope.entity;
								$rootScope.entityTentativaAgendamento.ClienteNome = $scope.Nome;
								$rootScope.entityTentativaAgendamento.IdporCliente = $scope.IdporClienteNotePro;
								$rootScope.entityTentativaAgendamento.DataValidadeConvenioFormatada = $scope.DataValidade;
							
								$scope.loading = false;
							} else {

								if (op == 1) {
									if ($scope.TipoAgendamento >= 2) {
										$mdDialog.hide("-" + $scope.TipoAgendamento + "-" + data.data + "-" + data.data.split("-")[2]); //$scope.entity.QuantidadeAuto);
									} else {
										$mdDialog.hide("-1--" + data.data.split("-")[1] + "-" + data.data.split("-")[2]); //$scope.entity.QuantidadeAuto);
									}
								} else {
									$mdDialog.hide("-1--" + data.data.split("-")[1] + "-" + data.data.split("-")[2]); //$scope.entity.QuantidadeAuto);
								}
								$scope.procedimentos[$scope.procedimento[0].$id - 1].Check = false;

								$scope.procedimentosrepasse[$scope.procedimentorepasse[0].$id - 1].Check = false;

								if ($scope.convenio.length > 0)
									$scope.convenios[$scope.convenio[0].$id - 1].Check = false;
								if ($scope.solicitante.length > 0)
									$scope.solicitantes[$scope.solicitante[0].$id - 1].Check = false;
								if ($scope.meiopagto.length > 0)
									$scope.meiospagto[$scope.meiopagto[0].$id - 1].Check = false;

								//se for salvar copia
								if (data.data.split("-")[2] == "N") {
									$scope.IdAgendaMais = "";
								}

								//$rootscope.entityTentativaAgendamento = "";
							}
						}); */

export const ScheduleService = {
  get,
  getScheduled,
  getMedicalCareHistory,
  insert,
  update,
  insertFitting,
  updateFitting,
  setStatus,
  markoff,
  reschedule,
};
