import { RouteRecordRaw } from "vue-router";
import SupplierType from "@/views/config/material/suppliertype/Index.vue";
import SupplierTypes from "@/views/config/material/suppliertype/List.vue";
import MaterialMenu from "@/components/menu/MaterialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/material/suppliertype/:id",
  name: "SupplierType",
  components: {
    default: SupplierType,
    sidebar: MaterialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/material/suppliertype/list",
  name: "SupplierTypes",
  components: {
    default: SupplierTypes,
    sidebar: MaterialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
