import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Reason } from "@/models/interfaces/schedule/reason.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "motivoalteracaoagenda").fetchData();

const get = async (id: string): Promise<Reason> => {
  if (id == "new") {
    const entity: Reason = {
      id: "",
      description: "",
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/motivoalteracaoagenda?id=${id}`)).data;
};

const getAllByTenant = async (id: string): Promise<Reason> =>
  (await axios.get(`${API_URL}/motivoalteracaoagenda/todos?cliente=${id}`))
    .data;

const insert = async (entity: Reason): Promise<Reason[]> => {
  return (
    await axios.post(
      `${API_URL}/motivoalteracaoagenda/Salvar`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Reason[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/motivoalteracaoagenda/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: Reason): Promise<Reason[]> => {
  return (
    /*  await axios.put( */
    (
      await axios.post(
        `${API_URL}/motivoalteracaoagenda/Salvar`,
        prepareData(entity)
      )
    ).data
  );
};

const prepareData = (entity: Reason) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  return { Id, IdCliente, usuario, ...updateEntity };
};

export const ReasonService = {
  getAll,
  getAllByTenant,
  get,
  insert,
  remove,
  update,
};
