import { API_URL } from "@/constants/env";
import { Customer } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";
import { treatedEntity } from "./treatedentity.service";

export const getById = async (id: string): Promise<Customer> => {
  if (id == "new") {
    const entity: Customer = {
      /* physicalperson */
      id: "",
      Id: "",
      cpf: "",
      birthdate: undefined,

      /* historicphysicalperson */
      civilstatusId: undefined,
      professionId: undefined,
      genderId: undefined,
      personaltreatmentId: undefined,
      name: "",
      preferredname: "",
      anothergender: "",
      placeofbird: "",
      userId: undefined,

      /* employee */
      storeId: undefined,
      /*   active: true, */
      /* user */
      /* isuser: false,
      profileId: undefined,
      password: "", */
      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      address: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      addressnf: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      documents: [],

      DataNascimento: undefined,
      idCriptografado: "",
      idByCustomer: "",
    };

    return entity;
  }

  const idCustomer = id ? id : 0;

  const entity = (await axios.get(`${API_URL}/paciente?id=${idCustomer}`)).data;

  return treatedEntity(entity);
};
