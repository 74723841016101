import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PartnershipReport,
  PartnershipReportState,
} from "@/models/interfaces/medicalconsultation/partnershipreport.interface";

export const getters: GetterTree<PartnershipReportState, RootState> = {
  getPartnershipReport(state): PartnershipReport {
    return state.partnershipreport;
  },
};
