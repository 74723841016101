import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  MeanOfPayment,
  MeanOfPaymentState,
} from "@/models/interfaces/financial/meanofpayment.interface";

export const getters: GetterTree<MeanOfPaymentState, RootState> = {
  getMeanOfPayment(state): MeanOfPayment {
    return state.meanOfPayment;
  },
};
