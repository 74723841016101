import {
  PatientRecordState,
  PatientRecord,
} from "@/models/interfaces/medicalconsultation/patientrecord.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PatientRecordState> = {
  setPatientRecords(state, payload: PatientRecord[]) {
    state.patientrecords = payload;
  },

  setPatientRecord(state, payload: PatientRecord) {
    state.patientrecord = payload;
  },

  insertPatientRecord(state, payload: PatientRecord) {
    state.patientrecord = payload;
  },

  removePatientRecord(state, payload: PatientRecord) {
    state.patientrecord = payload;
  },

  updatePatientRecord(state, payload: PatientRecord) {
    state.patientrecord = payload;
  },
};
