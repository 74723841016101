import { API_URL } from "@/constants/env";
import { Sale } from "@/models/interfaces/commercial/sale.interface";
import axios from "axios";
import { newBankslip } from "../bankslip/newbankslip.service";
import { prepareData } from "./preparedata.service";
import { newInvoice } from "./newinvoice.service";

export const update = async (id: string, entity: Sale): Promise<Sale[]> => {
  const r = (await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity)))
    .data;

  newBankslip(entity);

  newInvoice(entity);

  return r;
};
