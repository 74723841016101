import {
  ConsignedState,
  Consigned,
} from "@/models/interfaces/commercial/consigned.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ConsignedState> = {
  setConsigneds(state, payload: Consigned[]) {
    state.consigneds = payload;
  },

  setConsigned(state, payload: Consigned) {
    state.consigned = payload;
  },

  insertConsigned(state, payload: Consigned) {
    state.consigned = payload;
  },

  removeConsigned(state, payload: Consigned) {
    state.consigned = payload;
  },

  updateConsigned(state, payload: Consigned) {
    state.consigned = payload;
  },
};
