import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Service,
  ServiceState,
} from "@/models/interfaces/commercial/service.interface";
import { ServiceService as service } from "@/services/commercial/service.services";
import { constants } from "@/constants/commercial/service";

export const actions: ActionTree<ServiceState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Service[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_SERVICES, res);
  },
  getAllByTenant({ commit }, id): void {
    service.getAllByTenant(id).then((res: Service) => {
      commit(constants.SET_SERVICES, res);
    });
  },

  getAllByTenantUnitEmployee({ commit }, param): void {
    service
      .getAllByTenantUnitEmployee(
        param.tenantId,
        param.unitId,
        param.employeeId
      )
      .then((res: Service) => {
        commit(constants.SET_SERVICES, res);
      });
  },

  get({ commit }, id): void {
    service.get(id).then((res: Service) => {
      commit(constants.SET_SERVICE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Service[] = await service.insert(state.service);
    commit(constants.INSERT_SERVICE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    /* 
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Service[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_SERVICES, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Service[] = await service.update(id, state.service);
    commit(constants.UPDATE_SERVICE, res);
  },
};
