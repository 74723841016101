import { get } from "./get.service";
import { getAll } from "./getall.service";
import { getAllCombo } from "./getallcombo.service";
import { getDefault } from "./getdefault.service";

export const TaxScenarioService = {
  getAll,
  get,
  getAllCombo,
  getDefault,
};
