import { get } from "./get.service";
import { getAll } from "./getall.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { update } from "./update.service";

export const SaleConsumerService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
