import {
  ConsultationGuide,
  ConsultationGuideState,
} from "@/models/interfaces/billing/consultationguide.interface";

const consultationGuide: ConsultationGuide = {
  id: "",
  description: "",
};

const consultationGuides: Array<ConsultationGuide> = [];

export const state: ConsultationGuideState = {
  consultationGuide,
  consultationGuides,
};
