import { RouteRecordRaw } from "vue-router";
import Exception from "@/views/config/schedule/exception/Index.vue";
import Exceptions from "@/views/config/schedule/exception/List.vue";
import ScheduleMenu from "@/components/menu/ScheduleMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/schedule/exception/:id",
  name: "Exception",
  component: {
    default: Exception,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/schedule/exception/list",
  name: "Exceptions",
  components: {
    default: Exceptions,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});

export default itens;
