import { API_URL } from "@/constants/env";
import { WorkOrder } from "@/models/interfaces/commercial/workorder.interface";
import store from "@/store";
import axios from "axios";
import { prepareData } from "../internal/preparadata.service";
import { get } from "./get.service";

export const update = async (id: string, entity: any): Promise<WorkOrder[]> => {
  if (entity.receiptcode) {
    const credential = { ...store.state.login.credential };

    const url =
      `${API_URL}/ordemservico/atualizarstatus?cliente=${credential.tenantId}` +
      `&id=${entity.Id}&codigo=${entity.receiptcode}&usuario=${credential.userId}`;

    const rstatus = await axios.get(url);

    if (entity.Obs) {
      entity.Obs += `<br> ${rstatus.data}`;
    } else {
      entity.Obs = rstatus.data;
    }

    const ret: any = await get(entity.IdCriptografado);

    entity.StatusOs = ret.StatusOs;
  }

  const r = (
    await axios.post(`${API_URL}/ordemservico/Salvar`, prepareData(entity))
  ).data;

  return r;
};
