import { RouteRecordRaw } from "vue-router";
import ConfigDashboard from "@/views/config/dashboard/Index.vue";
import ConfigDashboards from "@/views/config/dashboard/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/dashboard",
  name: "ConfigDashboard",
  component: ConfigDashboard,
  beforeEnter: Auth,
});

itens.push({
  path: "/config/dashboard",
  name: "ConfigDashboards",
  component: ConfigDashboards,
  beforeEnter: Auth,
});

export default itens;
