import { API_URL } from "@/constants/env";
import { Voucher } from "@/models/interfaces/commercial/voucher.interface";
import store from "@/store";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import { getVoucher } from "./getvoucher.service";

export const setVoucher = async (
  id: string,
  entity: Voucher
): Promise<Voucher[]> => {
  const credential = { ...store.state.login.credential };

  const entitySave = prepareData(entity);

  entitySave.UsuarioAlteracaoId = credential.userId;
  entitySave.Cupom = await getVoucher();

  return (await axios.post(`${API_URL}/cupomdesconto/Salvar`, entitySave)).data;
};
