import { API_URL, BANKSLIP } from "@/constants/env";
import axios from "axios";
import { useDate } from "@/composables/date";

export const newBankslip = async (entity: any) => {
  const account: any = (
    await axios.get(`${API_URL}/conta/getId?id=${entity.ContaId}`)
  ).data;

  if (!account.Carteira) return;

  const customer: any = (
    await axios.get(`${API_URL}/paciente/getId?id=${entity.ClienteId}`)
  ).data;

  let city: any;

  if (customer.UfNfId) {
    const cities: any = (
      await axios.get(`${API_URL}/cidade/todos?uf=${customer.UfNfId}`)
    ).data;

    city = cities.filter((item: any) => item.Id == customer.CidadeNfId)[0];
  } else {
    const cities: any = (
      await axios.get(`${API_URL}/cidade/todos?uf=${customer.UfId}`)
    ).data;

    city = cities.filter((item: any) => item.Id == customer.CidadeId)[0];
  }

  const nomeRazaoSocial = customer.DocumentoNf
    ? customer.NomeNf.slice(0, 40)
    : customer.Nome.slice(0, 40);
  const tipoPessoa = customer.DocumentoNf
    ? customer.TipoPessoaNf == 1
      ? "F"
      : "J"
    : "F";
  const numeroDocumento = customer.DocumentoNf
    ? customer.DocumentoNf?.replace(/\D/g, "")
    : customer.Cpf?.replace(/\D/g, "");
  const tipoLogradouro = "Rua";
  const logradouro = customer.DocumentoNf
    ? customer.LogradouroNf
    : customer.Logradouro;
  const numero = customer.DocumentoNf ? customer.NumeroNf : customer.Numero;
  const complemento = customer.DocumentoNf
    ? customer.ComplementoNf
      ? customer.ComplementoNf
      : ""
    : customer.Complemento
    ? customer.Complemento
    : "";
  const bairro = customer.DocumentoNf ? customer.BairroNf : customer.Bairro;
  const cidade = city?.Nome;
  const uf = city?.UfSigla;
  const cep = customer.DocumentoNf
    ? customer.CepNf?.replace(/[.-]/g, "")
    : customer.Cep?.replace(/[.-]/g, "");

  const credentialsBankslip = BANKSLIP.filter(
    item => item.beneficiario == account.Carteira
  );
  debugger;

  if (
    (entity.MeioPagamentoTipo == 6 && credentialsBankslip.length > 0) || //commercial
    (entity.newBankslip && credentialsBankslip.length > 0) //account receivable
  ) {
    const credentialBankslip = credentialsBankslip[0];

    const bankslip = {
      clientId: credentialBankslip.clientId, //"2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
      urlCoperativa: credentialBankslip.urlCoperativa, //"svc.bnf.homolog", //usuario
      codCoperativa: credentialBankslip.codCoperativa, //"uL&CtJ5J", //senha
      idBeneficiario: account.Carteira, //"162B62923A8B46CFB5E567A23E2D621A",
      beneficiarioVariacaoCarteira: account.VariacaoCarteira, //38869,
      cooperativa: account.CedenteCodigo, //9184,
      seuNumero: entity.Id,
      valor: entity.Valor,
      vencimento: useDate("pt-BR").formatDateForInvoice(
        entity.DataVencimentoFormatada
      ), //"2024-10-01",
      pagador: {
        nomeRazaoSocial,
        nomeFantasia: nomeRazaoSocial,
        tipoPessoa,
        numeroDocumento,
        endereco: {
          tipoLogradouro,
          logradouro,
          numero,
          complemento,
          bairro,
          cidade,
          uf,
          cep,
        },
      },
    };
    return bankslip;
  }
  return false;
};
