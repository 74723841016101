// import axios from "axios";

import store from "@/store";
import { security } from "@/constants/security";
import { listView } from "@/constants/security/view";

const auth = (to: any, from: any, next: (arg?: any) => void) => {
  // Recuperar informações do usuario logado armazenados no token
  const credential = { ...store.state.login.credential };

  if (credential.token) {
    /* localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== null */
    /* const base64Url = localStorage.token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload).usuario; */

    // Verificar se o usuário logado tem a permissão de acesso a funcionalidade
    //let splitRouteName = to.name.split("_");

    const permissions = credential.permission;
    const viewName = camelToSnakeCase(to.name);
    const viewValue = getViewValue(viewName);

    //views que não precisam de validacao de acesso ou não foram configurados na constant
    if (!viewValue) next();

    const actionVIEW = getActionValue("VIEW");
    const actionVIEW_ONLY_YOUR_CALENDAR = getActionValue(
      "VIEW_ONLY_YOUR_CALENDAR"
    );
    const actionVIEW_ALL_CALENDARS = getActionValue("VIEW_ALL_CALENDARS");

    const permission = permissions.find(
      (item: any) =>
        (item.action == actionVIEW ||
          item.action == actionVIEW_ONLY_YOUR_CALENDAR ||
          item.action == actionVIEW_ALL_CALENDARS) &&
        item.view == viewValue
    );
    // TEM PERMISSÃO
    if (permission) {
      next();
    }

    next("/naoautorizado");
  } else {
    next("/login");
  }
};

const camelToSnakeCase = (text: any) =>
  text
    .replace(/([A-Z])/g, "_$1")
    .toUpperCase()
    .replace(/^_/, "");

const getViewValue = (key: keyof typeof security.listView) =>
  security.listView[key];
const getActionValue = (key: keyof typeof security.action) =>
  security.action[key];

export default auth;

export const hasPermission = (action: any, view: any) => {
  /* ValidaAcesso(12, 11009) */
  const credential = { ...store.state.login.credential };

  const permissions = credential.permission;

  if (view != 21004) {
    /* if (("," + credential.permission).search("," + action + "-" + item) >= 0)
      return true;
    if (acao == 5 || acao == 6) {
      if (("," + credential.permission).search(",5-" + item) >= 0) return true;
      if (("," + credential.permission).search(",6-" + item) >= 0) return true; //se acao for 5 visualizar somente sua agenda testa se visualizar todas ta marcado.
    } */

    const permission = permissions.find(
      (item: any) => item.action == action && item.view == view
    );

    // TEM PERMISSÃO
    if (permission) {
      return true;
    }
  }
  return false;
};
