// import { API_URL } from "@/constants/env";
import { Login } from "@/models/interfaces/login/login.interface";
// import axios from "axios";

const login: Login = {
  login: "",
  password: "",
};

const execute = async (id: string): Promise<Login> => {
  return login;
};

// const get = async (id: number): Promise<Pessoa> =>
//   (await axios.get(`${API_URL}/pessoa/${id}`)).data;

// const insert = async (pessoa: Pessoa): Promise<Pessoa[]> => {

//   return (await axios.post(`${API_URL}/pessoa`, pessoa)).data;
// };

// const remove = async (id: number): Promise<Pessoa[]> =>
//   (await axios.delete(`${API_URL}/pessoa/${id}`)).data;

// const update = async (id: number, pessoa: Pessoa): Promise<Pessoa[]> =>
//   (await axios.patch(`${API_URL}/pessoa/${id}`, pessoa)).data;

export const ChooseStoreService = {
  execute,
};
