import {
  Brand,
  BrandState,
} from "@/models/interfaces/material/brand.interface";

const brand: Brand = {
  id: "",
  description: "",
  suppliercoreId: undefined,
};

const brands: Array<Brand> = [];

export const state: BrandState = {
  brand,
  brands,
};
