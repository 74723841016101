import { RouteRecordRaw } from "vue-router";

import Timetalbe from "./timetableRoute";
import Block from "./blockRoute";
import Exception from "./exceptionRoute";
import Reason from "./reasonRoute";

const itens: Array<RouteRecordRaw> = [
  ...Timetalbe,
  ...Block,
  ...Exception,
  ...Reason,
];
export default itens;
