import {
  VoucherState,
  Voucher,
} from "@/models/interfaces/commercial/voucher.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<VoucherState> = {
  setVouchers(state, payload: Voucher[]) {
    state.vouchers = payload;
  },

  setVoucher(state, payload: Voucher) {
    state.voucher = payload;
  },

  insertVoucher(state, payload: Voucher) {
    state.voucher = payload;
  },

  removeVoucher(state, payload: Voucher) {
    state.voucher = payload;
  },

  updateVoucher(state, payload: Voucher) {
    state.voucher = payload;
  },
};
