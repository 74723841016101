import {
  Exception,
  ExceptionState,
} from "@/models/interfaces/schedule/exception.interface";

const exception: Exception = {
  id: "",
  description: "",
};

const exceptions: Array<Exception> = [];

export const state: ExceptionState = {
  exception,
  exceptions,
};
