import {
  DocumentType,
  DocumentTypeState,
} from "@/models/interfaces/shared/documenttype.interface";

const documentType: DocumentType = {
  id: "",
  description: "",
};

const documentTypes: Array<DocumentType> = [];

export const state: DocumentTypeState = {
  documentType,
  documentTypes,
};
