import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  MedicalCertificate,
  MedicalCertificateState,
} from "@/models/interfaces/medicalconsultation/medicalcertificate.interface";

export const getters: GetterTree<MedicalCertificateState, RootState> = {
  getMedicalCertificate(state): MedicalCertificate {
    return state.medicalcertificate;
  },
};
