import { RouteRecordRaw } from "vue-router";

import ConsultationGuide from "./consultationguideRoute";
import SpsadtGuide from "./spsadtguideRoute";
import OtherGuides from "./otherguidesRoute";
import Batch from "./batchRoute";

const itens: Array<RouteRecordRaw> = [
  ...ConsultationGuide,
  ...SpsadtGuide,
  ...OtherGuides,
  ...Batch,
];

export default itens;
