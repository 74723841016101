import {
  TaxScenario,
  TaxScenarioState,
} from "@/models/interfaces/commercial/taxscenario.interface";

const taxScenario: TaxScenario = {
  Nome: "",
  Id: 0,
  Apagado: false,
  Ativo: false,
  Usuario: 0,
  Natureza: 0,
  IdporClienteNotePro: 0,
  IdCliente: 0,
  QtdePaginasGrid: 0,
  IdCriptografado: "",
  Erro: "",
};

const taxScenarios: Array<TaxScenario> = [];

export const state: TaxScenarioState = {
  taxScenario,
  taxScenarios,
};
