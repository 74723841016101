import { RouteRecordRaw } from "vue-router";

import Inventory from "./inventoryRoute";
import ProductOrder from "./productorderRoute";
import Product from "./productRoute";
import Purchase from "./purchaseRoute";
import StockTransfer from "./stocktransferRoute";
import Supplier from "./supplierRoute";

const itens: Array<RouteRecordRaw> = [
  ...Inventory,
  ...ProductOrder,
  ...Product,
  ...Purchase,
  ...StockTransfer,
  ...Supplier,
];

export default itens;
