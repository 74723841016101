import { useDate } from "@/composables/date";

export const getBankslip = async (sale: any) => {
  const quotas = await getQuota(sale.accountReceivable);

  return {
    numero: 1,
    valorTotal: quotas.total,
    valorDesconto: 0,
    valorLiquido: quotas.total,
    parcelas: quotas.quotas,
  };
};

const getQuota = async (accounts: any) => {
  const quotas = await accounts.reduce(
    async (accPromise: Promise<any>, quota: any) => {
      const acc = await accPromise; // Aguardar a resolução da promessa anterior

      const dataVencimento = useDate("pt-BR").formatDateForInvoice(
        dateVerify(quota.DataVencimentoFormatada)
      );

      acc.quotas.push({
        numero: acc.quotaNumber.toString().padStart(3, "0"),
        valor: quota.Valor,
        dataVencimento,
      });

      acc.total += quota.Valor;
      acc.quotaNumber++;

      return acc; // Retorna a promessa resolvida com o novo acumulador
    },
    Promise.resolve({ quotas: [], quotaNumber: 1, total: 0 }) // Inicializa como uma promessa resolvida
  );

  return quotas;
};

const dateVerify = (dataString: string) => {
  // Divide a string no formato dd/mm/yyyy
  const [dia, mes, ano] = dataString.split("/").map(Number);

  // Cria um objeto Date com a data passada
  const dataPassada = new Date(ano, mes - 1, dia); // O mês é zero-based em JavaScript

  // Cria um objeto Date para a data atual
  const dataAtual = new Date();

  // Verifica se a data atual é menor que a data passada
  if (dataAtual > dataPassada) {
    // Formata a data atual no formato dd/mm/yyyy
    const diaAtual = String(dataAtual.getDate()).padStart(2, "0");
    const mesAtual = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Mês é zero-based
    const anoAtual = dataAtual.getFullYear();
    return `${diaAtual}/${mesAtual}/${anoAtual}`;
  }

  // Se não for menor, retorna null ou outro valor que desejar
  return dataString;
};
