import {
  Address,
  AddressState,
} from "@/models/interfaces/shared/address.interface";

const address: Address = {
  zipcode: "",
  state: undefined,
  city: "",
  neighborhood: "",
  street: "",
  id: "",
  ibgecode: "",
  stateId: undefined,
  cityId: undefined,
  number: "",
  complement: "",
  stateAcronym: "",
  cityName: "",
};

const addresses: Array<Address> = [];

export const state: AddressState = {
  address,
  addresses,
};
