import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  AccountReceivable,
  AccountReceivableState,
} from "@/models/interfaces/financial/accountreceivable.interface";
import { AccountReceivableService as service } from "@/services/financial/accountreceivable.services";
import { constants } from "@/constants/financial/accountreceivable";

export const actions: ActionTree<AccountReceivableState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: AccountReceivable[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_ACCOUNTSRECEIVABLE, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: AccountReceivable) => {
      commit(constants.SET_ACCOUNTRECEIVABLE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: AccountReceivable[] = await service.insert(
      state.accountReceivable
    );
    /*     commit(constants.INSERT_ACCOUNTRECEIVABLE, res); */
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*  const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: AccountReceivable[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_ACCOUNTSRECEIVABLE, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: AccountReceivable[] = await service.update(
      id,
      state.accountReceivable
    );
    /*    commit(constants.UPDATE_ACCOUNTRECEIVABLE, res); */
  },
};
