import {
  WorkOrder,
  WorkOrderInternalState,
} from "@/models/interfaces/commercial/workorder.interface";

const workorderInternal: WorkOrder = {
  id: "",
  serialnumber: "",
  newserialnumber: undefined,
  productdescription: "",
  productwarranty: false,
  expecteddate: undefined,
  deliverydate: undefined,
  serviceId: 0,
  storeId: 0,
  userId: 0,
  saleId: 0,
  ordernote: [],
  orderAuthorization: undefined,
  internalorder: undefined,
  customerorder: undefined,
  Serie: "",
  Descricao: "",
  Id: "",
  receiptcode: "",
};

const workordersInternal: Array<WorkOrder> = [];

export const state: WorkOrderInternalState = {
  workorderInternal,
  workordersInternal,
};
