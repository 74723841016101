import { GiftState, Gift } from "@/models/interfaces/commercial/gift.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<GiftState> = {
  setGifts(state, payload: Gift[]) {
    state.gifts = payload;
  },

  setGift(state, payload: Gift) {
    state.gift = payload;
  },

  insertGift(state, payload: Gift) {
    state.gift = payload;
  },

  removeGift(state, payload: Gift) {
    state.gift = payload;
  },

  updateGift(state, payload: Gift) {
    state.gift = payload;
  },
};
