import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Exception,
  ExceptionState,
} from "@/models/interfaces/schedule/exception.interface";

export const getters: GetterTree<ExceptionState, RootState> = {
  getException(state): Exception {
    return state.exception;
  },
};
