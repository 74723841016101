import {
  FiscalState,
  Fiscal,
} from "@/models/interfaces/commercial/fiscal.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<FiscalState> = {
  setFiscals(state, payload: Fiscal[]) {
    state.fiscals = payload;
  },

  setFiscal(state, payload: Fiscal) {
    state.fiscal = payload;
  },

  insertFiscal(state, payload: Fiscal) {
    state.fiscal = payload;
  },

  removeFiscal(state, payload: Fiscal) {
    state.fiscal = payload;
  },

  updateFiscal(state, payload: Fiscal) {
    state.fiscal = payload;
  },
};
