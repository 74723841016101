import { API_URL } from "@/constants/env";
import { MedicalRecord } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";
import axios from "axios";

export const getMedicalRecord = async (
  id: string,
  date: string
): Promise<MedicalRecord> => {
  const credential = { ...store.state.login.credential };

  const entity: MedicalRecord = (
    await axios.get(
      `${API_URL}/prontuario/getatendimento?cliente=${credential.tenantId}&profissional=${credential.userId}&paciente=${id}&data=${date}`
    )
  ).data;

  return entity;
};
