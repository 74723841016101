import { API_URL } from "@/constants/env";
import { IssuingAuthority } from "@/models/interfaces/shared/issuingauthority.interface";
import axios from "axios";

const getAll = async (): Promise<IssuingAuthority[]> => {
  const r = (await axios.get(`${API_URL}/shared/issuingauthority`)).data;
  return r;
};

const get = async (id: string): Promise<IssuingAuthority> => {
  return (await axios.get(`${API_URL}/shared/issuingauthority/${id}`)).data;
};

export const IssuingAuthorityService = {
  getAll,
  get,
};
