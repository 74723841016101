import { RouteRecordRaw } from "vue-router";
import PerformingPhysician from "@/views/config/billing/performingphysician/Index.vue";
import PerformingPhysicians from "@/views/config/billing/performingphysician/List.vue";
import BillingMenu from "@/components/menu/BillingMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/billing/performing-physician/:id",
  name: "PerformingPhysician",
  component: {
    default: PerformingPhysician,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/billing/performing-physician/list",
  name: "PerformingPhysicians",
  components: {
    default: PerformingPhysicians,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});

export default itens;
