import {
  FinancialAccountState,
  FinancialAccount,
} from "@/models/interfaces/financial/financialAccount.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<FinancialAccountState> = {
  setFinancialAccounts(state, payload: FinancialAccount[]) {
    state.financialAccounts = payload;
  },

  setFinancialAccount(state, payload: FinancialAccount) {
    state.financialAccount = payload;
  },

  insertFinancialAccount(state, payload: FinancialAccount) {
    state.financialAccount = payload;
  },

  removeFinancialAccount(state, payload: FinancialAccount) {
    state.financialAccount = payload;
  },

  updateFinancialAccount(state, payload: FinancialAccount) {
    state.financialAccount = payload;
  },
};
