import { API_URL } from "@/constants/env";
import { Purchase } from "@/models/interfaces/material/purchase.interface";
import axios from "axios";
import store from "@/store";
/* Promise<Purchase[]> */
export const remove = async (ids: any) => {
  const credential = { ...store.state.login.credential };

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    await axios.get(
      `${API_URL}/compra/cancelarfinanceiro?id=${id}&usuario=${credential.userId}`
    );

    await axios.delete(
      `${API_URL}/compra/apagar?ids=${id}&usuario=${credential.userId}`
    );
  }
};
