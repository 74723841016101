import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TimeTable,
  TimeTableState,
} from "@/models/interfaces/schedule/timetable.interface";
import { TimeTableService as timeTable } from "@/services/schedule/timetable.services";
import { constants } from "@/constants/schedule/timetable";

export const actions: ActionTree<TimeTableState, RootState> = {
  /* async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: TimeTable[] = await timeTable.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_TIMETABLES, res);
  }, */
  /*  get({ commit }, id): void {
    timeTable.get(id).then((res: TimeTable) => {
      commit(constants.SET_TIMETABLE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: TimeTable[] = await timeTable.insert(state.timeTable);
    commit(constants.INSERT_TIMETABLE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await timeTable.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: TimeTable[] = await timeTable.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_TIMETABLES, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: TimeTable[] = await timeTable.update(id, state.timeTable);
    commit(constants.UPDATE_TIMETABLE, res);
  }, */
};
