import { API_URL } from "@/constants/env";
import { Product } from "@/models/interfaces/material/product.interface";
import store from "@/store";
import axios from "axios";

export const getByCode = async (code: string): Promise<any> => {
  const credential = { ...store.state.login.credential };

  const list: Array<Product> = (
    await axios.get(
      `${API_URL}/produtoconf/getCodigoFornecedor?cliente=${credential.tenantId}&codigo=${code}`
    )
  ).data;

  //todo: recuperar serial number
  return list;
};
