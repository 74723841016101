import {
  TypeOfPaymentMethodState,
  TypeOfPaymentMethod,
} from "@/models/interfaces/financial/typeofpaymentmethod.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TypeOfPaymentMethodState> = {
  setTypesOfPaymentMethod(state, payload: TypeOfPaymentMethod[]) {
    state.typesOfPaymentMethod = payload;
  },

  setTypeOfPaymentMethod(state, payload: TypeOfPaymentMethod) {
    state.typeOfPaymentMethod = payload;
  },
};
