import { API_URL } from "@/constants/env";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import store from "@/store";
import { Gift } from "@/models/interfaces/commercial/gift.interface";

export const insert = async (entity: Gift): Promise<Gift[]> => {
  console.log(1);
  store.state.loading.loading.value = true;
  console.log(prepareData(entity));
  const r = (await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity)))
    .data;
  console.log(r);
  entity.Id = r.Id;

  return r;
};
