import {
  ItemProductOrder,
  ProductOrder,
} from "@/models/interfaces/material/productorder.interface";
import store from "@/store";

export const prepareData = (entity: ProductOrder) => {
  const credential = { ...store.state.login.credential };

  const Id = entity.Id;
  const Observacao = entity.Observacao;

  const UnidadeDestinoId = entity.Id
    ? entity.UnidadeDestinoId
    : credential.storeId;
  const ProfissionalId = entity.Id ? entity.ProfissionalId : credential.userId;
  const Usuario = entity.Id ? entity.ProfissionalId : credential.userId;

  const UnidadeId = entity.Id ? credential.storeId : null;
  const ProfissionalAcatadoId = entity.Id ? credential.userId : null;

  const Itens: Array<ItemProductOrder> = [];

  const ItensAcatado: Array<ItemProductOrder> = [];

  const IdCliente = credential.tenantId;

  const DataHoraFormatada = entity.DataHoraFormatada;
  const Codigo = entity.Codigo;
  const StatusPedido = entity.StatusPedido;

  console.log(StatusPedido);

  entity.Itens.forEach((item: any) => {
    Itens.push({
      Quantidade: item.Quantidade,
      QuantidadeEnviada: item.QuantidadeEnviada,
      Serie: "",
      Valor: item.Valor,
      ProdutoId: Id ? item.ProdutoId : item.id,
    });

    if (item.SerieObrigatoria) {
      item.series.forEach((serie: string) => {
        ItensAcatado.push({
          Quantidade: item.Quantidade,
          QuantidadeEnviada: item.series.length,
          Serie: serie,
          Valor: item.Valor,
          ProdutoId: Id ? item.ProdutoId : item.id,
        });
      });
    } else {
      ItensAcatado.push({
        Quantidade: item.Quantidade,
        QuantidadeEnviada: item.QuantidadeEnviada,
        Serie: "",
        Valor: item.Valor,
        ProdutoId: Id ? item.ProdutoId : item.id,
      });
    }
  });

  const entitySave = {
    Id,
    IdCliente,
    Observacao,
    UnidadeDestinoId,
    ProfissionalId,
    UnidadeId,
    ProfissionalAcatadoId,
    DataHoraFormatada,
    Codigo,
    Itens,
    ItensAcatado,
    Usuario,
    StatusPedido,
  };

  return entitySave;
};
