import { RouteRecordRaw } from "vue-router";
import Invoice from "@/views/commercial/invoice/product/Index.vue";
import Invoices from "@/views/commercial/invoice/product/List.vue";
import InvoiceMenuVue from "@/components/menu/InvoiceMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/invoice/product/:id",
  name: "InvoiceProduct",
  components: {
    default: Invoice,
    sidebar: InvoiceMenuVue,
  },
  beforeEnter: Auth,
});

itens.push({
  path: "/commercial/invoice/product/list",
  name: "InvoicesProduct",
  components: {
    default: Invoices,
    sidebar: InvoiceMenuVue,
  },
  beforeEnter: Auth,
});

export default itens;
