import { API_URL } from "@/constants/env";
import { Bank } from "@/models/interfaces/financial/bank.interface";
import axios from "axios";

const getAll = async (): Promise<Bank[]> =>
  (await axios.get(`${API_URL}/banco/todos`)).data;

const get = async (id: string): Promise<Bank> =>
  (await axios.get(`${API_URL}/config/financial/costcenter/${id}`)).data;

export const BankService = {
  getAll,
  get,
};
