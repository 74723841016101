import {
  StockTransfer,
  StockTransferState,
} from "@/models/interfaces/material/stocktransfer.interface";

const stockTransfer: StockTransfer = {
  id: "",
  description: "",
};

const stockTransfers: Array<StockTransfer> = [];

export const state: StockTransferState = {
  stockTransfer,
  stockTransfers,
};
