export const constants = {
  SET_CUSTOMERS: "setCustomers",
  SET_CUSTOMER: "setCustomer",
  SET_MEDICALRECORDS: "setMedicalRecords",
  SET_MEDICALRECORD: "setMedicalRecord",
  SET_ANAMNESIS: "setAnamnesis",
  SET_MEDICALCERTIFICATE: "setMedicalCertificate",
  SET_MEDICALPRESCRIPTION: "setMedicalPrescription",
  INSERT_CUSTOMER: "insertCustomer",
  REMOVE_CUSTOMER: "removeCustomer",
  UPDATE_CUSTOMER: "updateCustomer",
};
