import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Profession,
  ProfessionState,
} from "@/models/interfaces/shared/profession.interface";
import { ProfessionService as profession } from "@/services/shared/profession.services";
import { constants } from "@/constants/shared/profession";

export const actions: ActionTree<ProfessionState, RootState> = {
  getAll({ commit }): void {
    profession.getAll().then((res: Profession[]) => {
      commit(constants.SET_PROFESSIONS, res);
    });
  },
  get({ commit }, id): void {
    profession.get(id).then((res: Profession) => {
      commit(constants.SET_PROFESSION, res);
    });
  },
};
