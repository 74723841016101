import {
  TaxationState,
  Taxation,
} from "@/models/interfaces/commercial/taxation.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TaxationState> = {
  setTaxations(state, payload: Taxation[]) {
    state.taxations = payload;
  },

  setTaxation(state, payload: Taxation) {
    state.taxation = payload;
  },

  insertTaxation(state, payload: Taxation) {
    state.taxation = payload;
  },

  removeTaxation(state, payload: Taxation) {
    state.taxation = payload;
  },

  updateTaxation(state, payload: Taxation) {
    state.taxation = payload;
  },
};
