import store from "@/store";

export const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;

  const Usuario = credential.userId;
  const UnidadeId = credential.storeId;

  const ValorFormatado = Number(entity.Valor).toString().replace(/\./g, ",");
  const DescontoMaxFormatado = Number(entity.DescontoMax)
    .toString()
    .replace(/\./g, ",");

  /* const GeneroId = entity.GeneroId ? entity.GeneroId : credential.cashier;
  const UnidadeMedidaId = entity.UnidadeMedidaId ? entity.UnidadeMedidaId : credential.cashier;
  const OrigemId = entity.CaixaId ? entity.CaixaId : credential.cashier;

  const TipoProdutoId = entity.CaixaId ? entity.CaixaId : credential.cashier;
  const ContatoId = entity.CaixaId ? entity.CaixaId : credential.cashier;

  const MarcaId = entity.CaixaId ? entity.CaixaId : credential.cashier; */

  console.log({
    ...updateEntity,
    Id: id,
    IdCliente,
    UnidadeId,
    Usuario,
    ValorFormatado,
    DescontoMaxFormatado,
  });

  return {
    ...updateEntity,
    Id: id,
    IdCliente,
    UnidadeId,
    Usuario,
    ValorFormatado,
    DescontoMaxFormatado,

    /*     GeneroId,
    UnidadeMedidaId,
    OrigemId,
    TipoProdutoId,
    ContatoId,
    MarcaId, */
  };
};
