import { getIcms } from "./icms";
import { getPis } from "./pis.service";
import { getCofins } from "./cofins.service";
import { getShare } from "./share.service";

export const getTaxation = async (
  product: any,
  taxation: any,
  item: any,
  recipient: any,
  consumidorFinal: boolean,
  valor: any
) => {
  const share = getShare(taxation, product, recipient, consumidorFinal);

  return {
    ...(share !== null && { partilha: share }),
    icms: await getIcms(product, taxation, valor, recipient, consumidorFinal),
    pis: getPis(taxation, product, item, valor),
    cofins: getCofins(taxation, product, valor),
  };
};
