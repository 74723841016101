import { RouteRecordRaw } from "vue-router";
import Prescription from "@/views/config/medicalconsultation/prescription/Index.vue";
import Prescriptions from "@/views/config/medicalconsultation/prescription/List.vue";
import MedicalConsultationMenu from "@/components/menu/MedicalConsultationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/medical-consultation/prescription/:id",
  name: "Prescription",
  components: {
    default: Prescription,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/medical-consultation/prescription/list",
  name: "Prescriptions",
  components: {
    default: Prescriptions,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
