import { API_URL } from "@/constants/env";
import { Purchase } from "@/models/interfaces/material/purchase.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import store from "@/store";

export const insert = async (entity: Purchase): Promise<Purchase[]> => {
  const purchase = (
    await axios.post(`${API_URL}/compra/salvar`, prepareData(entity))
  ).data;

  const credential = { ...store.state.login.credential };

  const isCreated = await axios.get(
    `${API_URL}/compra/gerartransacaofinanceira?id=${purchase.Id}&usuario=${credential.userId}`
  );

  return purchase;
};
