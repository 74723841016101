import { RouteRecordRaw } from "vue-router";
import ProductOrder from "../../views/material/productorder/Index.vue";
import ProductOrders from "../../views/material/productorder/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/product-order/:id",
  name: "ProductOrder",
  component: ProductOrder,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/product-order/list",
  name: "ProductOrders",
  component: ProductOrders,
  beforeEnter: Auth,
});

export default itens;
