import {
  TransferAccountState,
  TransferAccount,
} from "@/models/interfaces/financial/transferaccount.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TransferAccountState> = {
  setTransferAccounts(state, payload: TransferAccount[]) {
    state.transferAccounts = payload;
  },

  setTransferAccount(state, payload: TransferAccount) {
    state.transferAccount = payload;
  },

  insertTransferAccount(state, payload: TransferAccount) {
    state.transferAccount = payload;
  },

  removeTransferAccount(state, payload: TransferAccount) {
    state.transferAccount = payload;
  },

  updateTransferAccount(state, payload: TransferAccount) {
    state.transferAccount = payload;
  },
};
