import { LoanState, Loan } from "@/models/interfaces/commercial/loan.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<LoanState> = {
  setLoans(state, payload: Loan[]) {
    state.loans = payload;
  },

  setLoan(state, payload: Loan) {
    state.loan = payload;
  },

  insertLoan(state, payload: Loan) {
    state.loan = payload;
  },

  removeLoan(state, payload: Loan) {
    state.loan = payload;
  },

  updateLoan(state, payload: Loan) {
    state.loan = payload;
  },
};
