import messages from "@/helpers/validate/messages";
import store from "@/store";
import * as yup from "yup";

export default yup.object({
  code: yup
    .string()
    .required(messages.compile({ id: "required", value: "Código" })),
  description: yup
    .string()
    .required(messages.compile({ id: "required", value: "Descrição" })),
  producttype: yup
    .number()
    .typeError(messages.compile({ id: "required", value: "Tipo" })),
  brand: yup
    .number()
    .test(
      "brand",
      messages.compile({ id: "required", value: "Fornecedor" }),
      (value: any) => {
        return !!store.state.product.product.ContatoId;
      }
    ),
});
