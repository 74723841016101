import { API_URL } from "@/constants/env";
import { Product } from "@/models/interfaces/material/product.interface";
import axios from "axios";

export const getById = async (id: string): Promise<Product> => {
  const entity = (await axios.get(`${API_URL}/produtoconf/getid?id=${id}`))
    .data;

  return entity;
};
