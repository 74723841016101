import { Bank, BankState } from "@/models/interfaces/financial/bank.interface";

const bank: Bank = {
  id: "",
  name: "",
  code: "",
};

const banks: Array<Bank> = [];

export const state: BankState = {
  bank,
  banks,
};
