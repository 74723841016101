import {
  RequestingPhysicianState,
  RequestingPhysician,
} from "@/models/interfaces/billing/requestingphysician.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<RequestingPhysicianState> = {
  setRequestingPhysicians(state, payload: RequestingPhysician[]) {
    state.requestingphysicians = payload;
  },

  setRequestingPhysician(state, payload: RequestingPhysician) {
    state.requestingphysician = payload;
  },

  insertRequestingPhysician(state, payload: RequestingPhysician) {
    state.requestingphysician = payload;
  },

  removeRequestingPhysician(state, payload: RequestingPhysician) {
    state.requestingphysician = payload;
  },

  updateRequestingPhysician(state, payload: RequestingPhysician) {
    state.requestingphysician = payload;
  },
};
