import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ConsultationGuide,
  ConsultationGuideState,
} from "@/models/interfaces/billing/consultationguide.interface";

export const getters: GetterTree<ConsultationGuideState, RootState> = {
  getConsultationGuide(state): ConsultationGuide {
    return state.consultationGuide;
  },
};
