import {
  TimeTableState,
  TimeTable,
} from "@/models/interfaces/schedule/timetable.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TimeTableState> = {
  /*   setTimeTables(state, payload: TimeTable[]) {
    state.timeTables = payload;
  }, */

  setTimeTable(state, payload: TimeTable) {
    state.timeTable = payload;
  },

  /*  insertTimeTable(state, payload: TimeTable) {
    state.timeTable = payload;
  },

  removeTimeTable(state, payload: TimeTable) {
    state.timeTable = payload;
  },

  updateTimeTable(state, payload: TimeTable) {
    state.timeTable = payload;
  }, */
};
