import { City, CityState } from "@/models/interfaces/shared/city.interface";

const city: City = {
  id: "",
  name: "",
  ibgecode: "",
  state: undefined,
};

const cities: Array<City> = [];

export const state: CityState = {
  city,
  cities,
};
