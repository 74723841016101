import {
  WorkOrderState,
  WorkOrder,
} from "@/models/interfaces/commercial/workorder.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<WorkOrderState> = {
  setWorkOrders(state, payload: WorkOrder[]) {
    state.workorders = payload;
  },

  setWorkOrder(state, payload: WorkOrder) {
    state.workorder = payload;
  },

  insertWorkOrder(state, payload: WorkOrder) {
    state.workorder = payload;
  },

  removeWorkOrder(state, payload: WorkOrder) {
    state.workorder = payload;
  },

  updateWorkOrder(state, payload: WorkOrder) {
    state.workorder = payload;
  },
};
