import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Partnership,
  PartnershipState,
} from "@/models/interfaces/billing/partnership.interface";
import { PartnershipService as partnership } from "@/services/billing/partnership.services";
import { constants } from "@/constants/billing/partnership";

export const actions: ActionTree<PartnershipState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Partnership[] = await partnership.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_PARTNERSHIPS, res);
  },
  get({ commit }, id): void {
    partnership.get(id).then((res: Partnership) => {
      commit(constants.SET_PARTNERSHIP, res);
    });
  },
  getByTenant({ commit }): void {
    partnership.getByTenant().then((res: Partnership) => {
      commit(constants.SET_PARTNERSHIPS, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Partnership[] = await partnership.insert(state.partnership);
    commit(constants.INSERT_PARTNERSHIP, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await partnership.remove(ids);
    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Partnership[] = await partnership.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_PARTNERSHIPS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Partnership[] = await partnership.update(id, state.partnership);
    commit(constants.UPDATE_PARTNERSHIP, res);
  },
};
