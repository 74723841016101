import {
  ProductOriginState,
  ProductOrigin,
} from "@/models/interfaces/shared/productorigin.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProductOriginState> = {
  setProductOrigins(state, payload: ProductOrigin[]) {
    state.productOrigins = payload;
  },

  setProductOrigin(state, payload: ProductOrigin) {
    state.productOrigin = payload;
  },
};
