import {
  PersonalTreatment,
  PersonalTreatmentState,
} from "@/models/interfaces/shared/personaltreatment.interface";

const personalTreatment: PersonalTreatment = {
  id: "",
  description: "",
};

const personalTreatments: Array<PersonalTreatment> = [];

export const state: PersonalTreatmentState = {
  personalTreatment,
  personalTreatments,
};
