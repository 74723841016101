export const constants = {
  SET_PRODUCTS: "setProducts",
  SET_PRODUCT: "setProduct",
  SET_PRODUCTSSTORE: "setProductsStore",
  SET_PRODUCTSTORE: "setProductStore",
  INSERT_PRODUCT: "insertProduct",
  REMOVE_PRODUCT: "removeProduct",
  UPDATE_PRODUCT: "updateProduct",
  SET_RESULTCODEVERIFY: "setResultCodeVerify",
};
