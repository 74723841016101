import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  SaleConsumer,
  SaleConsumerState,
} from "@/models/interfaces/commercial/saleConsumer.interface";

export const getters: GetterTree<SaleConsumerState, RootState> = {
  getSale(state): SaleConsumer {
    return state.saleConsumer;
  },
};
