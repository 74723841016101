import {
  PassingOnState,
  PassingOn,
} from "@/models/interfaces/financial/passingon.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PassingOnState> = {
  setPassingOns(state, payload: PassingOn[]) {
    state.passingOns = payload;
  },

  setPassingOn(state, payload: PassingOn) {
    state.passingOn = payload;
  },

  insertPassingOn(state, payload: PassingOn) {
    state.passingOn = payload;
  },

  removePassingOn(state, payload: PassingOn) {
    state.passingOn = payload;
  },

  updatePassingOn(state, payload: PassingOn) {
    state.passingOn = payload;
  },
};
