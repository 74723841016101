import {
  InvoiceConsumer,
  InvoiceConsumerState,
} from "@/models/interfaces/commercial/invoiceconsumer.interface";

const invoiceConsumer: InvoiceConsumer = {
  id: "",
  ChaveAcesso: "",
  nfeitem: [],
  CfopId: 0,
  ClienteId: 0,
  Cnpj: "",
  ConsumidorFinal: 0,
  DataEmissaoFormatada: "",
  FinalidadeOperacao: 0,
  Id: 0,
  IdCliente: 0,
  Natureza: "",
  NumeroNf: 0,
  Observacao: "",
  Presencial: 0,
  Saida: 0,
  StatusNfe: 0,
  TipoCliente: 0,
  TipoNf: 0,
  Usuario: 0,
  CenarioImpostos: 0,
};

const invoicesConsumer: Array<InvoiceConsumer> = [];

export const state: InvoiceConsumerState = {
  invoiceConsumer,
  invoicesConsumer,
};
