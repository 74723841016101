import {
  PerformingPhysicianState,
  PerformingPhysician,
} from "@/models/interfaces/billing/performingphysician.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PerformingPhysicianState> = {
  setPerformingPhysicians(state, payload: PerformingPhysician[]) {
    state.performingPhysicians = payload;
  },

  setPerformingPhysician(state, payload: PerformingPhysician) {
    state.performingPhysician = payload;
  },

  insertPerformingPhysician(state, payload: PerformingPhysician) {
    state.performingPhysician = payload;
  },

  removePerformingPhysician(state, payload: PerformingPhysician) {
    state.performingPhysician = payload;
  },

  updatePerformingPhysician(state, payload: PerformingPhysician) {
    state.performingPhysician = payload;
  },
};
