import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Schedule,
  ScheduleState,
} from "@/models/interfaces/schedule/schedule.interface";
import { ScheduleService as service } from "@/services/schedule/schedule.services";
import { constants } from "@/constants/schedule/schedule";

export const actions: ActionTree<ScheduleState, RootState> = {
  /*   async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Block[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_BLOCKS, res);
  }, */
  async get({ commit }, { unit, employee, date, periodNumber }): Promise<void> {
    const res = await service.get(unit, employee, date, periodNumber);
    commit(constants.SET_SCHEDULE, res);
  },
  async getScheduled(
    { commit },
    { unit, employee, date, periodNumber }
  ): Promise<void> {
    const res = await service.getScheduled(unit, employee, date, periodNumber);
    commit(constants.SET_DAILYSCHEDULES, res);
  },

  async getMedicalCareHistory({ commit }, { id }): Promise<void> {
    const res = await service.getMedicalCareHistory(id);
    commit(constants.SET_MEDICALCAREHISTORY, res);
  },

  async setStatus({ commit }, { id, status }): Promise<void> {
    await service.setStatus(id, status);
  },

  async markOff({ commit }, { id, obs, status, motivo, modo }): Promise<void> {
    await service.markoff(id, obs, status, motivo, modo);
  },

  async insert({ commit, state }): Promise<void> {
    const res: Schedule[] = await service.insert(state.schedule);
    /*     commit(constants.INSERT_SCHEDULE, res); */
  },

  async insertFitting({ commit, state }): Promise<void> {
    const res: Schedule[] = await service.insertFitting(state.schedule.fit);
    /*  commit(constants.INSERT_SCHEDULE, res); */
  },
  /*  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Block[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_BLOCKS, res);
  }, */
  async update({ commit, state, rootState }, id): Promise<void> {
    const res: Schedule[] = await service.update(
      id,
      rootState.schedule.schedule
    );
    /*   commit(constants.UPDATE_SCHEDULE, res); */
  },

  async updateFitting({ commit, state, rootState }, id): Promise<void> {
    const res: Schedule[] = await service.updateFitting(
      id,
      rootState.schedule.schedule
    );
    /*  commit(constants.UPDATE_SCHEDULE, res); */
  },

  async reschedule(
    { commit },
    { schedule, markOff, reschedule }
  ): Promise<void> {
    await service.reschedule(schedule, reschedule, markOff);
  },
};
