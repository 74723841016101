import store from "@/store";

export const prepareData = (entity: any) => {
  const credential = { ...store.state.login.credential };

  let Obs = "";

  if (entity.Obs) {
    if (entity.Observacao) Obs = `${entity.Obs} <br/> ${entity.Observacao}`;
    else Obs = entity.Obs;
  }

  const entitySave = {
    Id: entity.Id,
    Serie: entity.Serie,
    Descricao: entity.Descricao,
    Garantia: entity.Garantia,
    Autorizado: entity.Autorizado,
    Obs,
    Valor: entity.Valor,
    ClienteId: entity.personId ? entity.personId : entity.ClienteId,
    UnidadeId: credential.storeId,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
    Tipo: entity.Tipo,
    itens: [],
    DataCadastro: entity.DataCadastro,
    IdOrdemServico: entity.IdOrdemServico,
    StatusOs: entity.StatusOs,
    TrocarSerial: entity.TrocarSerial,
    NovaSerie: entity.NovaSerie,
  };

  return entitySave;
};
