import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Schedule,
  ScheduleState,
} from "@/models/interfaces/schedule/schedule.interface";

export const getters: GetterTree<ScheduleState, RootState> = {
  getSchedule(state): Schedule {
    return state.schedule;
  },
};
