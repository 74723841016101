import {
  MedicalCertificate,
  MedicalCertificateState,
} from "@/models/interfaces/medicalconsultation/medicalcertificate.interface";

const medicalcertificate: MedicalCertificate = {
  id: "",
  description: "",
};

const medicalcertificates: Array<MedicalCertificate> = [];

export const state: MedicalCertificateState = {
  medicalcertificate,
  medicalcertificates,
};
