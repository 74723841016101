import { API_URL } from "@/constants/env";
import { Inventory } from "@/models/interfaces/material/inventory.interface";
import store from "@/store";
import axios from "axios";
import { getSerialNumber } from "./getserialnumber.service";

export const get = async (id: string): Promise<Inventory> => {
  const urlProduct = `${API_URL}/produtoconf?id=${id}`;
  const entityProduct = (await axios.get(urlProduct)).data;

  const productId = entityProduct.IdProduto;
  const description = entityProduct.Nome;

  const url = `${API_URL}/produtoconf/getestoqueporproduto?id=${entityProduct.Id}`;
  const entityInventory = (await axios.get(url)).data;

  const credential = { ...store.state.login.credential };

  const unitInventory = entityInventory.filter(
    (item: any) => item.UnidadeId == credential.storeId
  );

  const initialValue = 0;
  const amount = unitInventory.reduce(
    (accumulator: number, item: any) => accumulator + item.Quantidade,
    initialValue
  );

  const serialnumber = entityProduct.SerieObrigatoria
    ? getSerialNumber(unitInventory)
    : Array<string>();

  const entity: Inventory = {
    id: entityProduct.Id,
    productId,
    description,
    amount,
    serialnumber,
  };

  return entity;
};
