export default function useScheduleStatus() {
  const selectedClass = (status: number) => {
    return scheduledStatus[status]?.class || "";
  };
  const scheduledStatus: Record<
    number,
    { class: string; description: string }
  > = {
    1: { class: "legenda-4 block", description: "Agendado" },
    6: { class: "legenda-2 block", description: "Confirmado" },
    /*    2: { class: "legenda-1 block", description: "Compareceu" }, */
    3: { class: "legenda-3 block", description: "Falta" },
    9: { class: "legenda-1 block atendido", description: "Atendido" },
  };

  const statusFunctions: { [key: string]: () => number } = {
    Agendado: () => 1,
    Confirmado: () => 6,
    Compareceu: () => 2,
    Falta: () => 3,
    Atendido: () => 9,
  };

  const getDescription: { [key: number]: () => string } = {
    0: () => "",
    1: () => "Agendado",
    6: () => "Confirmado",
    2: () => "Compareceu",
    3: () => "Falta",
    9: () => "Atendido",
  };

  const actionFunctions: { [key: string]: () => number } = {
    Desmarcar: () => 1,
    Encaixar: () => 4,
    Reagendar: () => 5,
  };

  return {
    selectedClass,
    scheduledStatus,
    statusFunctions,
    getDescription,
    actionFunctions,
  };
}
