import { get } from "./get.service";
import { getAll } from "./getall.service";
import { getByCode } from "./getByCode.service";
import { getByDescriptionForAutoComplete } from "./getbydescriptionforautocomplete.service";
import { getByDescriptionSerialForAutoComplete } from "./getbydescriptionserialforautocomplete.service";
import { getById } from "./getbyid.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { update } from "./update.service";

export const ProductService = {
  getAll,
  get,
  getById,
  getByDescriptionSerialForAutoComplete,
  getByDescriptionForAutoComplete,
  getByCode,
  insert,
  remove,
  update,
};
