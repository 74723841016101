import { Sale } from "@/models/interfaces/commercial/sale.interface";
import store from "@/store";
import { useDate } from "@/composables/date";

export const prepareData = (entity: Sale) => {
  const credential = { ...store.state.login.credential };

  const accountReceivableTratade = entity.accountReceivable.reduce(
    (resultado: any, item) => {
      resultado.Datas.push(updateDate(item.DataVencimentoFormatada));
      resultado.Valores.push(Number(item.Valor));
      resultado.Meios.push(item.MeioPagamentoId);
      resultado.MeiosPagamento += item.MeioPagamentoId + "|";

      return resultado;
    },
    { Datas: [], Valores: [], Meios: [], MeiosPagamento: "" }
  );
  const items = entity.saleitem.reduce((resultado: any, item) => {
    const r = {
      Quantidade: 0,
      Valor: 0,
      Desconto: 0,
      ProdutoId: 0,
      Serie: "",
      VendaId: "",
    };

    r.Quantidade = item.Quantidade;
    r.Valor = Number(item.Valor);
    r.Desconto = item.Desconto;
    r.ProdutoId = item.id ? item.id : item.ProdutoId;
    r.Serie = item.Serie;
    r.VendaId = entity.Id;

    resultado.push(r);

    return resultado;
  }, []);

  const DataFormatada = entity.Id
    ? entity.DataFormatada
    : useDate("pt-BR").today().format;

  const entitySave = {
    Id: entity.Id,
    QuantidadeParcelas: entity.accountReceivable.length,
    DataFormatada, //"01/04/2024",
    ClienteId: entity.ClienteId,
    UnidadeId: credential.storeId,
    MeioPagamentoId: entity.MeioPagamentoId,
    ProfissionalId: entity.ProfissionalId,
    Itens: items,
    Datas: accountReceivableTratade.Datas,
    Valores: accountReceivableTratade.Valores,
    Meios: accountReceivableTratade.Meios,
    MeiosPagamento: accountReceivableTratade.MeiosPagamento,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
    ContaId: entity.ContaId,
    Observacao: entity.Observacao,
    Tipovenda: 1,
  };

  // 341	GERAR FINANCEIRO - NAO MEXER
  // 587	GERAR FINANCEIRO - NAO MEXER - PAGO
  // 49	GERAR FINANCEIRO E BOLETO - NAO MEXER

  return entitySave;
};

const updateDate = (date: any) => {
  const parts = date.split("/");
  const dateIso = parts[2] + "-" + parts[1] + "-" + parts[0];

  return dateIso;
};
