import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  CivilStatus,
  CivilStatusState,
} from "@/models/interfaces/shared/civilstatus.interface";
import { CivilStatusService as civilstatus } from "@/services/shared/civilstatus.services";
import { constants } from "@/constants/shared/civilstatus";

export const actions: ActionTree<CivilStatusState, RootState> = {
  getAll({ commit }): void {
    civilstatus.getAll().then((res: CivilStatus[]) => {
      commit(constants.SET_CIVILSTATUSS, res);
    });
  },
  get({ commit }, id): void {
    civilstatus.get(id).then((res: CivilStatus) => {
      commit(constants.SET_CIVILSTATUS, res);
    });
  },
};
