//import Sidebar from "@/views/_layout/Sidebar_Financial.vue";
import { RouteRecordRaw } from "vue-router";

//import Main from "@/views/config/financial/financialaccount/List.vue";

import FinancialAccount from "./financialaccountRoute";
import FinancialCategory from "./financialcategoryRoute";
import CostCenter from "./costcenterRoute";
import MeansOfPayment from "./meansofpaymentRoute";

// const main = [
//   {
//     path: "/config/financial/",
//     name: "MainFinancial",
//     component: Main,
//     //beforeEnter: Auth,
//   },
// ];

// const childrenSidebar = [
//   ...main,
//   ...FinancialAccount,
//   ...FinancialCategory,
//   ...CostCenter,
//   ...MeansOfPayment,
// ];

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: "/config/financial/",
//     name: "Sidebar_Financial",
//     component: Sidebar,
//     children: childrenSidebar,
//   },
// ];

// export default routes;

const itens: Array<RouteRecordRaw> = [
  ...FinancialAccount,
  ...FinancialCategory,
  ...CostCenter,
  ...MeansOfPayment,
];
export default itens;
