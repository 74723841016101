import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  InvoiceProduct,
  InvoiceProductState,
} from "@/models/interfaces/commercial/invoiceproduct.interface";

export const getters: GetterTree<InvoiceProductState, RootState> = {
  getNfse(state): InvoiceProduct {
    return state.invoiceProduct;
  },
};
