import { getPis } from "./pis.service";
import { getCofins } from "./cofins.service";
import { getIcms } from "./icms";

export const getTaxation = async (
  product: any,
  taxation: any,
  item: any,
  recipient: any,
  consumidorFinal: boolean,
  valor: any
) => {
  return {
    icms: await getIcms(product, taxation, valor, recipient, consumidorFinal),
    pis: getPis(taxation, product, item, valor),
    cofins: getCofins(taxation, product, valor),
  };
};
