export const useNumber = (n: number) => {
  const formatCurrency = () => {
    return n?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const formatCurrencytoValue = (value: number) => {
    return value?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  return {
    formatCurrency,
    formatCurrencytoValue,
  };
};
