import { Gift, GiftState } from "@/models/interfaces/commercial/gift.interface";

const gift: Gift = {
  id: "",
  personId: 0,
  storeId: 0,
  salemanId: 0,
  userId: 0,
  canceldate: undefined,
  noteofsale: [],
  saleitem: [],
  ProfissionalId: 0,
  ContaId: 0,
  Id: "",
  ClienteId: 0,
  ClienteIdCriptografado: "",
  TipoVenda: 1,
};

const gifts: Array<Gift> = [];

export const state: GiftState = {
  gift,
  gifts,
};
