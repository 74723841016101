import {
  WorkOrder,
  WorkOrderState,
} from "@/models/interfaces/commercial/workorder.interface";

const workorder: WorkOrder = {
  id: "",
  serialnumber: "",
  newserialnumber: undefined,
  productdescription: "",
  productwarranty: false,
  expecteddate: undefined,
  deliverydate: undefined,
  serviceId: 0,
  storeId: 0,
  userId: 0,
  saleId: 0,
  ordernote: [],
  orderAuthorization: undefined,
  internalorder: undefined,
  customerorder: undefined,
  Serie: "",
  Descricao: "",
  Id: "",
  receiptcode: "",
};

const workorders: Array<WorkOrder> = [];

export const state: WorkOrderState = {
  workorder,
  workorders,
};
