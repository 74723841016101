import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Block,
  BlockState,
} from "@/models/interfaces/schedule/block.interface";

export const getters: GetterTree<BlockState, RootState> = {
  getBlock(state): Block {
    return state.block;
  },
};
