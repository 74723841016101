import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductOrigin,
  ProductOriginState,
} from "@/models/interfaces/shared/productorigin.interface";

export const getters: GetterTree<ProductOriginState, RootState> = {
  getProductOrigin(state): ProductOrigin {
    return state.productOrigin;
  },
};
