import {
  Unit,
  UnitState,
} from "@/models/interfaces/administration/unit.interface";

const unit: Unit = {
  /* legalperson */
  id: "",
  cnpj: "",

  /* historiclegalperson */
  corporatename: "",
  fantasyname: "",
  userId: undefined,

  /* unit */
  description: "",
  cantransferinventory: false,
  businessgroupId: undefined,

  /* person */
  finalconsumer: false,

  personnotes: [],

  contacts: [],

  addresses: [],

  documents: [],
};

const units: Array<Unit> = [];

export const state: UnitState = {
  unit,
  units,
};
