import {
  ContactTypeState,
  ContactType,
} from "@/models/interfaces/shared/contacttype.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ContactTypeState> = {
  setContactTypes(state, payload: ContactType[]) {
    state.contactTypes = payload;
  },

  setContactType(state, payload: ContactType) {
    state.contactType = payload;
  },
};
