export const prepareData = (entity: any) => {
  /* '335', 'Fornecedor de Produto Comercializável', '0', '30', '4'
'336', 'Fornecedor', '0', '30', '2'
 */
  const saveContact = {
    Id: entity.Id,
    IdCliente: entity.IdCliente,
    Documento: entity.Documento,
    Nome: entity.Nome,
    TipoContatoId: entity.TipoContatoId,

    Email: "",
    EmailComercial: "",
    Telefone: "",
    TelefoneAux: "",

    Cep: entity.address?.zipcode,
    UfId: entity.address?.stateId,
    CidadeId: entity.address?.cityId,
    Bairro: entity.address?.neighborhood,
    Logradouro: entity.address?.street,
    Numero: entity.address?.number,
    Complemento: entity.address?.complement,

    Observacao: entity.Observacao,

    Usuario: entity.Usuario,
  };

  console.log(entity, entity.contacts);
  debugger;

  for (let i = 0; i < entity.contacts.length; i++) {
    const c = entity.contacts[i];
    if (c.id == 1) saveContact.TelefoneAux = c.description;
    if (c.id == 2) saveContact.Telefone = c.description;
    if (c.id == 5) saveContact.Email = c.description;
  }

  return saveContact;
};
