import {
  FinancialCategory,
  FinancialCategoryState,
  ListFinancialCategory,
} from "@/models/interfaces/financial/financialCategory.interface";

const financialCategory: FinancialCategory = {
  id: "",
  description: "",
  categorytypeId: undefined,
  subcategories: [],
};

const financialCategories: Array<ListFinancialCategory> = [];

export const state: FinancialCategoryState = {
  financialCategory,
  financialCategories,
};
