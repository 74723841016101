import { CivilStatus } from "@/models/interfaces/shared/civilstatus.interface";

export const constants = {
  SET_CIVILSTATUSS: "setCivilStatuss",
  SET_CIVILSTATUS: "setCivilStatus",
};

export const data: CivilStatus[] = [
  { id: "2", description: "Casado" },
  { id: "4", description: "Divorciado(a)" },
  { id: "3", description: "Separado(a)" },
  { id: "1", description: "Solteiro(a)" },
  { id: "5", description: "Viúvo(a)" },
];
