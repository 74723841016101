import { RouteRecordRaw } from "vue-router";
import Reason from "@/views/config/schedule/reason/Index.vue";
import Reasons from "@/views/config/schedule/reason/List.vue";
import ScheduleMenu from "@/components/menu/ScheduleMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/schedule/reason/:id",
  name: "Reason",
  components: {
    default: Reason,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/schedule/reason/list",
  name: "Reasons",
  components: {
    default: Reasons,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});

export default itens;
