import { TestState, Test } from "@/models/interfaces/commercial/test.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TestState> = {
  setTests(state, payload: Test[]) {
    state.tests = payload;
  },

  setTest(state, payload: Test) {
    state.test = payload;
  },

  insertTest(state, payload: Test) {
    state.test = payload;
  },

  removeTest(state, payload: Test) {
    state.test = payload;
  },

  updateTest(state, payload: Test) {
    state.test = payload;
  },
};
