import { API_URL } from "@/constants/env";
import { TaxScenario } from "@/models/interfaces/commercial/taxscenario.interface";
import store from "@/store";
import axios from "axios";

export const getAllCombo = async (): Promise<TaxScenario[]> => {
  const credential = { ...store.state.login.credential };

  const r = (
    await axios.get(
      `${API_URL}/cenarioimpostos/getbyunidade?unidade=${credential.storeId}`
    )
  ).data;

  return r;
};
