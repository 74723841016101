import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  CivilStatus,
  CivilStatusState,
} from "@/models/interfaces/shared/civilstatus.interface";

export const getters: GetterTree<CivilStatusState, RootState> = {
  getCivilStatus(state): CivilStatus {
    return state.civilStatus;
  },
};
