import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  SupplierType,
  SupplierTypeState,
} from "@/models/interfaces/material/suppliertype.interface";

export const getters: GetterTree<SupplierTypeState, RootState> = {
  getSupplierType(state): SupplierType {
    return state.supplierType;
  },
};
