import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Reason,
  ReasonState,
} from "@/models/interfaces/schedule/reason.interface";
import { ReasonService as service } from "@/services/schedule/reason.services";
import { constants } from "@/constants/schedule/reason";

export const actions: ActionTree<ReasonState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Reason[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_REASONS, res);
  },

  async getAllByTenant({ commit }, id): Promise<void> {
    await service.getAllByTenant(id).then((res: Reason) => {
      commit(constants.SET_REASONS, res);
    });
  },

  get({ commit }, id): void {
    service.get(id).then((res: Reason) => {
      commit(constants.SET_REASON, res);
    });
  },

  async insert({ commit, state }): Promise<void> {
    const res: Reason[] = await service.insert(state.reason);
    commit(constants.INSERT_REASON, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    /*     const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Reason[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_REASONS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Reason[] = await service.update(id, state.reason);
    commit(constants.UPDATE_REASON, res);
  },
};
