import {
  ContactType,
  ContactTypeState,
} from "@/models/interfaces/shared/contacttype.interface";

const contactType: ContactType = {
  id: "",
  description: "",
};

const contactTypes: Array<ContactType> = [];

export const state: ContactTypeState = {
  contactType,
  contactTypes,
};
