// messages.js
interface Message {
  [key: string]: string;
}

const schema: Message = {
  itemNotSelected: "Nenhum item foi selecionado.",
  removeConfirm: "Você deseja excluir o item selecionado. Deseja continuar?",
  removesConfirm:
    "Você deseja excluir os itens selecionados. Deseja continuar?",
  recordDeleted: "Registro excluido.",
  recordsDeleted: "Registros excluidos.",
  deletionCanceled: "Exclusão cancelada.",
  canceledDeletions: "Exclusões canceladas.",

  changeStatusActiveConfirm:
    "Você deseja alterar o status do item selecionado. Deseja continuar?",
  changesStatusActiveConfirm:
    "Você deseja alterar o status dos  itens selecionados. Deseja continuar?",
  recordChangeStatusActive: "Registro alterado.",
  recordsChangeStatusActive: "Registros alterados.",
  changeStatusActiveCanceled: "Alteração cancelada.",
  canceledchangesStatusActive: "Alterações canceladas.",

  cashierIsClosed:
    "O caixa está fechado. Favor abrir o caixa para iniciar as vendas.",
};

export default {
  compile: (message: string) => schema[message],
};
