import { Task, TaskState } from "@/models/interfaces/others/task.interface";

const task: Task = {
  id: "",
  description: "",
};

const tasks: Array<Task> = [];

export const state: TaskState = {
  task,
  tasks,
};
