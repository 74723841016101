import { RouteRecordRaw } from "vue-router";
import CashManagement from "../../views/financial/cashmanagement/Index.vue";
import CashesManagement from "../../views/financial/cashmanagement/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/cash-management/:id",
  name: "CashManagement",
  component: CashManagement,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/cash-management/list",
  name: "CashesManagement",
  component: CashesManagement,
  beforeEnter: Auth,
});

export default itens;
