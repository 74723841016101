import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TransferAccount,
  TransferAccountState,
} from "@/models/interfaces/financial/transferaccount.interface";

export const getters: GetterTree<TransferAccountState, RootState> = {
  getTransferAccount(state): TransferAccount {
    return state.transferAccount;
  },
};
