import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  UnitOfMeasurement,
  UnitOfMeasurementState,
} from "@/models/interfaces/shared/unitofmeansurement.interface";

export const getters: GetterTree<UnitOfMeasurementState, RootState> = {
  getUnitOfMeasurement(state): UnitOfMeasurement {
    return state.unitOfMeasurement;
  },
};
