import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  /*  description: yup
    .string()
    .required(messages.compile({ id: "required", value: "Descrição" })),
  supplierCore: yup
    .number()
    .required(messages.compile({ id: "required", value: "Fornecedor" })), */
});
