import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { State, StateState } from "@/models/interfaces/shared/state.interface";
import { StateService as state } from "@/services/shared/state.services";
import { constants } from "@/constants/shared/state";

export const actions: ActionTree<StateState, RootState> = {
  getAll({ commit }): void {
    state.getAll().then((res: State[]) => {
      commit(constants.SET_STATES, res);
    });
  },
  get({ commit }, id): void {
    state.get(id).then((res: State) => {
      commit(constants.SET_STATE, res);
    });
  },
};
