import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Taxation,
  TaxationState,
} from "@/models/interfaces/commercial/taxation.interface";
import { TaxationService as service } from "@/services/commercial/taxation.services";
import { constants } from "@/constants/commercial/invoice/taxation";

export const actions: ActionTree<TaxationState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Taxation[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_TAXATIONS, res);
  },
  async getAllCombo({ commit }): Promise<void> {
    const res: Taxation[] = await service.getAllCombo();
    commit(constants.SET_TAXATIONS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Taxation) => {
      commit(constants.SET_TAXATION, res);
    });
  },
  getByCenarioNcm({ commit }, { cenario, ncm }): void {
    service.getByCenarioNcm(cenario, ncm).then((res: Taxation) => {
      commit(constants.SET_TAXATION, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Taxation[] = await service.insert(state.taxation);
    commit(constants.INSERT_TAXATION, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Taxation[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_TAXATIONS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Taxation[] = await service.update(id, state.taxation);
    commit(constants.UPDATE_TAXATION, res);
  },
};
