import {
  SupplierType,
  SupplierTypeState,
} from "@/models/interfaces/material/suppliertype.interface";

const supplierType: SupplierType = {
  id: "",
  description: "",
};

const supplierTypes: Array<SupplierType> = [];

export const state: SupplierTypeState = {
  supplierType,
  supplierTypes,
};
