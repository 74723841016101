import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Brand,
  BrandState,
} from "@/models/interfaces/material/brand.interface";

export const getters: GetterTree<BrandState, RootState> = {
  getBrand(state): Brand {
    return state.brand;
  },
};
