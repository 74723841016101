import { Customer } from "@/models/interfaces/customer/customer.interface";
import { Address } from "@/models/interfaces/shared/address.interface";
import { Contact } from "@/models/interfaces/shared/contact.interface";
import { Document } from "@/models/interfaces/shared/document.interface";

export const treatedEntity = (entity: any): Customer => {
  const {
    finalconsumer,
    birthdate,
    TelefoneResidencial,
    TelefoneCelular,
    Email,
    CarteiraIdentidade,
    CartaoNacionalSaude,

    Cep,
    UfId,
    UfSigla,
    CidadeId,
    CidadeNome,
    CidadeIbge,
    Bairro,
    Logradouro,
    Numero,
    Complemento,

    CepNf,
    UfNfId,
    UfNfSigla,
    CidadeNfId,
    CidadeNfNome,
    CidadeNfIbge,
    BairroNf,
    LogradouroNf,
    NumeroNf,
    ComplementoNf,

    ...updateEntity
  } = entity;

  const contacts: Contact[] = [];

  if (TelefoneResidencial)
    contacts.push({
      description: TelefoneResidencial,
      contacttypeId: 2,
      contacttypeDescription: "Telefone Residencial",
      id: "2",
    });
  if (TelefoneCelular)
    contacts.push({
      description: TelefoneCelular,
      contacttypeId: 1,
      contacttypeDescription: "Celular",
      id: "1",
    });
  if (Email)
    contacts.push({
      description: Email,
      contacttypeId: 5,
      contacttypeDescription: "Email",
      id: "5",
    });

  const documents: Document[] = [];

  if (CarteiraIdentidade)
    documents.push({
      description: CarteiraIdentidade,
      documenttypeId: 1,
      documenttypeDescription: "Carteira de identidade",
      id: "1",
    });

  if (CartaoNacionalSaude)
    documents.push({
      description: CartaoNacionalSaude,
      documenttypeId: 2,
      documenttypeDescription: "Carteira nacional da saúde",
      id: "2",
    });

  const address: Address = {
    zipcode: Cep,
    state: UfId,
    city: CidadeId,
    neighborhood: Bairro,
    street: Logradouro,
    id: "1",
    ibgecode: CidadeIbge,
    stateId: UfId,
    stateAcronym: UfSigla,
    cityId: CidadeId,
    cityName: CidadeNome,
    number: Numero,
    complement: Complemento,
  };

  const addressnf: Address = {
    zipcode: CepNf,
    state: UfNfId,
    city: CidadeNfId,
    neighborhood: BairroNf,
    street: LogradouroNf,
    id: "2",
    ibgecode: CidadeNfIbge,
    stateId: UfNfId,
    stateAcronym: UfNfSigla,
    cityId: CidadeNfId,
    cityName: CidadeNfNome,
    number: NumeroNf,
    complement: ComplementoNf,
  };

  const treatedEntity = {
    ...updateEntity,
    /* isuser: isNumber(isuser), */
    finalconsumer: finalconsumer == 1,
    birthdate: new Date(birthdate),
    contacts,
    documents,
    address,
    addressnf,
  };

  if (treatedEntity.DataNascimentoFormatada)
    treatedEntity.DataNascimentoFormatada = new Date(entity.DataNascimento)
      .toISOString()
      .split("T")[0];

  if (treatedEntity.DataValidadeConvenioFormatada)
    treatedEntity.DataValidadeConvenioFormatada = new Date(
      entity.DataValidadeConvenio
    )
      .toISOString()
      .split("T")[0];

  if (treatedEntity.personnotes?.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};
