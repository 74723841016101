import { API_URL } from "@/constants/env";
import { BusinessGroup } from "@/models/interfaces/administration/businessgroup.interface";
import axios from "axios";

const getAll = async (): Promise<BusinessGroup[]> => {
  const r = (await axios.get(`${API_URL}/config/administration/businessgroup`))
    .data;
  return r;
};

const get = async (id: string): Promise<BusinessGroup> => {
  if (id == "new") {
    const entity: BusinessGroup = {
      id: "",
      description: "",
    };
    return entity;
  }

  return (
    await axios.get(`${API_URL}/config/administration/businessgroup/${id}`)
  ).data;
};

const insert = async (entity: BusinessGroup): Promise<BusinessGroup[]> => {
  return (
    await axios.post(
      `${API_URL}/config/administration/businessgroup`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<BusinessGroup[]> =>
  (await axios.delete(`${API_URL}/config/administration/businessgroup/${id}`))
    .data;

const update = async (
  id: string,
  entity: BusinessGroup
): Promise<BusinessGroup[]> => {
  return (
    await axios.put(
      `${API_URL}/config/administration/businessgroup/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: BusinessGroup) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const BusinessGroupService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
