import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Fiscal,
  FiscalState,
} from "@/models/interfaces/commercial/fiscal.interface";
import { FiscalService as fiscal } from "@/services/commercial/fiscal.services";
import { constants } from "@/constants/commercial/fiscal";

export const actions: ActionTree<FiscalState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Fiscal[] = await fiscal.getAll(page, searchText, lastFilters);
    commit(constants.SET_FISCALS, res);
  },

  get({ commit }, id): void {
    fiscal.get(id).then((res: Fiscal) => {
      commit(constants.SET_FISCAL, res);
    });
  },

  async insert({ commit, state }): Promise<void> {
    const res: Fiscal[] = await fiscal.insert(state.fiscal);
    commit(constants.INSERT_FISCAL, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await fiscal.remove(ids);

    /*     const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Fiscal[] = await fiscal.getAll(page, searchText, lastFilters);

    commit(constants.SET_FISCALS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Fiscal[] = await fiscal.update(id, state.fiscal);
    commit(constants.UPDATE_FISCAL, res);
  },
};
