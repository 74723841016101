import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Profile,
  ProfileState,
} from "@/models/interfaces/administration/profile.interface";

export const getters: GetterTree<ProfileState, RootState> = {
  getProfile(state): Profile {
    return state.profile;
  },
};
