import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Treatment } from "@/models/interfaces/commercial/treatment.interface";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "planoatendimentoconf").fetchData();

const get = async (id: string): Promise<Treatment> => {
  if (id == "new") {
    const entity: Treatment = {
      id: "",
      description: "",
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/commercial/treatment/${id}`)).data;
};

const insert = async (entity: Treatment): Promise<Treatment[]> => {
  return (
    await axios.post(
      `${API_URL}/config/commercial/treatment`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Treatment[]> =>
  (await axios.delete(`${API_URL}/config/commercial/treatment/${id}`)).data;

const update = async (id: string, entity: Treatment): Promise<Treatment[]> => {
  return (
    await axios.put(
      `${API_URL}/config/commercial/treatment/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Treatment) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const TreatmentService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
