import {
  ProductGenreState,
  ProductGenre,
} from "@/models/interfaces/shared/productgenre.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProductGenreState> = {
  setProductGenres(state, payload: ProductGenre[]) {
    state.productGenres = payload;
  },

  setProductGenre(state, payload: ProductGenre) {
    state.productGenre = payload;
  },
};
