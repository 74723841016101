import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { constants } from "@/constants/shared/address";
import {
  Address,
  AddressState,
} from "@/models/interfaces/shared/address.interface";
import { AddressService } from "@/services/shared/address.services";

export const actions: ActionTree<AddressState, RootState> = {
  async get({ commit }, zipcode): Promise<void> {
    await AddressService.get(zipcode).then((res: Address) => {
      commit(constants.SET_ADDRESS, res);
    });
  },
};
