import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import {
  BankData,
  FinancialAccount,
  FinancialAccountMeansOfPayment,
} from "@/models/interfaces/financial/financialAccount.interface";
import store from "@/store";
import axios from "axios";
/* import { string } from "yup"; */

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "conta").fetchData();

/* const getAllWithMeansofpayments = async (): Promise<FinancialAccount[]> =>
  (
    await axios.get(
      `${API_URL}/config/financial/financialaccount/meansofpayment`
    )
  ).data; */

const getAllWithMeansofpayments = async (): Promise<
  FinancialAccountMeansOfPayment[]
> => {
  const credential = { ...store.state.login.credential };
  const url = `${API_URL}/meiopagamento/todos?cliente=${credential.tenantId}`;
  const response = await axios.get(url);
  const meansOfPaymentToAdd = response.data;

  const meansOfPaymentWithDefaultPermission = meansOfPaymentToAdd.map(
    (meanOfPayment: any) => ({
      meansofpaymentId: meanOfPayment.Id,
      permission: 0, // Defina a permissão como 0 para os novos meios de pagamento
      meansofpaymentDescription: meanOfPayment.Nome,
      permissionDescription: "Não permitir", // Defina a descrição da permissão como "Não permitir"
      discount: 0,
      typediscount: 1,
    })
  );
  return meansOfPaymentWithDefaultPermission;
};

const getAllByTenant = async (): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const url = `${API_URL}/conta/todosporunidade?unidade=${credential.storeId}`;
  return (await axios.get(url)).data;
};

const get = async (id: string): Promise<FinancialAccount> => {
  /*  const meansOfPaymentWithDefaultPermission = await getAllWithMeansofpayments(); 
        financialaccountMeansofpayments: Array<FinancialAccountMeansOfPayment>(),*/

  if (id == "new") {
    const entity: FinancialAccount = {
      id: "0",
      description: "",
      bankId: undefined,
      storeId: 1,
      bankdata: {
        id: undefined,
        branchcode: "",
        branchsortcode: "",
        accountnumber: "",
        accountsortcode: "",
        bankslip: {
          document: "",
          name: "",
          wallet: "",
          portfolioVariation: "",
          code: "",
          digit: "",
          note: "",
          address: {
            zipcode: "",
            state: undefined,
            city: "",
            neighborhood: "",
            street: "",
            id: "",
            ibgecode: undefined,
            stateId: undefined,
            stateAcronym: "",
            cityId: undefined,
            cityName: "",
            number: "",
            complement: "",
          },
        },
      },
    };

    // Concatenar o array original com os meansOfPayment recém-adicionados
    /*  entity.financialaccountMeansofpayments =
      meansOfPaymentWithDefaultPermission; */

    return entity;
  }

  const url = `${API_URL}/conta?id=${id}`;
  const response = await axios.get(url);
  const data = response.data;

  const address = {
    zipcode: data.CedenteCep,
    state: "",
    city: "",
    neighborhood: data.CedenteBairro,
    street: data.CedenteLogradouro,
    id: "",
    ibgecode: undefined,
    stateId: data.UfId,
    cityId: data.CidadeId,
    number: data.CedenteNumero,
    complement: data.CedenteComplemento,
  };

  const bankslip = {
    document: data.CedenteDoc,
    name: data.CedenteNome,
    wallet: data.Carteira,
    portfolioVariation: data.VariacaoCarteira,
    code: data.CedenteCodigo,
    digit: data.CedenteDigCodigo,
    note: data.CedenteObs,
    address,
  };

  data.bankdata = {
    id: undefined,
    branchcode: data.Agencia,
    branchsortcode: data.DigAgencia,
    accountnumber: data.NumConta,
    accountsortcode: data.DigConta,
    bankslip,
  };

  // Concatenar o array original com os meansOfPayment recém-adicionados
  /* data.financialaccountMeansofpayments = meansOfPaymentWithDefaultPermission; */

  return data;
};

const insert = async (
  entity: FinancialAccount
): Promise<FinancialAccount[]> => {
  const list: FinancialAccount[] = (
    await axios.post(`${API_URL}/conta/salvar`, prepareData(entity))
  ).data;

  return list;
};
const remove = async (id: string): Promise<FinancialAccount[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/conta/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: FinancialAccount
): Promise<FinancialAccount[]> => {
  const r = (await axios.post(`${API_URL}/conta/salvar`, prepareData(entity)))
    .data;

  return r;
};

const prepareData = (entity: FinancialAccount) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  const bankslip = entity.bankdata?.bankslip;

  const CedenteDoc = bankslip?.document;
  const CedenteNome = bankslip?.name;
  const CedenteObs = bankslip?.note;
  /*   const Agencia = entity.bankdata?.branchcode; */
  const DigAgencia = entity.bankdata?.branchsortcode;
  const NumConta = entity.bankdata?.accountnumber;
  const DigConta = entity.bankdata?.accountsortcode;
  const Carteira = bankslip?.wallet;
  const VariacaoCarteira = bankslip?.portfolioVariation;
  const CedenteLogradouro = bankslip?.address?.street;
  const CedenteNumero = bankslip?.address?.number;
  const CedenteComplemento = bankslip?.address?.complement;
  const CedenteBairro = bankslip?.address?.neighborhood;
  const CedenteCep = bankslip?.address?.zipcode;
  const CedenteCodigo = bankslip?.code;
  const CedenteDigCodigo = bankslip?.digit;
  /*   const BancoId = entity.bankId; */
  const UfId = bankslip?.address?.stateId;
  const CidadeId = bankslip?.address?.cityId;

  const r = {
    Id,
    IdCliente,
    usuario,
    ...updateEntity,
    /*  CedenteDoc,
    CedenteNome,
    CedenteObs,
        Agencia,
    DigAgencia,
    NumConta,
    DigConta,
    Carteira,
    VariacaoCarteira,
    */

    /*  CedenteLogradouro,
    CedenteNumero,
    CedenteComplemento,
    CedenteBairro,
    CedenteCep, */

    /*
    CedenteCodigo,
    CedenteDigCodigo, */
    /*     BancoId, */
    /*   UfId,
    CidadeId, */
  };

  return r;
};

export const FinancialAccountService = {
  getAll,
  getAllWithMeansofpayments,
  getAllByTenant,
  get,
  insert,
  remove,
  update,
};
