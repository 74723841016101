import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  InvoiceConsumer,
  InvoiceConsumerState,
} from "@/models/interfaces/commercial/invoiceconsumer.interface";

export const getters: GetterTree<InvoiceConsumerState, RootState> = {
  getNfse(state): InvoiceConsumer {
    return state.invoiceConsumer;
  },
};
