import { RouteRecordRaw } from "vue-router";
import CashFlow from "../../views/financial/cashflow/Index.vue";
import CashesFlow from "../../views/financial/cashflow/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/cash-flow/:id",
  name: "CashFlow",
  component: CashFlow,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/cash-flow/list",
  name: "CashesFlow",
  component: CashesFlow,
  beforeEnter: Auth,
});

export default itens;
