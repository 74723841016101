export const FIELD_TYPE = {
  LONG_TEXT: 1,
  SHORT_TEXT: 2,
  NUMBER: 3,
  ON_OFF: 4,
  SELECT_BOX: 5,
  CHECK: 6,
  DATE: 7,
  RADIO: 8,
  GRID: 9,
  TITLE: 10,
  LINE: 11,
};
