import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Customer,
  CustomerState,
} from "@/models/interfaces/customer/customer.interface";

export const getters: GetterTree<CustomerState, RootState> = {
  getCustomer(state): Customer {
    return state.customer;
  },
};
