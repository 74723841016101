import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Test } from "@/models/interfaces/commercial/test.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "teste").fetchData();

const get = async (id: string): Promise<Test> => {
  if (id == "new") {
    const entity: Test = {
      id: "",
      customerId: 0,
      note: "",
      testitem: [],
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/teste?id=${id}`)).data;

  entity.testitem = (
    await axios.get(`${API_URL}/teste/getitens?teste=${entity.Id}`)
  ).data;

  return entity;
};

const insert = async (entity: Test): Promise<Test[]> => {
  return (await axios.post(`${API_URL}/teste/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Test[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/teste/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: Test): Promise<Test[]> => {
  return (await axios.post(`${API_URL}/teste/Salvar`, prepareData(entity)))
    .data;
};

const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /*   const { id, ...updateEntity } = entity; */

  const credential = { ...store.state.login.credential };

  const items = entity.testitem.reduce((resultado: any, item: any) => {
    const r = {
      Quantidade: 0,
      ProdutoId: 0,
      Serie: "",
      TesteId: 0,
    };

    r.Quantidade = item.Quantidade;
    r.ProdutoId = item.id ? item.id : item.ProdutoId;
    r.Serie = item.Serie;

    resultado.push(r);

    return resultado;
  }, []);

  const entitySave = {
    IdTeste: entity.IdTeste,
    ClienteId: entity.ClienteId,
    DataDevolucaoFormatada: entity.DataDevolucaoFormatada,
    DataFormatada: entity.DataFormatada ? entity.DataFormatada : new Date(),
    Id: entity.Id,
    Itens: items,
    Observacao: entity.Observacao,
    ProfissionalId: entity.Id ? entity.ProfissionalId : credential.userId,
    Status: entity.Id ? entity.Status : 1,
    UnidadeId: credential.storeId,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
  };

  return entitySave;
};

export const TestService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
