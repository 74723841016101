import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  FinancialCategory,
  FinancialCategoryState,
} from "@/models/interfaces/financial/financialCategory.interface";

export const getters: GetterTree<FinancialCategoryState, RootState> = {
  getFinancialCategory(state): FinancialCategory {
    return state.financialCategory;
  },
};
