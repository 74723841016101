import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Unit,
  UnitState,
} from "@/models/interfaces/administration/unit.interface";

export const getters: GetterTree<UnitState, RootState> = {
  getUnit(state): Unit {
    return state.unit;
  },
};
