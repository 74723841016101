import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  SaleConsumer,
  SaleConsumerState,
} from "@/models/interfaces/commercial/saleConsumer.interface";
import { SaleConsumerService as service } from "@/services/commercial/saleconsumer";
import { constants } from "@/constants/commercial/sale/consumer";

export const actions: ActionTree<SaleConsumerState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: SaleConsumer[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_SALESCONSUMER, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: SaleConsumer) => {
      commit(constants.SET_SALECONSUMER, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: SaleConsumer[] = await service.insert(state.saleConsumer);
    commit(constants.INSERT_SALECONSUMER, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*   commit(constants.SET_SALESCONSUMER, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: SaleConsumer[] = await service.update(id, state.saleConsumer);
    commit(constants.UPDATE_SALECONSUMER, res);
  },
};
