import {
  BusinessGroupState,
  BusinessGroup,
} from "@/models/interfaces/administration/businessgroup.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<BusinessGroupState> = {
  setBusinessGroups(state, payload: BusinessGroup[]) {
    state.businessGroups = payload;
  },

  setBusinessGroup(state, payload: BusinessGroup) {
    state.businessGroup = payload;
  },

  insertBusinessGroup(state, payload: BusinessGroup) {
    state.businessGroup = payload;
  },

  removeBusinessGroup(state, payload: BusinessGroup) {
    state.businessGroup = payload;
  },

  updateBusinessGroup(state, payload: BusinessGroup) {
    state.businessGroup = payload;
  },
};
