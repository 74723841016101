import {
  TypeOfPaymentMethod,
  TypeOfPaymentMethodState,
} from "@/models/interfaces/financial/typeofpaymentmethod.interface";

const typeOfPaymentMethod: TypeOfPaymentMethod = {
  id: "",
  description: "",
};

const typesOfPaymentMethod: TypeOfPaymentMethod[] = [];

export const state: TypeOfPaymentMethodState = {
  typeOfPaymentMethod,
  typesOfPaymentMethod,
};
