import {
  InvoiceConsumerState,
  InvoiceConsumer,
} from "@/models/interfaces/commercial/invoiceconsumer.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<InvoiceConsumerState> = {
  setInvoices(state, payload: InvoiceConsumer[]) {
    state.invoicesConsumer = payload;
  },

  setInvoice(state, payload: InvoiceConsumer) {
    state.invoiceConsumer = payload;
  },

  insertInvoice(state, payload: InvoiceConsumer) {
    state.invoiceConsumer = payload;
  },

  removeInvoice(state, payload: InvoiceConsumer) {
    state.invoiceConsumer = payload;
  },

  updateInvoice(state, payload: InvoiceConsumer) {
    state.invoiceConsumer = payload;
  },
};
