import { Sale, SaleState } from "@/models/interfaces/commercial/sale.interface";

const sale: Sale = {
  id: "",
  personId: 0,
  storeId: 0,
  salemanId: 0,
  userId: 0,
  canceldate: undefined,
  voucherId: undefined,
  noteofsale: [],
  saleitem: [],
  accountReceivable: [],
  ProfissionalId: 0,
  newInvoice: false,
  ContaId: 0,
  Id: "",
  MeioPagamentoId: 0,
  ClienteId: 0,
  ClienteIdCriptografado: "",
  Invoice: "",
  Presencial: 1,
  ConsumidorFinal: 1,
  TipoVenda: 1,
};

const sales: Array<Sale> = [];

export const state: SaleState = {
  sale,
  sales,
};
