import { BankState, Bank } from "@/models/interfaces/financial/bank.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<BankState> = {
  setBanks(state, payload: Bank[]) {
    state.banks = payload;
  },

  setBank(state, payload: Bank) {
    state.bank = payload;
  },
};
