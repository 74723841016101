import { RouteRecordRaw } from "vue-router";
import PassingOn from "../../views/financial/passingon/Index.vue";
import PassingOns from "../../views/financial/passingon/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/passing-on/:id",
  name: "PassingOn",
  component: PassingOn,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/passing-on/list",
  name: "PassingOns",
  component: PassingOns,
  beforeEnter: Auth,
});

export default itens;
