import {
  ProfileState,
  Profile,
} from "@/models/interfaces/administration/profile.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProfileState> = {
  setProfiles(state, payload: Profile[]) {
    state.profiles = payload;
  },

  setProfile(state, payload: Profile) {
    state.profile = payload;
  },

  insertProfile(state, payload: Profile) {
    state.profile = payload;
  },

  removeProfile(state, payload: Profile) {
    state.profile = payload;
  },

  updateProfile(state, payload: Profile) {
    state.profile = payload;
  },
};
