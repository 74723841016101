export const useDate = (location: string, periodNumber?: number) => {
  const addDays = (date: Date, days: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const format = (date: Date): string => {
    return date.toLocaleString(location).split(",")[0];
  };

  const period = (date: Date, end?: any): string => {
    if (periodNumber) {
      const endDateCaluclated = addDays(date, periodNumber);
      const endDate = end ? format(new Date(end)) : format(endDateCaluclated);

      return `${format(date)} - ${endDate}`;
    }
    return `${format(date)} - ${format(date)}`;
  };

  function dateConvert(data: any) {
    // Verifica se a data está no formato 'yyyy-mm-dd'
    // Verifica se a data está no formato 'yyyy-mm-dd'
    const regexIsoDate = /^\d{4}-\d{2}-\d{2}$/;
    // Verifica se a data está no formato 'dd/mm/yyyy'
    const regexBrDate = /^\d{2}\/\d{2}\/\d{4}$/;

    let dateObj;

    if (regexIsoDate.test(data)) {
      // Se estiver no formato 'yyyy-mm-dd', converte diretamente
      dateObj = new Date(data + "T00:00:00-03:00"); // Adiciona o fuso horário UTC-3
    } else if (regexBrDate.test(data)) {
      // Se estiver no formato 'dd/mm/yyyy', converte para 'yyyy-mm-dd' primeiro
      const partes = data.split("/");
      const dataIso = partes[2] + "-" + partes[1] + "-" + partes[0];
      dateObj = new Date(dataIso + "T00:00:00-03:00"); // Adiciona o fuso horário UTC-3
    } else {
      if (isValidDate(data)) {
        dateObj = new Date(data);
      } else {
        // Se não estiver em nenhum dos formatos suportados, retorna null ou lança um erro
        return null; // ou lança um erro, dependendo do seu caso
      }
    }

    return dateObj;
  }

  const getObject = (date: Date, endDate?: any): Record<string, any> => {
    try {
      const dateTest = dateConvert(date);

      if (dateTest) {
        return {
          date: dateTest,
          format: format(dateTest),
          period: period(dateTest, endDate),
          day: dateTest.getDate(),
          dayofweek: dayName[dateTest.getDay()],
        };
      }
    } catch (e) {
      console.log(e);
    }
    return {};
  };

  const isValidDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const dayName = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
  const monName = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "Maio",
    "junho",
    "agosto",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const today = (): Record<string, any> => {
    return getObject(new Date());
  };

  const formatDateForInvoice = (dataString: string) => {
    // Dividir a string da data para obter dia, mês e ano
    const partes = dataString.split("/");
    if (partes.length == 1) return partes[0];
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];

    /*     console.log(dia); */

    // Criar um novo objeto Date com os valores extraídos
    /* const data = new Date(`${ano}-${mes}-${dia}`);

    console.log(data); */

    // Formatar a data no formato desejado "YYYY-MM-DD"
    /* const dataFormatada = `${data.getFullYear()}-${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${data.getDate().toString().padStart(2, "0")}`;

    console.log(dataFormatada); */
    const dataFormatada = `${ano}-${mes}-${dia}`;

    return dataFormatada;
  };

  return {
    addDays,
    format,
    period,
    getObject,
    today,
    formatDateForInvoice,
  };
};
