import { API_URL } from "@/constants/env";
import { SaleConsumer } from "@/models/interfaces/commercial/saleConsumer.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import { newInvoice } from "./newinvoice.service";

export const update = async (
  id: string,
  entity: SaleConsumer
): Promise<SaleConsumer[]> => {
  const r = (await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity)))
    .data;

  // newInvoice(entity);

  return r;
};
