import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductOrder,
  ProductOrderState,
} from "@/models/interfaces/material/productorder.interface";

export const getters: GetterTree<ProductOrderState, RootState> = {
  getProductOrder(state): ProductOrder {
    return state.productOrder;
  },
};
