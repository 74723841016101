export default (data: any) => {
  const { product, valor, cenarioIcms } = data;
  const aliquota = cenarioIcms.aliquota; // parseFloat(cenarioIcms.aliquota.replace(",", "."));

  return {
    origem: product.OrigemCodigo.toString(),
    cst: cenarioIcms.Cst.toString(),
    aliquota: aliquota,
    baseCalculo: {
      modalidadeDeterminacao: 3, //Valor da operação.
      valor: valor,
    },
  };
};
