import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PassingOn,
  PassingOnState,
} from "@/models/interfaces/financial/passingon.interface";

export const getters: GetterTree<PassingOnState, RootState> = {
  getPassingOn(state): PassingOn {
    return state.passingOn;
  },
};
