import { API_URL } from "@/constants/env";
import { Employee } from "@/models/interfaces/administration/employee.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";
import { Address } from "@/models/interfaces/shared/address.interface";
import { Contact } from "@/models/interfaces/shared/contact.interface";
import { Document } from "@/models/interfaces/shared/document.interface";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "profissional").fetchData();

/* const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const browsing = await { ...store.state.login.browsing };
  browsing.lastFilters = lastFilters;
  browsing.lastSearchText = searchText;
  browsing.lastPage = page;
  store.state.login.browsing = browsing;

  let active = "Ativo";

  for (let i = 0; i < lastFilters?.length; i++) {
    const line = lastFilters[i];

    for (let j = 0; j < lastFilters?.length; j++) {
      const field = line[j];

      if (field.field == "Ativo") {
        active = field.model;
      }
    }
  }

  return (
    await axios.get(
      `${API_URL}/api/profissional?cliente=${credential.tenantId}&pos=${page}&tipoRegistro=${active}&filtro=${searchText}`
    )
  ).data;
}; */

const getAllSchedule = async (unitId: number) => {
  const credential = { ...store.state.login.credential };
  const customer = credential.tenantId;
  const user = credential.userId;
  const permissions = credential.permission;
  const scheduleType = credential.scheduleType;

  let url = `${API_URL}/profissional/todosagenda?cliente=${customer}`;
  url += `&unidade=${unitId}`;
  url += `&tipo=${scheduleType}`;

  const permission = permissions.find(
    (item: any) => item.action == 5 && item.view == 10200
  );

  /*  if (permission.search("5-10200") >= 0) */
  if (permission) url += `&profissionalLogado=${user}`;
  else url += "&profissionalLogado=-1";

  const entity = (await axios.get(url)).data;

  return entity;
};

const get = async (id: string): Promise<Employee> => {
  if (id == "new") {
    const entity: Employee = {
      /* physicalperson */
      id: "",
      CPF: "",
      cpf: "",
      birthdate: undefined,

      /* historicphysicalperson */
      civilstatusId: undefined,
      professionId: undefined,
      genderId: undefined,
      personaltreatmentId: undefined,
      name: "",
      preferredname: "",
      anothergender: "",
      placeofbird: "",
      userId: undefined,

      /* employee */
      storeId: undefined,
      /*   active: true, */
      /* salesman */
      issalesman: false,

      /* user */
      isuser: false,
      profileId: undefined,
      password: "",

      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      address: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      documents: [],
      Id: "",
      Senha: "",
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/profissional?id=${id}`)).data;

  return treatedEntity(entity);

  /*   const { issalesman, isuser, finalconsumer, birthdate, ...updateEntity } =
    entity;

  const treatedEntity = {
    ...updateEntity,
    issalesman: isNumber(issalesman),
    isuser: isNumber(isuser),
    finalconsumer: finalconsumer == 1,
    birthdate: new Date(birthdate),
  };

  return treatedEntity; */
};

const treatedEntity = (entity: any): Employee => {
  const {
    issalesman,
    isuser,
    finalconsumer,
    birthdate,
    TelefoneResidencial,
    TelefoneCelular,
    Email,
    CarteiraIdentidade,
    CartaoNacionalSaude,
    /*     Cep,
    UfId,
    CidadeId,
    Bairro,
    Logradouro,
    Numero,
    Complemento, */

    ...updateEntity
  } = entity;

  const contacts: Contact[] = [];

  if (TelefoneResidencial)
    contacts.push({
      description: TelefoneResidencial,
      contacttypeId: 2,
      contacttypeDescription: "Telefone Residencial",
      id: "2",
    });
  if (TelefoneCelular)
    contacts.push({
      description: TelefoneCelular,
      contacttypeId: 1,
      contacttypeDescription: "Celular",
      id: "1",
    });
  if (Email)
    contacts.push({
      description: Email,
      contacttypeId: 5,
      contacttypeDescription: "Email",
      id: "5",
    });

  const documents: Document[] = [];

  if (CarteiraIdentidade)
    documents.push({
      description: CarteiraIdentidade,
      documenttypeId: 1,
      documenttypeDescription: "Carteira de identidade",
      id: "1",
    });

  if (CartaoNacionalSaude)
    documents.push({
      description: CartaoNacionalSaude,
      documenttypeId: 2,
      documenttypeDescription: "Carteira nacional da saúde",
      id: "2",
    });

  /*   const address: Address = {
    zipcode: Cep,
    state: undefined,
    city: "",
    neighborhood: Bairro,
    street: Logradouro,
    id: "",
    ibgecode: undefined,
    stateId: UfId,
    cityId: CidadeId,
    number: Numero,
    complement: Complemento,
  }; */

  const treatedEntity = {
    ...updateEntity,
    /* isuser: isNumber(isuser), */
    finalconsumer: finalconsumer == 1,
    birthdate: new Date(birthdate),
    contacts,
    documents,
    /*     address, */
  };

  if (treatedEntity.DataNascimentoFormatada)
    treatedEntity.DataNascimentoFormatada = new Date(entity.DataNascimento)
      .toISOString()
      .split("T")[0];

  if (treatedEntity.DataValidadeConvenioFormatada)
    treatedEntity.DataValidadeConvenioFormatada = new Date(
      entity.DataValidadeConvenio
    )
      .toISOString()
      .split("T")[0];

  if (treatedEntity.personnotes?.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};

const isNumber = (value: any) => typeof value === "number";

const insert = async (entity: Employee): Promise<Employee[]> => {
  return (
    await axios.post(`${API_URL}/profissional/Salvar`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<Employee[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/profissional/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: Employee): Promise<Employee[]> => {
  const a = prepareData(entity);

  return (
    await axios.post(`${API_URL}/profissional/Salvar`, prepareData(entity))
  ).data;
};

/* const prepareData = (entity: Employee) => {
  const credential = { ...store.state.login.credential };

  const birthdate = entity.birthdate ? entity.birthdate : undefined;

  const updateEntity = {
    // physicalperson 
    cpf: entity.cpf,
    birthdate: birthdate, //parseDate(birthdate),

    // historicphysicalperson 
    civilstatusId: entity.civilstatusId,
    professionId: entity.professionId,
    genderId: entity.genderId,
    personaltreatmentId: entity.personaltreatmentId,
    name: entity.name,
    preferredname: entity.preferredname,
    anothergender: entity.anothergender,
    placeofbirth: entity.placeofbird,
    userId: credential.userId,

    // employee 
    storeId: entity.storeId,
    //   active: true, 

    // salesman 
    issalesman: entity.issalesman,

    // user //
    isuser: entity.isuser,
    profileId: entity.profileId,
    password: entity.password,

    // person //
    finalconsumer: entity.finalconsumer,

    personnotes: entity.personnotes,
    contacts: entity.contacts,
    addresses: entity.address,
    documents: entity.documents,
    // rooms: entity.rooms,
  };

  return updateEntity;
}; */

const prepareData = (entity: Employee) => {
  const credential = { ...store.state.login.credential };

  const {
    Id,
    birthdate,
    finalconsumer,
    contacts,
    documents,
    /*    address, */
    CPF,
    Senha,
    ...updateEntity
  } = entity;

  let SenhaAux = "datatime";

  if (Senha.length < 21) {
    SenhaAux = Senha;
  }

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  const saveContact = {
    TelefoneResidencial: "",
    TelefoneCelular: "",
    Email: "",
  };
  for (let i = 0; i < contacts.length; i++) {
    const c = contacts[i];
    if (c.id == 1) saveContact.TelefoneCelular = c.description;
    if (c.id == 2) saveContact.TelefoneResidencial = c.description;
    if (c.id == 5) saveContact.Email = c.description;
  }

  const saveDocument = {
    CarteiraIdentidade: "",
    CartaoNacionalSaude: "",
  };
  for (let i = 0; i < documents.length; i++) {
    const d = documents[i];
    if (d.id == "1") saveDocument.CarteiraIdentidade = d.description;
    if (d.id == "2") saveDocument.CartaoNacionalSaude = d.description;
  }

  const saveEntity = {
    ...updateEntity,

    Id: id,
    IdCliente,
    usuario,

    CPF: CPF ? CPF : null,
    Senha,
    SenhaAux,
    /*   Cep: address?.zipcode,
    UfId: address?.stateId,
    CidadeId: address?.cityId,
    Bairro: address?.neighborhood,
    Logradouro: address?.street,
    Numero: address?.number,
    Complemento: address?.complement, */

    TelefoneResidencial: saveContact.TelefoneResidencial,
    TelefoneCelular: saveContact.TelefoneCelular,
    Email: saveContact.Email,

    CarteiraIdentidade: saveDocument.CarteiraIdentidade,
    CartaoNacionalSaude: saveDocument.CartaoNacionalSaude,
  };

  return saveEntity;
};

const parseDate = (date: string | undefined) => {
  if (!date) return undefined;
  // Divide a data original em partes usando "/"
  const partes = date.split("/");

  // Reorganiza as partes no formato ISO 8601
  return partes[2] + "-" + partes[1] + "-" + partes[0];
};

export const EmployeeService = {
  getAll,
  getAllSchedule,
  get,
  insert,
  remove,
  update,
};
