import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Supplier,
  SupplierState,
} from "@/models/interfaces/material/supplier.interface";

export const getters: GetterTree<SupplierState, RootState> = {
  getSupplier(state): Supplier {
    return state.supplier;
  },
};
