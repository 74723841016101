import {
  ProductGenre,
  ProductGenreState,
} from "@/models/interfaces/shared/productgenre.interface";

const productGenre: ProductGenre = {
  id: "",
  description: "",
  code: "",
};

const productGenres: Array<ProductGenre> = [];

export const state: ProductGenreState = {
  productGenre,
  productGenres,
};
