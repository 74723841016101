import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { Bank, BankState } from "@/models/interfaces/financial/bank.interface";
import { BankService as service } from "@/services/financial/bank.services";
import { constants } from "@/constants/financial/bank";

export const actions: ActionTree<BankState, RootState> = {
  getAll({ commit }): void {
    service.getAll().then((res: Bank[]) => {
      commit(constants.SET_BANKS, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: Bank) => {
      commit(constants.SET_BANK, res);
    });
  },
};
