import { BatchState, Batch } from "@/models/interfaces/billing/batch.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<BatchState> = {
  setBatches(state, payload: Batch[]) {
    state.batches = payload;
  },

  setBatch(state, payload: Batch) {
    state.batch = payload;
  },

  insertBatch(state, payload: Batch) {
    state.batch = payload;
  },

  removeBatch(state, payload: Batch) {
    state.batch = payload;
  },

  updateBatch(state, payload: Batch) {
    state.batch = payload;
  },
};
