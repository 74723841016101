import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { MeanOfPayment } from "@/models/interfaces/financial/meanofpayment.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "meiopagamento").fetchData();

const getAllExcept = async (ids: string[]): Promise<MeanOfPayment[]> => {
  const credential = { ...store.state.login.credential };

  const url = `${API_URL}/meiopagamento/todosporperfilunidade?cliente=${credential.tenantId}&perfil=${credential.profileId}&unidade=${credential.storeId}`;
  const list = (await axios.get(url)).data;

  const allExcept = list.filter((item: any) => !ids.includes(item.id));

  return allExcept;
};

const get = async (id: string): Promise<MeanOfPayment> => {
  if (id == "new") {
    const entity: MeanOfPayment = {
      id: "0",
      description: "",
      typeofpaymentmethodId: undefined,
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/meiopagamento?id=${id}`)).data;
};

const insert = async (entity: MeanOfPayment): Promise<MeanOfPayment[]> => {
  return (
    await axios.post(`${API_URL}/meiopagamento/salvar`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<MeanOfPayment[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/meiopagamento/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: MeanOfPayment
): Promise<MeanOfPayment[]> => {
  return (
    await axios.post(`${API_URL}/meiopagamento/salvar`, prepareData(entity))
  ).data;
};

const prepareData = (entity: MeanOfPayment) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  return { Id, IdCliente, usuario, ...updateEntity };
};

export const MeanOfPaymentService = {
  getAll,
  getAllExcept,
  get,
  insert,
  remove,
  update,
};
