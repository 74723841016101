import { RouteRecordRaw } from "vue-router";
import Fiscal from "@/views/config/commercial/fiscal/Index.vue";
import Fiscals from "@/views/config/commercial/fiscal/List.vue";
import CommercialMenu from "@/components/menu/CommercialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/commercial/fiscal/:id",
  name: "Fiscal",
  components: {
    default: Fiscal,
    sidebar: CommercialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/commercial/fiscal/list",
  name: "Fiscals",
  components: {
    default: Fiscals,
    sidebar: CommercialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
