import {
  Partnership,
  PartnershipState,
} from "@/models/interfaces/billing/partnership.interface";

const partnership: Partnership = {
  id: "",
  description: "",
};

const partnerships: Array<Partnership> = [];

export const state: PartnershipState = {
  partnership,
  partnerships,
};
