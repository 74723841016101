import { API_URL } from "@/constants/env";
import { InvoiceProduct } from "@/models/interfaces/commercial/invoiceproduct.interface";
import store from "@/store";
import axios from "axios";

export const remove = async (id: string): Promise<InvoiceProduct[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/nfe/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};
