import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Brand } from "@/models/interfaces/material/brand.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "marca").fetchData();

const getBySupplierCore = async (id: string): Promise<Brand[]> => {
  const url = `${API_URL}/marca/todosporfornecedor?fornecedor=${id}`;
  return (await axios.get(url)).data;
};

const get = async (id: string): Promise<Brand> => {
  if (id == "new") {
    const entity: Brand = {
      id: "0",
      description: "",
      suppliercoreId: undefined,
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/marca?id=${id}`)).data;
};

const insert = async (entity: Brand): Promise<Brand[]> => {
  return (await axios.post(`${API_URL}/marca/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Brand[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/marca/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};
const update = async (id: string, entity: Brand): Promise<Brand[]> => {
  return (await axios.post(`${API_URL}/marca/Salvar`, prepareData(entity)))
    .data;
};

const prepareData = (entity: Brand) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  return updateEntity;
};

export const BrandService = {
  getAll,
  getBySupplierCore,
  get,
  insert,
  remove,
  update,
};
