import {
  ScheduleState,
  Schedule,
  DailySchedule,
} from "@/models/interfaces/schedule/schedule.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ScheduleState> = {
  setDailySchedules(state, payload: DailySchedule[]) {
    state.dailySchedules = payload;
  },

  setDailySchedule(state, payload: DailySchedule) {
    state.dailySchedule = payload;
  },

  setSchedules(state, payload: Schedule[]) {
    state.schedules = payload;
  },

  setSchedule(state, payload: Schedule) {
    state.schedule = payload;
  },

  setMedicalCareHistory(state, payload: any) {
    state.schedules = payload;
  },

  insertSchedule(state, payload: Schedule) {
    state.schedule = payload;
  },

  removeSchedule(state, payload: Schedule) {
    state.schedule = payload;
  },

  updateSchedule(state, payload: Schedule) {
    /* state.schedule = payload; */
  },
};
