import {
  BusinessGroup,
  BusinessGroupState,
} from "@/models/interfaces/administration/businessgroup.interface";

const businessGroup: BusinessGroup = {
  id: "",
  description: "",
};

const businessGroups: Array<BusinessGroup> = [];

export const state: BusinessGroupState = {
  businessGroup,
  businessGroups,
};
