import {
  MedicalCertificateState,
  MedicalCertificate,
} from "@/models/interfaces/medicalconsultation/medicalcertificate.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<MedicalCertificateState> = {
  setMedicalCertificates(state, payload: MedicalCertificate[]) {
    state.medicalcertificates = payload;
  },

  setMedicalCertificate(state, payload: MedicalCertificate) {
    state.medicalcertificate = payload;
  },

  insertMedicalCertificate(state, payload: MedicalCertificate) {
    state.medicalcertificate = payload;
  },

  removeMedicalCertificate(state, payload: MedicalCertificate) {
    state.medicalcertificate = payload;
  },

  updateMedicalCertificate(state, payload: MedicalCertificate) {
    state.medicalcertificate = payload;
  },
};
