import store from "@/store";

export const prepareData = (entity: any, isSale?: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /* const { id, ...updateEntity } = entity; */

  const credential = { ...store.state.login.credential };

  const CfopId = entity.CfopId; //5201
  const NumeroNf = entity.NumeroNf ? entity.NumeroNf : 0;
  const ClienteId = entity.ClienteId; // 165640,
  const Cnpj = credential.tenantCnpj;
  const TipoCliente = entity.TipoCliente; // 1;
  const TipoNf = entity.TipoNf; // 2,
  const StatusNfe = entity.StatusNfe; // 1,
  const Critica = entity.Critica; // null,

  const Observacao = isSale ? entity.Info : entity.Observacao; // "",
  const ChaveAcesso = entity.ChaveAcesso; // null,
  const JustificativaCancelamento = entity.JustificativaCancelamento; // null,
  const DataEmissaoFormatada = entity.DataEmissaoFormatada; // "05/07/2020",
  const Presencial = entity.Presencial;
  const ConsumidorFinal = entity.ConsumidorFinal;
  const Saida = entity.Saida;
  const FinalidadeOperacao = entity.FinalidadeOperacao;
  const Natureza = entity.Natureza;
  const VendaId = isSale ? entity.Id : entity.VendaId;
  const MeioPagamentoId = entity.MeioPagamentoId;
  const CenarioImpostosId = entity.CenarioImpostosId;
  const UnidadeId = credential.storeId;

  const IdCliente = credential.tenantId;

  const Id = isSale ? 0 : entity.Id;
  const Usuario = credential.userId;
  const Itens: {
    Quantidade: any;
    Valor: any;
    ValorDesconto: any;
    ValorTotal: any;
    ProdutoId: any;
    TributacaoId: any;
  }[] = [];

  /*   if (entity.nfeitem) {
    entity.nfeitem.map((item: any) => {
      const desconto = item.ValorDesconto ? item.ValorDesconto : 0;

      Itens.push({
        Quantidade: item.Quantidade,
        Valor: item.Valor,
        ValorDesconto: desconto,
        ValorTotal: item.Quantidade * (item.Valor - desconto),
        ProdutoId: item.id ? item.id : item.ProdutoId,
        TributacaoId: item.TributacaoId ? item.TributacaoId : null, //Não estou mais usando,  busca o a tributacao no cenario
      });
    });
  } else {
    if (entity.saleitem) {
      entity.saleitem.map((item: any) => {
        const desconto = item.ValorDesconto ? item.ValorDesconto : 0;

        Itens.push({
          Quantidade: item.Quantidade,
          Valor: item.Valor,
          ValorDesconto: desconto,
          ValorTotal: item.Quantidade * (item.Valor - desconto),
          ProdutoId: entity.Id ? item.ProdutoId : item.id,
          TributacaoId: item.TributacaoId ? item.TributacaoId : null, //Não estou mais usando,  busca o a tributacao no cenario
        });
      });
    }
  } */

  if (entity.nfeitem) {
    for (let i = 0; i < entity.nfeitem.length; i++) {
      const item = entity.nfeitem[i];
      const desconto = item.ValorDesconto ? item.ValorDesconto : 0;

      Itens.push({
        Quantidade: item.Quantidade,
        Valor: item.Valor,
        ValorDesconto: desconto,
        ValorTotal: item.Quantidade * (item.Valor - desconto),
        ProdutoId: item.id ? item.id : item.ProdutoId,
        TributacaoId: item.TributacaoId ? item.TributacaoId : null, // Não estou mais usando, busca o a tributacao no cenario
      });
    }
  } else {
    if (entity.saleitem) {
      for (let i = 0; i < entity.saleitem.length; i++) {
        const item = entity.saleitem[i];
        const desconto = item.Desconto ? item.Desconto : 0;

        Itens.push({
          Quantidade: item.Quantidade,
          Valor: item.Valor,
          ValorDesconto: desconto,
          ValorTotal: item.Quantidade * (item.Valor - desconto),
          ProdutoId: entity.Id ? item.ProdutoId : item.id,
          TributacaoId: item.TributacaoId ? item.TributacaoId : null, // Não estou mais usando, busca o a tributacao no cenario
        });
      }
    }
  }

  const entitySave = {
    CfopId,
    NumeroNf,
    ClienteId,
    Cnpj,
    TipoCliente,
    TipoNf,
    StatusNfe,
    Critica,
    Observacao,
    ChaveAcesso,
    JustificativaCancelamento,
    Itens,
    DataEmissaoFormatada,
    IdCliente,
    Id,
    Usuario,
    Presencial,
    ConsumidorFinal,
    Saida,
    FinalidadeOperacao,
    Natureza,
    VendaId,
    MeioPagamentoId,
    CenarioImpostosId,
    UnidadeId,
    /*   FormaPagto: 0, */
    /*     VendaId: 744, */
    /*     TransacaoFinanceiraId: 0, */
  };

  return entitySave;
};
