import store from "@/store";

export const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;
  const Usuario = credential.userId;
  const UnidadeId = credential.storeId;
  const CaixaId = credential.cashier;
  const MeioPagamentoId = entity.MeioPagamentoId ? entity.MeioPagamentoId : 0;
  const ValorFormatado = Number(entity.Valor).toString().replace(/\./g, ",");

  const ValorRecebidoFormatado = entity.ValorRecebido
    ? Number(entity.ValorRecebido).toString().replace(/\./g, ",")
    : 0;
  const AcrescimoFormatado = entity.Acrescimo
    ? Number(entity.Acrescimo).toString().replace(/\./g, ",")
    : 0;
  const DescontoFormatado = entity.Desconto
    ? Number(entity.Desconto).toString().replace(/\./g, ",")
    : 0;
  const CategoriaFinanceiraId = 1;
  const QtdeParcelas = entity.QtdeParcelas ? entity.QtdeParcelas : 1;
  const Invoice = entity.Invoice ? entity.Invoice : ""; //boleto
  const Erro = entity.Erro ? entity.Erro : ""; //boleto

  /*   const RepasseId = 0;
  const IdAgenda = 0;

  const UsuarioFechouCaixa = 0;
  const LoteId = 0;
  const PlanoContaId = 1273;
  const CentroCustoId = 0;
  const ContaId = 0;
  const ContaDestinoId = 0;
  const ClienteId = 0;
  const ProfissionalId = 0;
  const ConvenioId = 0;
  const ProcedimentoId = 0;
  const PlanoAtendimentoId = 0;
  const CompraId = 0;
  const VendaId = 0;
  const OrdemServicoId = 0;
  const BoletoId = 0; */
  /*   const MeioPagamentoId = 579; */

  /* const DataVencimentoFormatada = "28/03/2024",
  "DataCompetenciaFormatada = "28/03/2024", */
  /*  const ContatoId = 0;
  const NfeId = 0; */

  return {
    ...updateEntity,
    Id: id,
    IdCliente,
    UnidadeId,
    MeioPagamentoId,
    CaixaId,
    Usuario,
    ValorFormatado,
    ValorRecebidoFormatado,
    AcrescimoFormatado,
    DescontoFormatado,
    CategoriaFinanceiraId,
    QtdeParcelas,
    Invoice,
    Erro,
    /*    QtdeParcelas, */
  };
};
