import messages from "@/helpers/validate/messages";
import { CustomerService as service } from "@/services/customer/index";
import store from "@/store";
import { computed } from "vue";

import * as yup from "yup";

const state = computed(() => store?.state?.gift).value;

export default yup.object({
  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => !!value?.id
    )
    .test(
      "customer",
      messages.compile({ id: "giftCustomer", value: "" }),
      async (value: any) => {
        console.log("teste");
        const customer: any = await service.getById(
          state.gift.ClienteIdCriptografado
        );

        if (!customer.Cpf) return false;

        if (!customer.address) return false;

        if (customer.DocumentoNf) {
          if (!customer.addressnf) return false;
        }

        if (customer.contacts) {
          let hasEmail = false;
          for (const contact of customer.contacts) {
            if (contact.contacttypeDescription == "Email") hasEmail = true;
          }
          return hasEmail;
        } else {
          return false;
        }
      }
    ),

  giftitem: yup
    .array()
    .test(
      "giftitem",
      messages.compile({ id: "requiredList", value: "de itens do brinde" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    ),
});
