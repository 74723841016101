import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Checklist } from "@/models/interfaces/commercial/checklist.interface";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "atestadoconf").fetchData();

const get = async (id: string): Promise<Checklist> => {
  if (id == "new") {
    const entity: Checklist = {
      id: "",
      description: "",
      checklistitems: [],
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/commercial/checklist/${id}`)).data;
};

const insert = async (entity: Checklist): Promise<Checklist[]> => {
  return (
    await axios.post(
      `${API_URL}/config/commercial/checklist`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Checklist[]> =>
  (await axios.delete(`${API_URL}/config/commercial/checklist/${id}`)).data;

const update = async (id: string, entity: Checklist): Promise<Checklist[]> => {
  return (
    await axios.put(
      `${API_URL}/config/commercial/checklist/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Checklist) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const ChecklistService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
