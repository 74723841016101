import { SaleState, Sale } from "@/models/interfaces/commercial/sale.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<SaleState> = {
  setSales(state, payload: Sale[]) {
    state.sales = payload;
  },

  setSale(state, payload: Sale) {
    state.sale = payload;
  },

  insertSale(state, payload: Sale) {
    state.sale = payload;
  },

  removeSale(state, payload: Sale) {
    state.sale = payload;
  },

  updateSale(state, payload: Sale) {
    state.sale = payload;
  },
};
