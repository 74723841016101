import useLocalStorage from "@/composables/localStorage";
import { ProductType } from "@/models/interfaces/shared/producttype.interface";
import store from "@/store";

const storage = useLocalStorage("producttype");

const getAll = async (): Promise<ProductType[]> => {
  const credential = { ...store.state.login.credential };
  return storage.getAll(
    `/tipoprodutoconf/todos?cliente=${credential.tenantId}`
  );
};

const get = async (id: string): Promise<ProductType> => {
  const credential = { ...store.state.login.credential };
  return storage.get(
    id,
    `/tipoprodutoconf/todos?cliente=${credential.tenantId}`
  );
};

export const ProductTypeService = {
  getAll,
  get,
};
