import { API_URL } from "@/constants/env";
import { Product } from "@/models/interfaces/material/product.interface";
import axios from "axios";

export const get = async (id: string): Promise<Product> => {
  if (id == "new") {
    const entity: Product = {
      /* product */
      id: "",
      code: "",

      productypeId: undefined,
      brandId: undefined,
      supplierId: undefined,
      supplierCnpj: "",
      supplierCorporatename: "",
      supplierFantasyname: "",
      userId: undefined,

      /* historic product */
      description: "",
      ncm: "",
      cest: "",
      ean: "",
      price: "",
      discountinreais: "",
      useserialnumber: true,
      movesstock: true,
      returnstostock: true,
      note: "",
      unitofmeasurementId: undefined,
      productoriginId: undefined,
      productgenreId: undefined,

      /* historic product */
      conversionsunitofmeasure: [],
      ContatoId: 0,
      erro: "",
    };

    return entity;
  }
  const url = `${API_URL}/produtoconf?id=${id}`;

  const entity = (await axios.get(url)).data;

  const {
    useserialnumber,
    movesstock,
    returnstostock,
    userId,
    ...updateEntity
  } = entity;

  const treatedEntity = {
    ...updateEntity,
    useserialnumber: useserialnumber == 1,
    movesstock: movesstock == 1,
    returnstostock: returnstostock == 1,
    userId,
  };

  if (treatedEntity.conversionunitofmeasure?.length)
    treatedEntity.conversionunitofmeasure[0].show = true;

  return treatedEntity;
};
