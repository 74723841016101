import {
  ProfessionState,
  Profession,
} from "@/models/interfaces/shared/profession.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProfessionState> = {
  setProfessions(state, payload: Profession[]) {
    state.professions = payload;
  },

  setProfession(state, payload: Profession) {
    state.profession = payload;
  },
};
