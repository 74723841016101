import {
  Consigned,
  ConsignedState,
} from "@/models/interfaces/commercial/consigned.interface";

const consigned: Consigned = {
  id: "",
  partnerId: 0,
  note: "",
  consignedproductitem: [],
};

const consigneds: Array<Consigned> = [];

export const state: ConsignedState = {
  consigned,
  consigneds,
};
