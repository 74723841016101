import { API_URL } from "@/constants/env";
import { MedicalRecords } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";
import axios from "axios";

export const getMedicalRecords = async (
  id: string
): Promise<MedicalRecords> => {
  const credential = { ...store.state.login.credential };

  //https://api.docthor.com.br/api/prontuario/getprontuario?cliente=30&profissional=15&paciente=44066
  const entity: MedicalRecords = (
    await axios.get(
      `${API_URL}/prontuario/getprontuario?cliente=${credential.tenantId}&profissional=${credential.userId}&paciente=${id}`
    )
  ).data;

  entity.atendimentos[0].Status = "ativo";
  entity.atendimentos[0].Today = true;

  return entity;
};
