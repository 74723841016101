import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Purchase,
  PurchaseState,
} from "@/models/interfaces/material/purchase.interface";

export const getters: GetterTree<PurchaseState, RootState> = {
  getPurchase(state): Purchase {
    return state.purchase;
  },
};
