import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  IssuingAuthority,
  IssuingAuthorityState,
} from "@/models/interfaces/shared/issuingauthority.interface";

export const getters: GetterTree<IssuingAuthorityState, RootState> = {
  getIssuingAuthority(state): IssuingAuthority {
    return state.issuingAuthority;
  },
};
