import {
  Person,
  PersonState,
} from "@/models/interfaces/shared/person.interface";

const person: Person = {
  id: "",
  name: "",
  document: "",
  typeperson: "",
};

const persons: Array<Person> = [];

export const state: PersonState = {
  person,
  persons,
};
