import {
  PatientRecord,
  PatientRecordState,
} from "@/models/interfaces/medicalconsultation/patientrecord.interface";

const patientrecord: PatientRecord = {
  id: "",
  description: "",
};

const patientrecords: Array<PatientRecord> = [];

export const state: PatientRecordState = {
  patientrecord,
  patientrecords,
};
