import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  IssuingAuthority,
  IssuingAuthorityState,
} from "@/models/interfaces/shared/issuingauthority.interface";
import { IssuingAuthorityService as issuingAuthority } from "@/services/shared/issuingauthority.services";
import { constants } from "@/constants/shared/issuingauthority";

export const actions: ActionTree<IssuingAuthorityState, RootState> = {
  getAll({ commit }): void {
    issuingAuthority.getAll().then((res: IssuingAuthority[]) => {
      commit(constants.SET_ISSUINGAUTHORITIES, res);
    });
  },
  get({ commit }, id): void {
    issuingAuthority.get(id).then((res: IssuingAuthority) => {
      commit(constants.SET_ISSUINGAUTHORITY, res);
    });
  },
};
