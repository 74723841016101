import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { TransferAccount } from "@/models/interfaces/financial/transferaccount.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "transferencia").fetchData();

const get = async (id: string): Promise<TransferAccount> => {
  if (id == "new") {
    const entity: TransferAccount = {
      id: "",
      description: "",
      value: 0,
      sourcefinancialaccountId: undefined,
      destinationfinancialaccountId: undefined,
      createddate: undefined,
      userId: 0,
      sourceBank: 0,
      sourceDescription: "",
      destinationBank: 0,
      destinationDescription: "",
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/transferencia?id=${id}`)).data;
};

const insert = async (entity: TransferAccount): Promise<TransferAccount[]> => {
  return (
    await axios.post(`${API_URL}/transferencia/Salvar`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<TransferAccount[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/transferencia/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: TransferAccount
): Promise<TransferAccount[]> => {
  return (
    await axios.post(`${API_URL}/transferencia/Salvar`, prepareData(entity))
  ).data;
};

const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;
  const UnidadeId = credential.storeId;
  const CaixaId = credential.cashier;
  const ValorFormatado = entity.Valor;
  const TipoTransacao = 3;

  const date = new Date();

  return {
    Id,
    IdCliente,
    UnidadeId,
    CaixaId,
    ValorFormatado,
    usuario,
    DataVencimentoFormatada: date,
    TipoTransacao,
    ...updateEntity,
  };
};

export const TransferAccountService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
