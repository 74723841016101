import {
  Checklist,
  ChecklistState,
} from "@/models/interfaces/commercial/checklist.interface";

const checklist: Checklist = {
  id: "",
  description: "",
  checklistitems: [],
};

const checklists: Array<Checklist> = [];

export const state: ChecklistState = {
  checklist,
  checklists,
};
