import { RouteRecordRaw } from "vue-router";
import Gift from "../../views/commercial/gift/Index.vue";
import Gifts from "../../views/commercial/gift/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/gift/:id",
  name: "Gift",
  component: Gift,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/gift/list",
  name: "Gifts",
  component: Gifts,
  beforeEnter: Auth,
});

export default itens;
