import {
  AddressState,
  Address,
} from "@/models/interfaces/shared/address.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<AddressState> = {
  setAddress(state, payload: Address) {
    state.address = payload;
  },
};
