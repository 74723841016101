import {
  DashboardState,
  Dashboard,
} from "@/models/interfaces/dashboard/dashboard.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<DashboardState> = {
  setDashboard(state, payload: Dashboard) {
    state.dashboard = payload;
  },
};
