import {
  GenderState,
  Gender,
} from "@/models/interfaces/shared/gender.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<GenderState> = {
  setGenders(state, payload: Gender[]) {
    state.genders = payload;
  },

  setGender(state, payload: Gender) {
    state.gender = payload;
  },
};
