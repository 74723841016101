import { API_URL } from "@/constants/env";
import { Batch } from "@/models/interfaces/billing/batch.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "lote").fetchData();

const getByName = async (name: string): Promise<Batch[]> => {
  const r = (await axios.get(`${API_URL}/batch/name/${name}`)).data;
  return r;
};

const get = async (id: string): Promise<Batch> => {
  if (id == "new") {
    const entity: Batch = {
      id: "",
      description: "",
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/batch/${id}`)).data;

  const { /* isuser, */ finalconsumer, birthdate, ...updateEntity } = entity;

  const treatedEntity = {
    ...updateEntity,
    /* isuser: isNumber(isuser), */
    finalconsumer: finalconsumer == 1,
    birthdate: new Date(birthdate),
  };

  if (treatedEntity.personnotes?.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};

const insert = async (entity: Batch): Promise<Batch[]> => {
  return (await axios.post(`${API_URL}/batch`, prepareData(entity))).data;
};

const remove = async (id: string): Promise<Batch[]> =>
  (await axios.delete(`${API_URL}/batch/${id}`)).data;

const update = async (id: string, entity: Batch): Promise<Batch[]> => {
  return (await axios.put(`${API_URL}/batch/${id}`, prepareData(entity))).data;
};

const prepareData = (entity: Batch) => {
  /* const credential = { ...store.state.login.credential }; */

  const updateEntity = {
    description: entity.description,
  };

  return updateEntity;
};

export const BatchService = {
  getAll,
  get,
  getByName,
  insert,
  remove,
  update,
};
