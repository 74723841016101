export const view = {
  SCHEDULE: 10200, // agenda
  CUSTOMER: 10100, // cliente
  ACCOUNTS_RECEIVABLE: 10601, // contas a receber
  ACCOUNTS_PAYABLE: 10602, // contas a pagar
  TRANSFER_ACCOUNTS: 10603, // transfêrencia
  CASH_MANAGEMENT: 0, // gestão de caixa
  SALE_CONSUMER: 0, // venda de balcao
  SALE_PRODUCT: 10301, // venda
  VOUCHER: 11009, // cupom de desconto
  LOAN: 11007, // emprestimo
  TEST: 11008, // teste
  WORKORDER_CUSTOMER: 11102, // ordem de servico cliente
  WORKORDER_INTERNAL: 11101, // ordem de servico interno
  INVOICE_PRODUCT: 10801, // nfe
  INVOICE_CONSUMER: 0, // nfce
};

export const listView = {
  SCHEDULE: 10200, // agenda
  CUSTOMERS: 10100, // cliente
  ACCOUNTS_RECEIVABLE: 10601, // contas a receber
  ACCOUNTS_PAYABLE: 10602, // contas a pagar
  TRANSFERS_ACCOUNTS: 10603, // transfêrencia
  //CASHES_MANAGEMENT: 2123, // gestão de caixa
  //SALES_CONSUMER: 0, // venda de balcao
  SALES_PRODUCT: 11004, // venda
  VOUCHERS: 11009, // cupom de desconto
  LOANS: 11007, // emprestimo
  TESTS: 11008, // teste
  WORKORDERS_CUSTOMER: 11102, // ordem de servico cliente
  WORKORDERS_INTERNAL: 11101, // ordem de servico interno
  INVOICES_PRODUCT: 10801, // nfe
  //INVOICES_CONSUMER: 0, // nfce
};
