import {
  ConsultationGuideState,
  ConsultationGuide,
} from "@/models/interfaces/billing/consultationguide.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ConsultationGuideState> = {
  setConsultationGuides(state, payload: ConsultationGuide[]) {
    state.consultationGuides = payload;
  },

  setConsultationGuide(state, payload: ConsultationGuide) {
    state.consultationGuide = payload;
  },

  insertConsultationGuide(state, payload: ConsultationGuide) {
    state.consultationGuide = payload;
  },

  removeConsultationGuide(state, payload: ConsultationGuide) {
    state.consultationGuide = payload;
  },

  updateConsultationGuide(state, payload: ConsultationGuide) {
    state.consultationGuide = payload;
  },
};
