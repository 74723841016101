/* import { API_URL } from "@/constants/env";
import { ProductGenre } from "@/models/interfaces/shared/productgenre.interface";
import axios from "axios";

const getAll = async (): Promise<ProductGenre[]> => {
  const r = (await axios.get(`${API_URL}/shared/productgenre`)).data;
  return r;
};

const get = async (id: string): Promise<ProductGenre> => {
  return (await axios.get(`${API_URL}/shared/productgenre/${id}`)).data;
};

export const ProductGenreService = {
  getAll,
  get,
};
 */

import useLocalStorage from "@/composables/localStorage";
import { ProductGenre } from "@/models/interfaces/shared/productgenre.interface";
import store from "@/store";

const storage = useLocalStorage("productgenre");

const getAll = async (): Promise<ProductGenre[]> => {
  const credential = { ...store.state.login.credential };
  return storage.getAll(`/generoconf/todos?cliente=${credential.tenantId}`);
};

const get = async (id: string): Promise<ProductGenre> => {
  const credential = { ...store.state.login.credential };
  return storage.get(id, `/generoconf/todos?cliente=${credential.tenantId}`);
};

export const ProductGenreService = {
  getAll,
  get,
};
