import { API_URL } from "@/constants/env";
import { Supplier } from "@/models/interfaces/material/supplier.interface";
import axios from "axios";
import { treatedEntity } from "./treatedentity.service";

export const get = async (id: string): Promise<Supplier> => {
  if (id == "new") {
    const entity: Supplier = {
      /* legalperson */
      id: "",
      cnpj: "",

      /* historiclegalperson */
      corporatename: "",
      fantasyname: "",
      userId: 1,

      /* supplier */
      iscore: false,
      suppliertypes: [],

      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      address: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      documents: [],
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/contato?id=${id}`)).data;

  const entitySave = treatedEntity(entity);

  return entitySave;
};
