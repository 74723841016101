import { ref } from "vue";
export const ACTIVE = {
  field: "Ativo",
  title: "Ativo",
  items: [
    { id: "Ativo", description: "Sim" },
    { id: "Inativo", description: "Não" },
  ],
  type: "SELECT",
  model: ref("Ativo"),
};

export const NF_TYPE = {
  field: "TipoNf",
  title: "Tipo",
  items: undefined,
  type: "STRING",
  model: ref(),
};

export const SALE_TYPE = {
  field: "TipoVenda",
  title: "Tipo",
  items: undefined,
  type: "STRING",
  model: ref(),
};

export const STORE = {
  field: "Unidade.Id",
  title: "Loja",
  items: undefined,
  type: "NUMBER",
  model: ref(),
};

export const USER = {
  field: "Profissional.Id",
  title: "Usuário",
  items: undefined,
  type: "NUMBER",
  model: ref(),
};

export const PERIOD = {
  field: "tipoRegistro",
  title: "Período",
  items: [
    { id: "Todos", description: "Todos" },
    { id: "Mês", description: "Mês" },
    { id: "Hoje", description: "Hoje" },
    { id: "Amanhã", description: "Amanhã" },
    { id: "Ontem", description: "Ontem" },
  ],
  type: "SELECT",
  model: ref("Mês"),
};

export const WORKORDER_STATUS = {
  field: "tipoRegistro",
  title: "Situação",
  items: [
    { id: "TODOS", description: "Todos" },
    { id: "CADASTRADO", description: "Cadastrado" },
    { id: "ESTOQUE", description: "Estoque" },
    { id: "LABORATORIO", description: "Laboratório" },
    { id: "SETOR DE GARANTIA", description: "Setor de garantia" },
    { id: "FABRICA", description: "Fabrica" },
    { id: "ESTOQUE", description: "Estoque" },
    { id: "UNIDADE", description: "Unidade" },
    { id: "CONCLUIDO", description: "Concluído" },
  ],
  type: "SELECT",
  model: ref("TODOS"),
};
