import {
  OtherGuideState,
  OtherGuide,
} from "@/models/interfaces/billing/otherguide.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<OtherGuideState> = {
  setOtherGuides(state, payload: OtherGuide[]) {
    state.otherGuides = payload;
  },

  setOtherGuide(state, payload: OtherGuide) {
    state.otherGuide = payload;
  },

  insertOtherGuide(state, payload: OtherGuide) {
    state.otherGuide = payload;
  },

  removeOtherGuide(state, payload: OtherGuide) {
    state.otherGuide = payload;
  },

  updateOtherGuide(state, payload: OtherGuide) {
    state.otherGuide = payload;
  },
};
