import { API_URL } from "@/constants/env";
import { PersonalTreatment } from "@/models/interfaces/shared/personaltreatment.interface";
import axios from "axios";

const getAll = async (): Promise<PersonalTreatment[]> => {
  const r = (await axios.get(`${API_URL}/shared/personaltreatment`)).data;
  return r;
};

const get = async (id: string): Promise<PersonalTreatment> => {
  return (await axios.get(`${API_URL}/shared/personaltreatment/${id}`)).data;
};

export const PersonalTreatmentService = {
  getAll,
  get,
};
