import { RouteRecordRaw } from "vue-router";
import TransferAccounts from "../../views/financial/transferaccounts/Index.vue";
import TransfersAccounts from "../../views/financial/transferaccounts/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/transfer-accounts/:id",
  name: "TransferAccounts",
  component: TransferAccounts,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/transfer-accounts/list",
  name: "TransfersAccounts",
  component: TransfersAccounts,
  beforeEnter: Auth,
});

export default itens;
