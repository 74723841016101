import { RouteRecordRaw } from "vue-router";
import PatientRecord from "@/views/config/medicalconsultation/patientrecord/Index.vue";
import PatientsRecord from "@/views/config/medicalconsultation/patientrecord/List.vue";
import MedicalConsultationMenu from "@/components/menu/MedicalConsultationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/medical-consultation/patient-record/:id",
  name: "PatientRecord",
  components: {
    default: PatientRecord,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/medical-consultation/patient-record/list",
  name: "PatientsRecord",
  components: {
    default: PatientsRecord,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
