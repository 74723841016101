import {
  Profile,
  ProfileState,
} from "@/models/interfaces/administration/profile.interface";

const profile: Profile = {
  id: "",
  description: "",
  modules: [],
};

const profiles: Array<Profile> = [];

export const state: ProfileState = {
  profile,
  profiles,
};
