import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { Sale, SaleState } from "@/models/interfaces/commercial/sale.interface";
import { SaleService as service } from "@/services/commercial/sale";
import { constants } from "@/constants/commercial/sale/product";

export const actions: ActionTree<SaleState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Sale[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_SALES, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Sale) => {
      commit(constants.SET_SALE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Sale[] = await service.insert(state.sale);
    commit(constants.INSERT_SALE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*    commit(constants.SET_SALES, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Sale[] = await service.update(id, state.sale);
    commit(constants.UPDATE_SALE, res);
  },
};
