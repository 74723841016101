import messages from "@/helpers/validate/messages";
import store from "@/store";
import * as yup from "yup";

export default yup.object({
  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => {
        const result = !!value?.id;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
  productDescription: yup
    .string()
    .test(
      "description",
      messages.compile({ id: "required", value: "Descrição" }),
      (value: any) => {
        console.log(value);
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
});
