import { Batch, BatchState } from "@/models/interfaces/billing/batch.interface";

const batch: Batch = {
  id: "",
  description: "",
};

const batches: Array<Batch> = [];

export const state: BatchState = {
  batch,
  batches,
};
