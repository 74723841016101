import {
  FinancialCategoryState,
  FinancialCategory,
} from "@/models/interfaces/financial/financialCategory.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<FinancialCategoryState> = {
  setFinancialCategories(state, payload: FinancialCategory[]) {
    state.financialCategories = payload;
  },

  setFinancialCategory(state, payload: FinancialCategory) {
    state.financialCategory = payload;
  },

  insertFinancialCategory(state, payload: FinancialCategory) {
    state.financialCategory = payload;
  },

  removeFinancialCategory(state, payload: FinancialCategory) {
    state.financialCategory = payload;
  },

  updateFinancialCategory(state, payload: FinancialCategory) {
    state.financialCategory = payload;
  },
};
