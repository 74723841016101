import { RouteRecordRaw } from "vue-router";
import Purchase from "../../views/material/purchase/Index.vue";
import Purchases from "../../views/material/purchase/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/purchase/:id",
  name: "Purchase",
  component: Purchase,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/purchase/list",
  name: "Purchases",
  component: Purchases,
  beforeEnter: Auth,
});

export default itens;
