import { RouteRecordRaw } from "vue-router";
import Login from "../../views/security/login/Index.vue";
import NaoAutorizadoView from "@/views/NaoAutorizadoView.vue";
// import NovaSenha from "../views/login/NovaSenha.vue";
// import RecuperarSenha from "../views/login/RecuperarSenha.vue";
// import NotFoundView from "@/views/NotFoundView.vue";

const itens: Array<RouteRecordRaw> = [];

/* itens.push({
  path: "/login",
  name: "Login",
  component: Login,
}); */
// itens.push({
//   path: "/novaSenha/:hashSeguranca",
//   name: "NovaSenha",
//   component: NovaSenha,
// });
// itens.push({
//   path: "/recuperarSenha",
//   name: "RecuperarSenha",
//   component: RecuperarSenha,
// });
itens.push({
  path: "/naoautorizado",
  name: "NaoAutorizado",
  component: NaoAutorizadoView,
});
// itens.push({
//   path: "/:catchAll(.*)",
//   name: "NotFound",
//   component: NotFoundView,
//   meta: { requiresAuth: false },
// });

export default itens;
