import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { locale } from "./constants/prime-locale";

import PrimeVue from "primevue/config";

//theme
/* import "primevue/resources/themes/lara-light-indigo/theme.css"; */

//core
/* import "primevue/resources/primevue.min.css"; */

//icons
/* import "primeicons/primeicons.css"; */

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Card from "primevue/card";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import Dropdown from "primevue/dropdown";
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";
import TextArea from "primevue/textarea";
import ConfirmPopup from "primevue/confirmpopup";
import Dialog from "primevue/dialog";

/* import { defineRule } from "vee-validate";
import * as Yup from "yup";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Error {
  message: string;
}

defineRule("validateEmail", (value: any) => {
  const schema = Yup.string()
    .email(`E-mail inválido`)
    .required(`E-mail brigatório`);

  try {
    schema.validateSync(value);
  } catch (error: Error | any) {
    return error.message;
  }

  return true;
});
*/

const app = createApp(App);

app.component("Button", Button);
app.component("InputText", InputText);
app.component("Toast", Toast);
app.component("Card", Card);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Dropdown", Dropdown);
app.component("DynamicDialog", DynamicDialog);
app.component("TextArea", TextArea);
app.component("ConfirmPopup", ConfirmPopup);
app.component("Dialog", Dialog);

app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);

app.use(router);
app.use(store);

app.use(PrimeVue, locale);

app.mount("#app");
