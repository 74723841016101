import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  RequestingPhysician,
  RequestingPhysicianState,
} from "@/models/interfaces/billing/requestingphysician.interface";

export const getters: GetterTree<RequestingPhysicianState, RootState> = {
  getRequestingPhysician(state): RequestingPhysician {
    return state.requestingphysician;
  },
};
