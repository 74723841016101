import {
  StockTransferState,
  StockTransfer,
} from "@/models/interfaces/material/stocktransfer.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<StockTransferState> = {
  setStockTransfers(state, payload: StockTransfer[]) {
    state.stockTransfers = payload;
  },

  setStockTransfer(state, payload: StockTransfer) {
    state.stockTransfer = payload;
  },

  insertStockTransfer(state, payload: StockTransfer) {
    state.stockTransfer = payload;
  },

  removeStockTransfer(state, payload: StockTransfer) {
    state.stockTransfer = payload;
  },

  updateStockTransfer(state, payload: StockTransfer) {
    state.stockTransfer = payload;
  },
};
