import {
  Dashboard,
  DashboardState,
} from "@/models/interfaces/dashboard/dashboard.interface";

const dashboard: Dashboard = {
  Agendados: 0,
  Confirmados: 0,
  Atendidos: 0,
  Faltaram: 0,
  LembretesMes: [],
  LembretesHoje: [],
  AniversariantesDia: [],
  Id: 0,
  Apagado: false,
  Ativo: true,
  Usuario: 0,
};

export const state: DashboardState = {
  dashboard,
};
