import store from "@/store";
import { useDate } from "@/composables/date";

export const prepareData = (entity: any) => {
  const credential = { ...store.state.login.credential };

  const accountReceivableTratade = entity.accountReceivable.reduce(
    (
      resultado: any,
      item: {
        DataVencimentoFormatada: any;
        Valor: any;
        MeioPagamentoId: string;
      }
    ) => {
      resultado.Datas.push(updateDate(item.DataVencimentoFormatada));
      resultado.Valores.push(Number(item.Valor));
      resultado.Meios.push(item.MeioPagamentoId);
      resultado.MeiosPagamento += item.MeioPagamentoId + "|";

      return resultado;
    },
    { Datas: [], Valores: [], Meios: [], MeiosPagamento: "" }
  );
  const items = entity.Itens.reduce(
    (
      resultado: any,
      item: {
        Quantidade: number;
        Valor: any;
        Desconto: number;
        id: number;
        ProdutoId: number;
        Serie: string;
      }
    ) => {
      const r = {
        Quantidade: 0,
        Valor: 0,
        Desconto: 0,
        ProdutoId: 0,
        Serie: "",
        CompraId: "",
      };

      r.Quantidade = item.Quantidade;
      r.Valor = Number(item.Valor);
      r.Desconto = item.Desconto;
      r.ProdutoId = item.id ? item.id : item.ProdutoId;
      r.Serie = item.Serie;
      r.CompraId = entity.Id;

      resultado.push(r);

      /*  [
        {
          "$id": "2",
          "Quantidade": 1,
          "Serie": "111",
          "Valor": 2000,
          "ValorFormatado": "2000,0000",
          "Desconto": 0,
          "Ipi": 0,
          "ValorTotal": 2000,
         ] */

      return resultado;
    },
    []
  );

  /*   const DataFormatada = entity.Id
    ? entity.DataFormatada
    : useDate("pt-BR").today().format; */

  const entitySave = {
    Id: entity.Id,
    DataFormatada: entity.DataFormatada,
    Observacao: entity.Observacao,
    NotaFiscal: entity.NotaFiscal,
    /*  Valor: 6200,
    Gerado: true, */
    /*  ValorFormatado: "6200,00", */
    ContatoId: entity.ContatoId,
    UnidadeId: credential.storeId,
    Itens: items,
    QuantidadeParcelas: entity.accountReceivable.length,
    Datas: accountReceivableTratade.Datas,
    /*     DatasFinanceiro: "01/04/2022 09:49:50,", */
    Valores: accountReceivableTratade.Valores,
    Meios: accountReceivableTratade.Meios,
    MeiosPagamento: accountReceivableTratade.MeiosPagamento,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
    ContaId: entity.ContaId,
  };
  console.log(entitySave);
  return entitySave;
};

const updateDate = (date: any) => {
  const parts = date.split("/");
  const dateIso = parts[2] + "-" + parts[1] + "-" + parts[0];

  return dateIso;
};
