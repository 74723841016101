import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  MedicalCertificate,
  MedicalCertificateState,
} from "@/models/interfaces/medicalconsultation/medicalcertificate.interface";
import { MedicalCertificateService as medicalCertificate } from "@/services/medicalconsultation/medicalcertificate.services";
import { constants } from "@/constants/medicalconsultation/medicalcertificate";

export const actions: ActionTree<MedicalCertificateState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: MedicalCertificate[] = await medicalCertificate.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_MEDICALCERTIFICATES, res);
  },
  get({ commit }, id): void {
    medicalCertificate.get(id).then((res: MedicalCertificate) => {
      commit(constants.SET_MEDICALCERTIFICATE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: MedicalCertificate[] = await medicalCertificate.insert(
      state.medicalcertificate
    );
    commit(constants.INSERT_MEDICALCERTIFICATE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await medicalCertificate.remove(ids);
    /*  const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: MedicalCertificate[] = await medicalCertificate.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_MEDICALCERTIFICATES, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: MedicalCertificate[] = await medicalCertificate.update(
      id,
      state.medicalcertificate
    );
    commit(constants.UPDATE_MEDICALCERTIFICATE, res);
  },
};
