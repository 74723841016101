export const getCfop = (taxation: any, product: any, recipient: any) => {
  const ufRecipient = recipient.endereco.estado;

  //const documentType = identifyDocument(recipient.cpfCnpj);

  const ie = recipient.inscricaoEstadual;

  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const cenariosCfop = ncm.CenarioCfops.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioCfop = cenariosCfop[0];

  // const cfopType = documentType == "CPF" ? "CfopNaoContribuinteId" : "CfopId";
  const cfopType = ie ? "CfopId" : "CfopNaoContribuinteId";

  return cenarioCfop ? cenarioCfop[cfopType]?.toString() : "";
};

/* const identifyDocument = (number: string) => {
  // Remover qualquer caractere que não seja um dígito
  const justNumber = number.replace(/\D/g, "");

  if (justNumber.length === 11) {
    return "CPF";
  } else if (justNumber.length === 14) {
    return "CNPJ";
  } else {
    return "Número inválido";
  }
}; */
