import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Partnership,
  PartnershipState,
} from "@/models/interfaces/billing/partnership.interface";

export const getters: GetterTree<PartnershipState, RootState> = {
  getPartnership(state): Partnership {
    return state.partnership;
  },
};
