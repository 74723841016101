import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PersonalTreatment,
  PersonalTreatmentState,
} from "@/models/interfaces/shared/personaltreatment.interface";

export const getters: GetterTree<PersonalTreatmentState, RootState> = {
  getPersonalTreatment(state): PersonalTreatment {
    return state.personalTreatment;
  },
};
