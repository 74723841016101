import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  FinancialAccount,
  FinancialAccountState,
} from "@/models/interfaces/financial/financialAccount.interface";

export const getters: GetterTree<FinancialAccountState, RootState> = {
  getFinancialAccount(state): FinancialAccount {
    return state.financialAccount;
  },
};
