export const identifyDocument = (number: string) => {
  // Remover qualquer caractere que não seja um dígito
  const justNumber = number?.replace(/\D/g, "");

  if (!justNumber) {
    return "CPF";
  } else if (justNumber.length === 14) {
    return "CNPJ";
  } else {
    return "CPF";
  }
};
