import { API_URL } from "@/constants/env";
import { WorkOrder } from "@/models/interfaces/commercial/workorder.interface";
import axios from "axios";
import { prepareData } from "./preparadata.service";

export const insert = async (entity: WorkOrder): Promise<WorkOrder[]> => {
  return (
    await axios.post(`${API_URL}/ordemservico/Salvar`, prepareData(entity))
  ).data;
};
