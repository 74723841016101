//import Sidebar from "@/views/_layout/Sidebar_Administration.vue";
import { RouteRecordRaw } from "vue-router";

//import Main from "@/views/config/administration/employee/List.vue";

import Employee from "./employeeRoute";
import Profile from "./profileRoute";
import Room from "./roomRoute";
import Unit from "./unitRoute";

// const main = [
//   {
//     path: "/config/administration/",
//     name: "MainAdministration",
//     component: Main,
//     //beforeEnter: Auth,
//   },
// ];

// const childrenSidebar = [ ...Employee, ...Profile, ...Room, ...Store];

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: "/config/administration/",
//     name: "Sidebar_Administration",
//     component: Sidebar,
//     children: childrenSidebar,
//   },
// ];

// export default routes;

const itens: Array<RouteRecordRaw> = [
  ...Employee,
  ...Profile,
  ...Room,
  ...Unit,
];

export default itens;
