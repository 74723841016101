import {
  PersonState,
  Person,
} from "@/models/interfaces/shared/person.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PersonState> = {
  setPersons(state, payload: Person[]) {
    state.persons = payload;
  },

  setPerson(state, payload: Person) {
    state.person = payload;
  },
};
