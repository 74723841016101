import useLocalStorage from "@/composables/localStorage";
import { API_URL } from "@/constants/env";
import { City } from "@/models/interfaces/shared/city.interface";
import axios from "axios";

const storage = useLocalStorage("city");

const getAll = async (): Promise<City[]> => storage.getAll("/cidade/todos");

const get = async (id: string): Promise<City> =>
  storage.get(id, "/cidade/todos");

/* const getByState = async (id: string): Promise<City> =>
  (await axios.get(`${API_URL}/cidade/todos?uf=${id}`)).data; */

const getByState = async (id: string): Promise<City[]> =>
  storage.getBy("UfId", id, `/cidade/getall`);

export const CityService = {
  getAll,
  get,
  getByState,
};
