import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductType,
  ProductTypeState,
} from "@/models/interfaces/shared/producttype.interface";
import { ProductTypeService as productType } from "@/services/shared/producttype.services";
import { constants } from "@/constants/shared/producttype";

export const actions: ActionTree<ProductTypeState, RootState> = {
  getAll({ commit }): void {
    productType.getAll().then((res: ProductType[]) => {
      commit(constants.SET_PRODUCTTYPES, res);
    });
  },
  get({ commit }, id): void {
    productType.get(id).then((res: ProductType) => {
      commit(constants.SET_PRODUCTTYPE, res);
    });
  },
};
