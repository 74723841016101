import { API_URL } from "@/constants/env";
import { Inventory } from "@/models/interfaces/material/inventory.interface";

import axios from "axios";

export const areThereInvetory = async (
  id: string,
  serial: string,
  unit: number,
  amount: number
): Promise<Inventory[]> => {
  /* const url = `${API_URL}/produtoconf/temestoqueporpedido?pedido=${id}`; */
  const url = `${API_URL}/produtoconf/temEstoquePorProdutoUnidade?produto=${id}&serie=${serial}&unidade=${unit}&quantidade=${amount}`;
  const list = (await axios.get(url)).data;

  return list;
};
