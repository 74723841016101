import { ref } from "vue";

interface Filter {
  field: string;
  model: string;
}

export default function processFilters(lastFilters: any[]): {
  active: string;
  recordtype: string;
  moreFilter: string;
} {
  let active = "Ativo";
  let recordtype = "";
  let moreFilter = "";

  interface iFilterConditions {
    [key: string]: (model: any, field: any) => void;
    Ativo: (model: any, field: any) => void;
    tipoRegistro: (model: any, field: any) => void;
    default: (model: any, field: any) => void;
  }

  const filterConditions: iFilterConditions = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Ativo: (model: any, field: any) => (active = `${model}`),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tipoRegistro: (model: any, field: any) => (recordtype = `${model}`),
    default: (model: any, field: any) => (moreFilter += `,${field}=${model}`),
  };

  for (let i = 0; i < lastFilters.length; i++) {
    const line = lastFilters[i];

    for (let j = 0; j < line.length; j++) {
      const filter: Filter = line[j];

      const filterCondition =
        filter?.field == "Ativo" || filter?.field == "tipoRegistro"
          ? filter.field
          : "default";

      const condition = filterConditions[filterCondition];
      const modelRef = ref(filter.model);
      condition(modelRef.value, filter.field);
    }
  }

  return { active, recordtype, moreFilter: moreFilter ? `${moreFilter}` : "" };
}
