import { RouteRecordRaw } from "vue-router";
import RequestingPhysician from "@/views/config/billing/requestingphysician/Index.vue";
import RequestingPhysicians from "@/views/config/billing/requestingphysician/List.vue";
import BillingMenu from "@/components/menu/BillingMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/billing/requesting-physician/:id",
  name: "RequestingPhysician",
  component: {
    default: RequestingPhysician,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/billing/requesting-physician/list",
  name: "RequestingPhysicians",
  components: {
    default: RequestingPhysicians,
    sidebar: BillingMenu,
  },
  beforeEnter: Auth,
});

export default itens;
