import { RouteRecordRaw } from "vue-router";
import Inventory from "@/views/material/inventory/Index.vue";
import Inventories from "@/views/material/inventory/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/inventory/:id",
  name: "Inventory",
  component: Inventory,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/inventory/list",
  name: "Inventories",
  component: Inventories,
  beforeEnter: Auth,
});

export default itens;
