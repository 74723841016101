import { State, StateState } from "@/models/interfaces/shared/state.interface";

const stateuf: State = {
  id: "",
  name: "",
  acronyml: "",
  ibgecode: "",
  // cities: undefined,
};

const states: Array<State> = [];

export const state: StateState = {
  state: stateuf,
  states,
};
