import {
  Profession,
  ProfessionState,
} from "@/models/interfaces/shared/profession.interface";

const profession: Profession = {
  id: "",
  description: "",
};

const professions: Array<Profession> = [];

export const state: ProfessionState = {
  profession,
  professions,
};
