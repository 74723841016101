import { RouteRecordRaw } from "vue-router";
import Employee from "@/views/config/administration/employee/Index.vue";
import Employees from "@/views/config/administration/employee/List.vue";
import AdministrationMenu from "@/components/menu/AdministrationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/administration/employee/:id",
  name: "Employee",
  components: {
    default: Employee,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/administration/employee/list",
  name: "Employees",
  components: {
    default: Employees,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
