import {
  Voucher,
  VoucherState,
} from "@/models/interfaces/commercial/voucher.interface";

const voucher: Voucher = {
  id: "",
  discountinreais: 0,
  note: "",
  codevoucher: "",
  storeId: 0,
  customerId: 0,
  requestuserId: 0,
  responseuserId: 0,
  voucheritem: [],
};

const vouchers: Array<Voucher> = [];

export const state: VoucherState = {
  voucher,
  vouchers,
};
