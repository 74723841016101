import { API_URL } from "@/constants/env";

import axios from "axios";
import { Dashboard } from "@/models/interfaces/dashboard/dashboard.interface";
import store from "@/store";

//multi-cliente =  modelo "Multi-inquilino" ou "Multitenancy" - tenant = inquilino
const get = async (): Promise<Dashboard> => {
  const credential = { ...store.state.login.credential };

  const entity = (
    await axios.get(
      `${API_URL}/painel/get?cliente=${credential.tenantId}&profissional=${credential.userId}`
    )
  ).data;

  return entity;
};

export const DashboardService = {
  get,
};
