import { API_URL } from "@/constants/env";
import { Fiscal } from "@/models/interfaces/commercial/fiscal.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const browsing = await { ...store.state.login.browsing };
  browsing.lastFilters = lastFilters;
  browsing.lastSearchText = searchText;
  browsing.lastPage = page;
  store.state.login.browsing = browsing;

  /* let active = "Ativo";

  for (let i = 0; i < lastFilters?.length; i++) {
    const line = lastFilters[i];

    for (let j = 0; j < lastFilters?.length; j++) {
      const field = line[j];

      if (field.field == "Ativo") {
        active = field.model;
      }
    }
  } */

  return (
    await axios.get(
      `${API_URL}/api/configuracaonfe?cliente=${credential.tenantId}`
    )
  ).data;
};

const get = async (id: string): Promise<Fiscal> => {
  if (id == "new") {
    const entity: Fiscal = {
      id: "",
      description: "",
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/commercial/fiscal/${id}`)).data;
};

const insert = async (entity: Fiscal): Promise<Fiscal[]> => {
  return (
    await axios.post(`${API_URL}/config/commercial/fiscal`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<Fiscal[]> =>
  (await axios.delete(`${API_URL}/config/commercial/fiscal/${id}`)).data;

const update = async (id: string, entity: Fiscal): Promise<Fiscal[]> => {
  return (
    await axios.put(
      `${API_URL}/config/commercial/fiscal/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Fiscal) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const FiscalService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
