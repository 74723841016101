import { API_URL } from "@/constants/env";
import { TaxScenario } from "@/models/interfaces/commercial/taxscenario.interface";
import store from "@/store";
import axios from "axios";

export const get = async (id: string): Promise<TaxScenario> => {
  const credential = { ...store.state.login.credential };

  if (id == "new") {
    const entity: TaxScenario = {
      Id: 0,
      IdCliente: credential.tenantId,
      Usuario: credential.userId,
      Nome: "",
      Natureza: 1,
      Apagado: false,
      IdporClienteNotePro: 0,
      QtdePaginasGrid: 0,
      IdCriptografado: "",
      Erro: "",
      Ativo: false,
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/cenarioimpostos?id=${id}`)).data;

  return entity;
};
