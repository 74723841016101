import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Dashboard,
  DashboardState,
} from "@/models/interfaces/dashboard/dashboard.interface";
import { DashboardService as dashboard } from "@/services/dashboard/dashboard.services";
import { constants } from "@/constants/dashboard";

export const actions: ActionTree<DashboardState, RootState> = {
  get({ commit }): void {
    dashboard.get().then((res: Dashboard) => {
      commit(constants.SET_DASHBOARD, res);
    });
  },
};
