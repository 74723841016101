import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  WorkOrder,
  WorkOrderInternalState,
} from "@/models/interfaces/commercial/workorder.interface";
import { WorkorderService as service } from "@/services/commercial/workorder/internal";
import { constants } from "@/constants/commercial/workorderinternal";

export const actions: ActionTree<WorkOrderInternalState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: WorkOrder[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_WORKORDERSINTERNAL, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: WorkOrder) => {
      commit(constants.SET_WORKORDERINTERNAL, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: WorkOrder[] = await service.insert(state.workorderInternal);
    commit(constants.INSERT_WORKORDERINTERNAL, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*  const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: WorkOrder[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_WORKORDERSINTERNAL, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    /* console.log("action", state); */
    const res: WorkOrder[] = await service.update(id, state.workorderInternal);
    commit(constants.UPDATE_WORKORDERINTERNAL, res);
  },
};
