import { API_INVOICE_URL, API_URL, X_API_KEY } from "@/constants/env";
import axios from "axios";

import { useDate } from "@/composables/date";
/* import { prepareData } from "../invoiceproduct/preparedata.service"; */
import store from "@/store";
import { prepareData } from "../invoiceconsumer/preparedata.service";
import { emit } from "../invoiceconsumer/emit.service";

export const newInvoice = async (entity: any) => {
  if (await hasInvoicetoSale(entity)) return;

  entity.ChaveAcesso = "";
  entity.CfopId = 0;
  entity.DataEmissaoFormatada = useDate("pt-BR").today().format;
  entity.NumeroNf = 0;
  entity.StatusNfe = 1;
  entity.TipoCliente = 1;
  entity.TipoNf = 3;
  entity.Natureza = "VENDA";
  entity.Saida = 1;
  entity.FinalidadeOperacao = 1;

  const entitySave = await prepareData(entity, true);

  const invoice = (await axios.post(`${API_URL}/nfe/Salvar`, entitySave)).data;

  invoice.VendaId = entitySave.VendaId;

  entity.ChaveAcesso = await emit(invoice);

  const credential = { ...store.state.login.credential };

  await checkInvoiceStatus(credential, invoice, entity.ChaveAcesso);
};

const hasInvoicetoSale = async (entity: any) => {
  const credential = { ...store.state.login.credential };

  if (entity.Id) {
    const invoiceSale: any = (
      await axios.get(
        `${API_URL}/nfe/notafiscalporvenda?cliente=${credential.tenantId}&id=${entity.VendaId}`
      )
    ).data;

    if (invoiceSale.length) {
      return true;
    }
  }
  return false;
};

async function checkInvoiceStatus(
  credential: any,
  invoice: any,
  chave: string
) {
  const delayInMilliseconds = 3000; //3 second

  const timeLimit = 60000; // 60 second

  let waitingTime = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const invoiceSale: any = (
      await axios.get(
        `${API_URL}/nfe/notafiscalporvenda?cliente=${credential.tenantId}&id=${invoice.VendaId}`
      )
    ).data;

    if (invoiceSale[0].StatusNfe == 3) {
      download("pdf", chave);

      break;
    }

    waitingTime += delayInMilliseconds;

    if (waitingTime > timeLimit) {
      waitInvoice(chave, credential, invoice);
      break;
    }

    await delay(delayInMilliseconds);
  }
}

const waitInvoice = async (chave: any, credential: any, invoice: any) => {
  store.state.loading.loading.value = false;

  const delayInMilliseconds = 120000;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const invoiceSale: any = (
      await axios.get(
        `${API_URL}/nfe/notafiscalporvenda?cliente=${credential.tenantId}&id=${invoice.VendaId}`
      )
    ).data;

    if (invoiceSale[0].StatusNfe == 3) {
      download("pdf", chave);

      break;
    }

    await delay(delayInMilliseconds);
  }
};

async function delay(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const download = async (what: string, ChaveAcesso: string) => {
  try {
    const response = await axios.get(
      `${API_INVOICE_URL}/nfce/${ChaveAcesso}/${what}`,
      {
        headers: {
          "X-API-KEY": X_API_KEY,
        },
        responseType: "arraybuffer", // Para lidar com dados binários
      }
    );

    // Converter a resposta para um blob
    const blob = new Blob([response.data], { type: `application/${what}` });

    // Crie um link para o download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${ChaveAcesso}.${what}`;

    // Clique no link para iniciar o download
    link.click();

    // Libere o objeto URL
    URL.revokeObjectURL(link.href);

    console.log("PDF criado e exibido no navegador");
  } catch (ex) {
    console.log(ex);
  }
};
