import {
  TimeTable,
  TimeTableState,
} from "@/models/interfaces/schedule/timetable.interface";

const timeTable: TimeTable = {
  unitId: 0,
  employeeId: 0,
  date: "",
  time: "",
  averageTime: 0,
};

export const state: TimeTableState = {
  timeTable,
};
