import { RouteRecordRaw } from "vue-router";
import Timetable from "@/views/config/schedule/timetable/Index.vue";
import Timetables from "@/views/config/schedule/timetable/List.vue";
import ScheduleMenu from "@/components/menu/ScheduleMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/schedule/timetable/:id",
  name: "Timetable",
  component: {
    default: Timetable,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/schedule/timetable/list",
  name: "Timetables",
  components: {
    default: Timetables,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});

export default itens;
