import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  BusinessGroup,
  BusinessGroupState,
} from "@/models/interfaces/administration/businessgroup.interface";

export const getters: GetterTree<BusinessGroupState, RootState> = {
  getBusinessGroup(state): BusinessGroup {
    return state.businessGroup;
  },
};
