import store from "@/store";

export const prepareData = (entity: any) => {
  const credential = { ...store.state.login.credential };

  const Valor = entity.Valor;
  const Usuario = credential.userId;
  const UsuarioId = credential.userId;
  const UsuarioAlteracaoId = entity.UsuarioAlteracaoId;
  const Observacao = entity.Observacao;
  const UnidadeId = entity.Id ? entity.UnidadeId : credential.storeId;
  const ClienteId = entity.ClienteId;
  const IdCliente = credential.tenantId;
  const Id = entity.Id;
  const DataFormatada = entity.DataFormatada;
  const Cupom = entity.Cupom;
  const DataAlteracaoFormatada = entity.DataAlteracaoFormatada;

  const Itens: {
    Quantidade: any;
    Valor: any;
    ValorDesconto: any;
    ValorTotal: any;
    ProdutoId: any;
    TributacaoId: any;
  }[] = [];

  if (entity.voucheritem) {
    for (let i = 0; i < entity.voucheritem.length; i++) {
      const item = entity.voucheritem[i];
      const desconto = item.ValorDesconto ? item.ValorDesconto : 0;

      Itens.push({
        Quantidade: item.Quantidade,
        Valor: item.Valor,
        ValorDesconto: desconto,
        ValorTotal: item.Quantidade * (item.Valor - desconto),
        ProdutoId: item.id ? item.id : item.ProdutoId,
        TributacaoId: item.TributacaoId ? item.TributacaoId : null, // Não estou mais usando, busca o a tributacao no cenario
      });
    }
  }

  const entitySave = {
    Valor,
    Usuario,
    UsuarioId,
    UsuarioAlteracaoId,
    Observacao,
    UnidadeId,
    ClienteId,
    IdCliente,
    Id,
    DataFormatada,
    Cupom,
    DataAlteracaoFormatada,
    Itens,
  };

  return entitySave;
};
