import { RouteRecordRaw } from "vue-router";
import OtherGuides from "../../views/billing/otherguides/Index.vue";
import OtherGuidesList from "../../views/billing/otherguides/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/billing/other-guides/:id",
  name: "OtherGuides",
  component: OtherGuides,
  beforeEnter: Auth,
});
itens.push({
  path: "/billing/other-guides/list",
  name: "OtherGuidesList",
  component: OtherGuidesList,
  beforeEnter: Auth,
});

export default itens;
