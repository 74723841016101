import { ActionTree } from "vuex";
import { RootState } from "../type";
import { LoginService } from "@/services/login/login.services";
import { ChooseStoreService } from "@/services/login/choosestore.services";
import { constants } from "@/constants/login";
import { LoginState, Login } from "@/models/interfaces/login/login.interface";

export const actions: ActionTree<LoginState, RootState> = {
  login({ commit }): void {
    LoginService.execute().then((res: Login) => {
      commit(constants.LOAD_LOGIN, res);
    });
  },
  chooseStore({ commit }, id): void {
    ChooseStoreService.execute(id).then((res: Login) => {
      commit(constants.LOAD_LOGIN, res);
    });
  },
};
