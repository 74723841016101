import {
  TreatmentState,
  Treatment,
} from "@/models/interfaces/commercial/treatment.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TreatmentState> = {
  setTreatments(state, payload: Treatment[]) {
    state.treatments = payload;
  },

  setTreatment(state, payload: Treatment) {
    state.treatment = payload;
  },

  insertTreatment(state, payload: Treatment) {
    state.treatment = payload;
  },

  removeTreatment(state, payload: Treatment) {
    state.treatment = payload;
  },

  updateTreatment(state, payload: Treatment) {
    state.treatment = payload;
  },
};
