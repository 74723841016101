import {
  UnitState,
  Unit,
} from "@/models/interfaces/administration/unit.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<UnitState> = {
  setUnits(state, payload: Unit[]) {
    state.units = payload;
  },

  setUnit(state, payload: Unit) {
    state.unit = payload;
  },

  insertUnit(state, payload: Unit) {
    state.unit = payload;
  },

  removeUnit(state, payload: Unit) {
    state.unit = payload;
  },

  updateUnit(state, payload: Unit) {
    state.unit = payload;
  },
};
