import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Auth from "@/helpers/security/auth";

import Security from "./security/outdash";
import SecurityIn from "./security/indash";

/* import FullScreen from "../views/_layout/FullScreen.vue"; */
import Dash from "../views/Dash.vue";
import Dashboard from "../views/Dashboard.vue";

import Schedule from "./schedule";
import Customer from "./customer";
import Billing from "./billing";
import Commercial from "./commercial";
import Financial from "./financial";
import Material from "./material";
import Others from "./others";
import Config from "./config";

const dashboard = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: Auth,
  },
];

const childrenDash = [
  ...dashboard,
  ...Schedule,
  ...Customer,
  ...Billing,
  ...Commercial,
  ...Financial,
  ...Material,
  ...Others,
  ...Config,
  ...SecurityIn,
];

const routes: Array<RouteRecordRaw> = [
  ...Security,
  {
    path: "/",
    name: "Dash",
    component: Dash,
    children: childrenDash,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "ativo",
});

export default router;
