export const constants = {
  SET_INVENTORIES: "setInventories",
  SET_INVENTORY: "setInventory",
  INSERT_INVENTORY: "insertInventory",
  REMOVE_INVENTORY: "removeInventory",
  UPDATE_INVENTORY: "updateInventory",
};

export const ownerinventory = {
  PURCHASE: 1,
  SALE: 2,
  TEST: 3,
  LOAN: 4,
  TRANSFER: 5,
  CONSIGNED: 6,
  ADJUSTMENT: 7,
};
