import { RouteRecordRaw } from "vue-router";
import Voucher from "../../views/commercial/voucher/Index.vue";
import Vouchers from "../../views/commercial/voucher/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/voucher/:id",
  name: "Voucher",
  component: Voucher,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/voucher/list",
  name: "Vouchers",
  component: Vouchers,
  beforeEnter: Auth,
});

export default itens;
