export default (data: any) => {
  const { product, valor, cenarioIcms, taxation, recipient } = data;
  const aliquota = cenarioIcms.aliquota; // parseFloat(cenarioIcms.aliquota.replace(",", "."));

  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const ufRecipient = recipient.endereco.estado;

  const cenariosIcmsSt = ncm.CenarioIcmsSts.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioIcmsSt = cenariosIcmsSt[0];

  const cenariosInterestadual = ncm.CenarioIcmsInterestaduais.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioInterestadual = cenariosInterestadual[0];

  const cst = cenarioIcms.Cst.toString();

  const aliquotaSt = cenarioIcmsSt.Aliquota;

  const modalidadeDeterminacao = cenarioIcmsSt.ModalidadeBaseCalculo;

  const percentualReducao = cenarioIcmsSt.ReducaoBaseCalculo;

  const mva = 1 + cenarioIcmsSt.MargemValorAdicionado / 100;

  const valorBc = +(valor * mva).toFixed(2);

  const aliquotaFcpSt = cenarioInterestadual.FundoCombatePobreza;

  const aliquotaFcp = 0;

  const bcFcp = valorBc;

  return {
    origem: product.OrigemCodigo.toString(),
    cst,
    aliquota: aliquota,
    baseCalculo: {
      modalidadeDeterminacao: 3, //Valor da operação.
      valor,
    },
    substituicaoTributaria: {
      aliquota: aliquotaSt,
      baseCalculo: {
        modalidadeDeterminacao,
        valor: valorBc,
        percentualReducao,
      },
      margemValorAdicionado: {
        percentual: cenarioIcmsSt.MargemValorAdicionado,
      },
      fundoCombatePobreza: {
        aliquota: aliquotaFcpSt,
        baseCalculo: {
          valor: bcFcp,
        },
      },
      // ufDevido: ufRecipient,
    },
    /*  fundoCombatePobreza: {
      aliquota: aliquotaFcp,
      baseCalculo: {
        valor: item.Valor,
      },
    }, */
  };
};
