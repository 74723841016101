import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Employee,
  EmployeeState,
} from "@/models/interfaces/administration/employee.interface";
import { EmployeeService as service } from "@/services/administration/employee.services";
import { constants } from "@/constants/administration/employee";
import { state } from "./state";

export const actions: ActionTree<EmployeeState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Employee[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_EMPLOYEES, res);
  },
  async getAllSchedule({ commit }, unitId): Promise<void> {
    const res: Employee[] = await service.getAllSchedule(unitId);

    commit(constants.SET_EMPLOYEES, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Employee) => {
      commit(constants.SET_EMPLOYEE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Employee[] = await service.insert(state.employee);
    /*    commit(constants.INSERT_EMPLOYEE, res); */
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Employee[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_EMPLOYEES, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Employee[] = await service.update(id, state.employee);
    /*   commit(constants.UPDATE_EMPLOYEE, res); */
  },
};
