import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  AccountPayable,
  AccountPayableState,
} from "@/models/interfaces/financial/accountpayable.interface";

export const getters: GetterTree<AccountPayableState, RootState> = {
  getAccountPayable(state): AccountPayable {
    return state.accountPayable;
  },
};
