import { RouteRecordRaw } from "vue-router";
import AccountStatement from "../../views/financial/accountstatement/Index.vue";
import AccountStatements from "../../views/financial/accountstatement/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/account-statement/:id",
  name: "AccountStatement",
  component: AccountStatement,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/account-statement/list",
  name: "AccountStatements",
  component: AccountStatements,
  beforeEnter: Auth,
});

export default itens;
