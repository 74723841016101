import { API_URL } from "@/constants/env";
import { MedicalReport } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";
import axios from "axios";

export const getMedicalReport = async (
  id: string,
  customer: string
): Promise<MedicalReport> => {
  const credential = { ...store.state.login.credential };

  const url = `${API_URL}/prontuario/getrelatorio?id=${id}&paciente=${customer}&profissional= + ${credential.userId}`;

  const entity: MedicalReport = {
    description: "",
    text: "",
  };

  entity.text = (await axios.get(url)).data;

  return entity;
};
