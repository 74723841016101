import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Service } from "@/models/interfaces/commercial/service.interface";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "procedimento").fetchData();

const getAllByTenant = async (id: string) =>
  (await axios.get(`${API_URL}/procedimento/todos?cliente=${id}`)).data;

const getAllByTenantUnitEmployee = async (
  tenantId: string,
  unitId: string,
  employeeId: string
) =>
  (
    await axios.get(
      `${API_URL}/procedimento/todosUnidadeProfissional?cliente=${tenantId}&unidade=${unitId}&profissional=${employeeId}`
    )
  ).data;

const get = async (id: string): Promise<Service> => {
  if (id == "new") {
    const entity: Service = {
      id: "",
      description: "",
      code: "",
      price: 0,
      taxationId: undefined,
      cnaeId: undefined,
      Id: "",
      TempoMedio: 0,
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/commercial/service/${id}`)).data;
};

const insert = async (entity: Service): Promise<Service[]> => {
  return (
    await axios.post(
      `${API_URL}/config/commercial/service`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Service[]> =>
  (await axios.delete(`${API_URL}/config/commercial/service/${id}`)).data;

const update = async (id: string, entity: Service): Promise<Service[]> => {
  return (
    await axios.put(
      `${API_URL}/config/commercial/service/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Service) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  return updateEntity;
};

export const ServiceService = {
  getAll,
  getAllByTenant,
  getAllByTenantUnitEmployee,
  get,
  insert,
  remove,
  update,
};
