import {
  Taxation,
  TaxationState,
} from "@/models/interfaces/commercial/taxation.interface";

const taxation: Taxation = {
  id: "",
  Nome: "",
  Cenq: "",
  CfopId: 0,
  CstIcmsId: 0,
  CsosnId: 0,
  CstIpiId: 0,
  CstCofinsId: 0,
  CstPisId: 0,
  ModBcIcmsId: 0,
  ModBcIcmsStId: 0,
  UfId: 0,
  PIcms: 0,
  PReducaoIcms: 0,
  PIcmsSt: 0,
  PReducaoIcmsSt: 0,
  PIpi: 0,
  ExtIpi: 0,
  PPis: 0,
  PCofins: 0,
  Padrao: 0,
  IdporClienteNotePro: 0,
  IdCliente: 0,
  QtdePaginasGrid: 0,
  IdCriptografado: "",
  Erro: "",
  Id: 0,
  Apagado: false,
  Ativo: false,
  Usuario: 0,
};

const taxations: Array<Taxation> = [];

export const state: TaxationState = {
  taxation,
  taxations,
};
