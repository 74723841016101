import { RouteRecordRaw } from "vue-router";
import Profile from "@/views/config/administration/profile/Index.vue";
import Profiles from "@/views/config/administration/profile/List.vue";
import AdministrationMenu from "@/components/menu/AdministrationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/administration/profile/:id",
  name: "Profile",
  components: {
    default: Profile,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/administration/profile/list",
  name: "Profiles",
  components: {
    default: Profiles,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
