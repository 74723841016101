import store from "@/store";
import { getItems } from "./item";
import { getRecipient } from "./receipient.service";
import { getPayments } from "./payment.service";
import { getBankslip } from "./bankslip.service";
import { SaleService } from "../../sale";
import { getTechnicalResponsible } from "./technicalresponsible.service";

export const prepareDataInvoice = async (entity: any) => {
  const credential = { ...store.state.login.credential };
  //Seu identificador único para a NFe
  const idIntegracao = entity.Id.toString();
  //Finalidade da Operação
  /*  1 - NF-e Normal
      2 - NF-e Complementar
      3 - NF-e de AJuste
      4 - NF-e de Devolução de Mercadoria */
  const finalidade = entity.FinalidadeOperacao.toString();
  /*   Na NF-e existe o campo natOp - Descrição da Natureza da Operação da NF-e. 
  Esse campo deve ser preenchido com a natureza da operação de que decorrer a 
  saída ou a entrada, tais como: venda, compra, transferência, devolução, importação, consignação, remessa 
  (para fins de demonstração, de industrialização ou outra), conforme previsto na alínea 'i', 
  inciso I, art. 19 do CONVÊNIO S/Nº, de 15 de dezembro de 1970. 
  https://atendimento.tecnospeed.com.br/hc/pt-br/articles/360015490694-Regra-Tributaria-natOp-Descri%C3%A7%C3%A3o-da-Natureza-da-Opera%C3%A7%C3%A3o-da-NF-e */

  const natureza = entity.CenarioImpostosNome; //Natureza; //"VENDA";
  const presencial = entity.Presencial; // true;
  const consumidorFinal = entity.ConsumidorFinal; // true;

  const saida = entity.Saida; // true;

  const informacoesComplementares = entity.Observacao;
  // const dataEmissao = entity.DataEmissaoFormatada; // "2024-06-16";

  const notaReferenciada =
    finalidade === "4"
      ? {
          nfe: [{ chave: entity.NotaReferenciada.replace(/\s+/g, "") }],
        }
      : null;

  const emitente = { cpfCnpj: credential.storeCnpj.replace(/\D/g, "") };

  const destinatario = await getRecipient(entity.ClienteIdCriptografado);

  const it = entity.nfeitem ? entity.nfeitem : entity.Itens;
  const itemsTreated = await getItems(
    it,
    entity.CenarioImpostosId,
    destinatario,
    consumidorFinal
  );

  const itens = itemsTreated.itens;

  /*   const responsavelTecnico = await getTechnicalResponsible(); */

  const invoice: { [k: string]: any } = {
    idIntegracao,
    finalidade,
    natureza,
    saida,
    /* dataEmissao, */
    presencial,
    consumidorFinal,
    ...(notaReferenciada && { notaReferenciada }),
    emitente,
    destinatario,
    itens,
    informacoesComplementares,
    /*   responsavelTecnico, */
  };
  const sale: any = await SaleService.get(entity.VendaIdCriptografado);
  if (sale.Id) {
    invoice.pagamentos = await getPayments(sale);
    invoice.cobranca = await getBankslip(sale);
  } else {
    //finalidade === "4" ? 10 -> 90 - Sem pagamento
    const saleFake = {
      accountReceivable: [
        {
          MeioPagamentoTipo: finalidade === "4" ? 10 : entity.MeioPagamentoTipo,
          Valor: itemsTreated.total,
        },
      ],
    };

    invoice.pagamentos = await getPayments(saleFake);
  }

  const invoices = [];

  invoices.push(invoice);

  return invoices;
};
