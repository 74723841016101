import {
  PersonalTreatmentState,
  PersonalTreatment,
} from "@/models/interfaces/shared/personaltreatment.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PersonalTreatmentState> = {
  setPersonalTreatments(state, payload: PersonalTreatment[]) {
    state.personalTreatments = payload;
  },

  setPersonalTreatment(state, payload: PersonalTreatment) {
    state.personalTreatment = payload;
  },
};
