import {
  DailySchedule,
  Markoff,
  Schedule,
  ScheduleState,
  customer,
  scheduled,
  service,
} from "@/models/interfaces/schedule/schedule.interface";

const schedule: Schedule = {
  /* id: "",
  status: 0,
  note: "",
  modality: 0,
  service: undefined,
  customer: undefined, */
  date: "",
  i: 0,
  simultaneouservice: 0,
  scheduleds: [],
  fit: undefined,
  time: "",
};

const dailySchedule: DailySchedule = {
  date: "",
  simultaneouservice: 0,
  schedules: undefined,
};

const schedules: Array<Schedule> = [];

const dailySchedules: Array<DailySchedule> = [];

const markoff: Markoff = {
  reason: "",
  modo: "",
  status: "",
};
const service: service = {
  id: "",
  description: "",
  background: "",
  abbreviation: "",
  averagetime: "",
};

const customer: customer = {
  id: "",
  idbycutomer: "",
  name: "",
  description: "",
  idCriptografado: "",
};

const reschedule: scheduled | undefined = undefined; /*  {
  id: "",
  status: 0,
  note: "",
  modality: 0,
  service,
  customer,
  type: 0,
  amount: "",
  daysofweek: "",
  fitting: false,
}; */

export const state: ScheduleState = {
  schedule,
  schedules,
  dailySchedule,
  dailySchedules,
  markoff,
  reschedule,
};
