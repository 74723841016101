export const constants = {
  SET_DAILYSCHEDULES: "setDailySchedules",
  SET_DAILYSCHEDULE: "setDailySchedule",
  SET_SCHEDULES: "setSchedules",
  SET_SCHEDULE: "setSchedule",
  SET_MEDICALCAREHISTORY: "setMedicalCareHistory",
  INSERT_SCHEDULE: "insertSchedule",
  REMOVE_SCHEDULE: "removeSchedule",
  UPDATE_SCHEDULE: "updateSchedule",
};
