import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Inventory,
  InventoryState,
} from "@/models/interfaces/material/inventory.interface";

export const getters: GetterTree<InventoryState, RootState> = {
  getInventory(state): Inventory {
    return state.inventory;
  },
};
