import { RouteRecordRaw } from "vue-router";
import FinancialAccount from "@/views/config/financial/financialaccount/Index.vue";
import FinancialAccounts from "@/views/config/financial/financialaccount/List.vue";
import FinancialMenu from "@/components/menu/FinancialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/financial/financialaccount/:id",
  name: "FinancialAccount",
  components: {
    default: FinancialAccount,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/financial/financialaccount/list",
  name: "FinancialAccounts",
  components: {
    default: FinancialAccounts,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
