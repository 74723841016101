import { API_URL } from "@/constants/env";
import { Supplier } from "@/models/interfaces/material/supplier.interface";
import axios from "axios";
import { treatedEntity } from "./treatedentity.service";
import store from "@/store";

export const getByCnpj = async (cnpj: string): Promise<Supplier> => {
  const credential = { ...store.state.login.credential };

  const entity = (
    await axios.get(
      `${API_URL}/contato/getbydocument?cliente=${credential.tenantId}&doc=${cnpj}`
    )
  ).data;

  const entitySave = entity ? treatedEntity(entity) : entity;

  return entitySave;
};
