import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PatientRecord,
  PatientRecordState,
} from "@/models/interfaces/medicalconsultation/patientrecord.interface";
import { PatientRecordService as patientRecord } from "@/services/medicalconsultation/patientrecord.services";
import { constants } from "@/constants/medicalconsultation/patientrecord";

export const actions: ActionTree<PatientRecordState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: PatientRecord[] = await patientRecord.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_PATIENTRECORDS, res);
  },
  get({ commit }, id): void {
    patientRecord.get(id).then((res: PatientRecord) => {
      commit(constants.SET_PATIENTRECORD, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: PatientRecord[] = await patientRecord.insert(
      state.patientrecord
    );
    commit(constants.INSERT_PATIENTRECORD, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await patientRecord.remove(ids);
    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: PatientRecord[] = await patientRecord.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_PATIENTRECORDS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: PatientRecord[] = await patientRecord.update(
      id,
      state.patientrecord
    );
    commit(constants.UPDATE_PATIENTRECORD, res);
  },
};
