import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ContactType,
  ContactTypeState,
} from "@/models/interfaces/shared/contacttype.interface";

export const getters: GetterTree<ContactTypeState, RootState> = {
  getContactType(state): ContactType {
    return state.contactType;
  },
};
