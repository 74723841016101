import { API_URL } from "@/constants/env";
import { Customer } from "@/models/interfaces/customer/customer.interface";
import store from "@/store";
import axios from "axios";

export const changeStatusActive = async (id: string): Promise<Customer[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.get(
      `${API_URL}/paciente/mundastatusai?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};
