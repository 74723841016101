import { removeAccents } from "@/helpers/string";
import { CustomerService } from "@/services/customer/index";

export const getRecipient = async (customerId: string) => {
  const customer: any = await CustomerService.getById(customerId);

  if (customer.DocumentoNf) {
    return {
      cpfCnpj: customer.DocumentoNf?.replace(/\D/g, ""),
      razaoSocial: removeAccents(customer.NomeNf),
      email: customer.Email ? customer.Email : "",
      endereco: {
        tipoLogradouro: "Rua",
        logradouro: removeAccents(customer.addressnf.street),
        numero: removeAccents(customer.addressnf.number),
        bairro: removeAccents(customer.addressnf.neighborhood),
        codigoCidade: customer.addressnf.ibgecode,
        descricaoCidade: removeAccents(customer.addressnf.cityName),
        estado: removeAccents(customer.addressnf.stateAcronym),
        cep: formattedCep(customer.addressnf.zipcode),
      },
      inscricaoEstadual: customer.InscricaoEstadualNf
        ? customer.InscricaoEstadualNf
        : "", //não é obrigatório
      inscricaoMunicipal: customer.InscricaoMunicipalNf
        ? customer.InscricaoMunicipalNf
        : "", //não é obrigatório
    };
  } else {
    return {
      cpfCnpj: customer.Cpf?.replace(/\D/g, ""),
      razaoSocial: removeAccents(customer.Nome),
      email: customer.Email ? customer.Email : "",
      endereco: {
        tipoLogradouro: "Rua",
        logradouro: removeAccents(customer.address.street),
        numero: removeAccents(customer.address.number),
        bairro: removeAccents(customer.address.neighborhood),
        codigoCidade: customer.address.ibgecode,
        descricaoCidade: removeAccents(customer.address.cityName),
        estado: removeAccents(customer.address.stateAcronym),
        cep: formattedCep(customer.address.zipcode),
      },
      inscricaoEstadual: "", //não é obrigatório
      inscricaoMunicipal: "", //não é obrigatório
    };
  }
};

function formattedCep(cep: string) {
  if (cep) return cep.replace(/[.-]/g, "");
  return "";
}
