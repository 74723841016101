import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { Gift, GiftState } from "@/models/interfaces/commercial/gift.interface";
import { GiftService as service } from "@/services/commercial/gift";
import { constants } from "@/constants/commercial/gift";

export const actions: ActionTree<GiftState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Gift[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_GIFTS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Gift) => {
      commit(constants.SET_GIFT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    console.log("action");
    const res: Gift[] = await service.insert(state.gift);
    commit(constants.INSERT_GIFT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Gift[] = await service.update(id, state.gift);
    commit(constants.UPDATE_GIFT, res);
  },
};
