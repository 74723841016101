import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { FinancialCategory } from "@/models/interfaces/financial/financialCategory.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "categoriafinanceira").fetchData();

const getAllDad = async (): Promise<FinancialCategory[]> =>
  (await axios.get(`${API_URL}/config/financial/financialcategory/findalldad`))
    .data;

const getAllByType = async (type: number): Promise<FinancialCategory[]> => {
  const credential = { ...store.state.login.credential };

  return (
    await axios.get(
      `${API_URL}/categoriafinanceira/todosportipo?cliente=${credential.tenantId}&tipo=${type}`
    )
  ).data;
};

const get = async (id: string): Promise<FinancialCategory> => {
  if (id == "new") {
    const entity: FinancialCategory = {
      id: "0",
      description: "",
      categorytypeId: undefined,
      subcategories: [],
    };

    return entity;
  }

  const url = `${API_URL}/categoriafinanceira?id=${id}`;
  const response = await axios.get(url);
  const data = response.data;

  return data;
};

const insert = async (
  entity: FinancialCategory
): Promise<FinancialCategory[]> => {
  const list: FinancialCategory[] = (
    await axios.post(
      `${API_URL}/categoriafinanceira/salvar`,
      prepareData(entity)
    )
  ).data;

  return list;
};

const remove = async (id: string): Promise<FinancialCategory[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/categoriafinanceira/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: FinancialCategory
): Promise<FinancialCategory[]> => {
  return (
    await axios.post(
      `${API_URL}/categoriafinanceira/salvar`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: FinancialCategory) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  return { Id, IdCliente, usuario, ...updateEntity };
};

export const FinancialCategoryService = {
  getAll,
  getAllDad,
  getAllByType,
  get,
  insert,
  remove,
  update,
};
