import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => {
        return !!value?.id;
      }
    ),

  service: yup
    .string()
    .required(messages.compile({ id: "required", value: "Serviço" })),

  averagetime: yup
    .string()
    .required(messages.compile({ id: "required", value: "Tempo médio" })),

  obs: yup
    .string()
    .required(
      messages.compile({ id: "required", value: "Observação do atendimento" })
    ),
});
