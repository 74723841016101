import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Consigned,
  ConsignedState,
} from "@/models/interfaces/commercial/consigned.interface";
import { ConsignedService as consigned } from "@/services/commercial/consigned.services";
import { constants } from "@/constants/commercial/consigned";

export const actions: ActionTree<ConsignedState, RootState> = {
  getAll({ commit }): void {
    consigned.getAll().then((res: Consigned[]) => {
      commit(constants.SET_CONSIGNEDS, res);
    });
  },
  get({ commit }, id): void {
    consigned.get(id).then((res: Consigned) => {
      commit(constants.SET_CONSIGNED, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Consigned[] = await consigned.insert(state.consigned);
    commit(constants.INSERT_CONSIGNED, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await consigned.remove(ids);
    /*   const res: Consigned[] = await consigned.getAll();
    commit(constants.SET_CONSIGNEDS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Consigned[] = await consigned.update(id, state.consigned);
    commit(constants.UPDATE_CONSIGNED, res);
  },
};
