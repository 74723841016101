import { RouteRecordRaw } from "vue-router";
import CostCenter from "@/views/config/financial/costcenter/Index.vue";
import CostsCenter from "@/views/config/financial/costcenter/List.vue";
import FinancialMenu from "@/components/menu/FinancialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/financial/costcenter/:id",
  name: "CostCenter",
  components: {
    default: CostCenter,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/financial/costcenter/list",
  name: "CostsCenter",
  components: {
    default: CostsCenter,
    sidebar: FinancialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
