/* export const getPayments = async (sale: any) => {
  //entity pode ser sale ou salefake montado na emissao da nota fiscal direta
  /*  if (sale.accountReceivable) { * /
  const accounts = await sale.accountReceivable.reduce(
    async (acc: any[], account: any) => {
      const payments: any = {
        aVista: sale.accountReceivable.length == 1 ? true : false,
        meio: getMeio(account.MeioPagamentoTipo),
        valor: account.Valor,
      };

      if (
        account.MeioPagamentoTipo == 2 ||
        account.MeioPagamentoTipo == 3 ||
        account.MeioPagamentoTipo == 8
      ) {
        const cartao = {
          tipoIntegracao: 2,
        };
        payments.cartao = cartao;
      }

      acc.push(payments);
      return acc;
    },
    []
  );
  debugger;
  return accounts;
}; */

export const getPayments = async (sale: any) => {
  // entity pode ser sale ou salefake montado na emissao da nota fiscal direta
  if (!sale.accountReceivable) {
    return []; // Retorna um array vazio se não houver contas a receber
  }

  const accounts = [];

  for (const account of sale.accountReceivable) {
    const payments: any = {
      aVista: sale.accountReceivable.length === 1,
      meio: getMeio(account.MeioPagamentoTipo),
      valor: account.Valor,
    };

    if (
      account.MeioPagamentoTipo === 2 ||
      account.MeioPagamentoTipo === 3 ||
      account.MeioPagamentoTipo === 8
    ) {
      payments.cartao = {
        tipoIntegracao: 2,
      };
    }

    accounts.push(payments);
  }

  return accounts;
};

const getMeio = (tipo: number) => {
  switch (tipo) {
    case 1: //01 - Dinheiro
      return "01";

    case 2: //03 - Cartão de Crédito
      return "03";

    case 3: //04 - Cartão de Débito
      return "04";

    case 6: //15 - Boleto Bancário
      return "15";

    case 7: //18 - Transferência bancária, Carteira Digital
      return "18";

    case 8: //20 - Pagamento Instantâneo (PIX) - Estático
      return "17";

    case 9: //16 - Depósito Bancário
      return "16";

    case 10: //16 - Depósito Bancário
      return "90";

    default: //99 - Outros
      return "99";
  }
};

/* dinheiro =1
cc = 2
cartao debito = 34
boleto = 6
transferencia = 7
pix=8
deposito =9 */
