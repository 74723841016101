import {
  FinancialAccount,
  FinancialAccountState,
} from "@/models/interfaces/financial/financialAccount.interface";

const financialAccount: FinancialAccount = {
  id: "",
  description: "",
  bankId: undefined,
  storeId: 1,
  financialaccountMeansofpayments: [],
  bankdata: {
    id: undefined,
    branchcode: "",
    branchsortcode: "",
    accountnumber: "",
    accountsortcode: "",
    bankslip: {
      document: "",
      name: "",
      wallet: "",
      portfolioVariation: "",
      code: "",
      digit: "",
      note: "",
      address: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },
    },
  },
};

const financialAccounts: Array<FinancialAccount> = [];

export const state: FinancialAccountState = {
  financialAccount,
  financialAccounts,
};
