import {
  SpsadtGuide,
  SpsadtGuideState,
} from "@/models/interfaces/billing/spsadtguide.interface";

const spsadtGuide: SpsadtGuide = {
  id: "",
  description: "",
};

const spsadtGuides: Array<SpsadtGuide> = [];

export const state: SpsadtGuideState = {
  spsadtGuide,
  spsadtGuides,
};
