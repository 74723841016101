import { API_URL } from "@/constants/env";
import { Voucher } from "@/models/interfaces/commercial/voucher.interface";
import axios from "axios";

//resgatar
export const redeem = async (
  id: string,
  customer: number
): Promise<Voucher> => {
  if (id == "new") {
    const entity: Voucher = {
      id: "",
      discountinreais: 0,
      note: "",
      codevoucher: "",
      storeId: 0,
      customerId: 0,
      requestuserId: 0,
      responseuserId: 0,
      voucheritem: [],
    };
    return entity;
  }

  const url = `${API_URL}/cupomdesconto/resgatarcupom?cupom=${id}&paciente=${customer}`;

  const entity = (await axios.get(url)).data;

  if (!entity) return entity;

  entity.voucheritem = (
    await axios.get(`${API_URL}/cupomdesconto/getitens?voucher=${entity.Id}`)
  ).data;

  return entity;
};
