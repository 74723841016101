import { Supplier } from "@/models/interfaces/material/supplier.interface";
import { Address } from "@/models/interfaces/shared/address.interface";
import { Contact } from "@/models/interfaces/shared/contact.interface";

export const treatedEntity = (entity: any): Supplier => {
  const {
    Telefone,
    TelefoneAux,
    Email,
    Site,

    Cep,
    UfId,
    UfSigla,
    CidadeId,
    CidadeNome,
    CidadeIbge,
    Bairro,
    Logradouro,
    Numero,
    Complemento,

    ...updateEntity
  } = entity;

  const contacts: Contact[] = [];

  if (Telefone)
    contacts.push({
      description: Telefone,
      contacttypeId: 2,
      contacttypeDescription: "Telefone",
      id: "2",
    });
  if (TelefoneAux)
    contacts.push({
      description: TelefoneAux,
      contacttypeId: 1,
      contacttypeDescription: "Celular",
      id: "1",
    });

  if (Email)
    contacts.push({
      description: Email,
      contacttypeId: 5,
      contacttypeDescription: "Email",
      id: "5",
    });

  if (Site)
    contacts.push({
      description: Site,
      contacttypeId: 10,
      contacttypeDescription: "Site",
      id: "10",
    });

  const address: Address = {
    zipcode: Cep,
    state: UfId,
    city: CidadeId,
    neighborhood: Bairro,
    street: Logradouro,
    id: "1",
    ibgecode: CidadeIbge,
    stateId: UfId,
    stateAcronym: UfSigla,
    cityId: CidadeId,
    cityName: CidadeNome,
    number: Numero,
    complement: Complemento,
  };

  const treatedEntity = {
    ...updateEntity,
    contacts,
    address,
  };

  return treatedEntity;
};
