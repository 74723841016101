import { API_URL } from "@/constants/env";
import { Customer } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

const url = `${API_URL}/paciente/Salvar`;

export const insert = async (entity: Customer): Promise<Customer[]> => {
  const r = (await axios.post(url, prepareData(entity))).data;

  return r;
};
