import {
  AccountReceivable,
  AccountReceivableState,
  Installment,
} from "@/models/interfaces/financial/accountreceivable.interface";

const accountReceivable: AccountReceivable = {
  id: "",
  description: "",
  storeId: 0,
  financialcategoryId: 0,
  installmentId: 0,
  installments: new Array<Installment>(),
  installmentreceivable: undefined,
  receipts: [],
  Invoice: "",
  newBankslip: false,
};

const accountsReceivable: Array<AccountReceivable> = [];

export const state: AccountReceivableState = {
  accountReceivable,
  accountsReceivable,
};
