import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  DocumentType,
  DocumentTypeState,
} from "@/models/interfaces/shared/documenttype.interface";
import { DocumentTypeService as service } from "@/services/shared/documenttype.services";
import { constants } from "@/constants/shared/documenttype";

export const actions: ActionTree<DocumentTypeState, RootState> = {
  getAll({ commit }): void {
    service.getAll().then((res: DocumentType[]) => {
      commit(constants.SET_DOCUMENTTYPES, res);
    });
  },
  getAllExcept({ commit }, ids): void {
    service.getAllExcept(ids).then((res: DocumentType[]) => {
      commit(constants.SET_DOCUMENTTYPES, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: DocumentType) => {
      commit(constants.SET_DOCUMENTTYPE, res);
    });
  },
};
