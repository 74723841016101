import useLocalStorage from "@/composables/localStorage";
import { data } from "@/constants/shared/civilstatus";
import { CivilStatus } from "@/models/interfaces/shared/civilstatus.interface";

const storage = useLocalStorage("civilStatus", data);

const getAll = async (): Promise<CivilStatus[]> => storage.getAll();

const get = async (id: string): Promise<CivilStatus> => storage.get(id);

export const CivilStatusService = {
  getAll,
  get,
};
