import { ProductOrder } from "@/models/interfaces/material/productorder.interface";

export const treatedEntity = (entity: any): ProductOrder => {
  const {
    Itens,
    ItensAcatado,

    ...updateEntity
  } = entity;

  Itens.forEach((item: any) => {
    const series: string[] = [];
    const ItemsAcatadoFiltered = ItensAcatado.filter(
      (itemAcatado: any) => itemAcatado.ProdutoId == item.ProdutoId
    );

    if (ItemsAcatadoFiltered) {
      ItemsAcatadoFiltered.forEach((item: { Serie: any }) => {
        series.push(item.Serie);
      });
    }

    item.series = series;

    item.SerieObrigatoria = item.Produto.SerieObrigatoria;

    /* console.log(item.Produto.SerieObrigatoria);
    item.QuantidadeEnviada =
      item.Produto.SerieObrigatoria == 1
        ? series.length
        : item.QuantidadeEnviada; */
  });

  const treatedEntity = {
    ...updateEntity,
    Itens,
  };

  return treatedEntity;
};
