import { RouteRecordRaw } from "vue-router";

import MedicalCertificate from "./medicalcertificate";
import PartnershipReport from "./partnershipreport";
import PatientRecord from "./patientrecord";
import Prescription from "./prescription";

const itens: Array<RouteRecordRaw> = [
  ...MedicalCertificate,
  ...PartnershipReport,
  ...PatientRecord,
  ...Prescription,
];
export default itens;
