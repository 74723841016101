import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

export const update = async (
  id: string,
  entity: ProductOrder
): Promise<ProductOrder[]> => {
  const returnData = (
    await axios.post(`${API_URL}/pedido/salvar`, prepareData(entity))
  ).data;

  await axios.get(
    `${API_URL}/pedido/gerartransacao?id=${entity.Id}&usuario=${entity.ProfissionalAcatadoId}`
  );

  return returnData;
};
