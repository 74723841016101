import {
  WorkOrderInternalState,
  WorkOrder,
} from "@/models/interfaces/commercial/workorder.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<WorkOrderInternalState> = {
  setWorkOrdersInternal(state, payload: WorkOrder[]) {
    state.workordersInternal = payload;
  },

  setWorkOrderInternal(state, payload: WorkOrder) {
    /*     console.log("mutation", payload); */
    state.workorderInternal = payload;
  },

  insertWorkOrderInternal(state, payload: WorkOrder) {
    state.workorderInternal = payload;
  },

  removeWorkOrderInternal(state, payload: WorkOrder) {
    state.workorderInternal = payload;
  },

  updateWorkOrderInternal(state, payload: WorkOrder) {
    state.workorderInternal = payload;
  },
};
