import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductGenre,
  ProductGenreState,
} from "@/models/interfaces/shared/productgenre.interface";
import { ProductGenreService as productGenre } from "@/services/shared/productgenre.services";
import { constants } from "@/constants/shared/productgenre";

export const actions: ActionTree<ProductGenreState, RootState> = {
  getAll({ commit }): void {
    productGenre.getAll().then((res: ProductGenre[]) => {
      commit(constants.SET_PRODUCTGENRES, res);
    });
  },
  get({ commit }, id): void {
    productGenre.get(id).then((res: ProductGenre) => {
      commit(constants.SET_PRODUCTGENRE, res);
    });
  },
};
