<template>
  <!--   <main class="erro404" style="justify-content: center"> -->
  <section class="alinha-v erro404">
    <div class="margem alinha-centro">
      <h1>401</h1>
      <h2>Acesso negado, consulte o Administrador para maiores informações.</h2>
      <!--   <button><a href="./">Voltar</a></button> -->
    </div>
  </section>
  <!--   </main> -->
</template>

<script>
  export default {
    name: "NaoAutorizadoView",
  };
</script>
