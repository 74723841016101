import {
  Inventory,
  InventoryState,
} from "@/models/interfaces/material/inventory.interface";

const inventory: Inventory = {
  /* inventory */
  id: "0",
  productId: undefined,
  amount: 0,
  serialnumber: [],
  description: "",
};

const inventories: Array<Inventory> = [];

export const state: InventoryState = {
  inventory,
  inventories,
};
