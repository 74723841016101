import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Supplier,
  SupplierState,
} from "@/models/interfaces/material/supplier.interface";
import { SupplierService as service } from "@/services/material/supplier";
import { constants } from "@/constants/material/supplier";

export const actions: ActionTree<SupplierState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Supplier[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_SUPPLIERS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Supplier) => {
      commit(constants.SET_SUPPLIER, res);
    });
  },

  async getAllSupplierCore({ commit }): Promise<void> {
    const res: Supplier[] = await service.getAllSupplierCore();
    commit(constants.SET_SUPPLIERS, res);
  },

  async getByCnpj({ commit }, cnpj): Promise<void> {
    const res: Supplier = await service.getByCnpj(cnpj);
    commit(constants.SET_SUPPLIER, res);
  },

  async insert({ commit, state }): Promise<void> {
    /* const res: Supplier[] = */
    await service.insert(state.supplier);
    /* commit(constants.INSERT_SUPPLIER, res); */
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Supplier[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_SUPPLIERS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    /*  const res: Supplier[] =  */
    await service.update(id, state.supplier);

    /*  commit(constants.UPDATE_SUPPLIER, res); */
  },
};
