import { RouteRecordRaw } from "vue-router";
import Log from "../../views/others/log/Index.vue";
import Logs from "../../views/others/log/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/others/log",
  name: "Log",
  component: Log,
  beforeEnter: Auth,
});
itens.push({
  path: "/others/log/list",
  name: "Logs",
  component: Logs,
  beforeEnter: Auth,
});

export default itens;
