/* import { API_URL } from "@/constants/env";
import { UnitOfMeasurement } from "@/models/interfaces/shared/unitofmeansurement.interface";
import axios from "axios";

const getAll = async (): Promise<UnitOfMeasurement[]> => {
  const r = (await axios.get(`${API_URL}/shared/unitofmeasurement`)).data;
  return r;
};

const get = async (id: string): Promise<UnitOfMeasurement> => {
  return (await axios.get(`${API_URL}/shared/unitofmeasurement/${id}`)).data;
};

export const UnitOfMeasurementService = {
  getAll,
  get,
};
 */

import useLocalStorage from "@/composables/localStorage";
import { UnitOfMeasurement } from "@/models/interfaces/shared/unitofmeansurement.interface";
import store from "@/store";

const storage = useLocalStorage("unitofmeasurement");

const getAll = async (): Promise<UnitOfMeasurement[]> => {
  const credential = { ...store.state.login.credential };

  return storage.getAll(
    `/unidademedidaconf/todos?cliente=${credential.tenantId}`
  );
};

const get = async (id: string): Promise<UnitOfMeasurement> => {
  const credential = { ...store.state.login.credential };
  return storage.get(
    id,
    `/unidademedidaconf/todos?cliente=${credential.tenantId}`
  );
};

export const UnitOfMeasurementService = {
  getAll,
  get,
};
