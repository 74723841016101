import {
  PartnershipState,
  Partnership,
} from "@/models/interfaces/billing/partnership.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PartnershipState> = {
  setPartnerships(state, payload: Partnership[]) {
    state.partnerships = payload;
  },

  setPartnership(state, payload: Partnership) {
    state.partnership = payload;
  },

  insertPartnership(state, payload: Partnership) {
    state.partnership = payload;
  },

  removePartnership(state, payload: Partnership) {
    state.partnership = payload;
  },

  updatePartnership(state, payload: Partnership) {
    state.partnership = payload;
  },
};
