import { API_URL } from "@/constants/env";
import { Voucher } from "@/models/interfaces/commercial/voucher.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

export const insert = async (entity: Voucher): Promise<Voucher[]> => {
  try {
    const r = (
      await axios.post(`${API_URL}/cupomdesconto/Salvar`, prepareData(entity))
    ).data;

    return r;
  } catch (ex: any) {
    console.log(ex);
  }
  return [];
};
