import { TaskState, Task } from "@/models/interfaces/others/task.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TaskState> = {
  setTasks(state, payload: Task[]) {
    state.tasks = payload;
  },

  setTask(state, payload: Task) {
    state.task = payload;
  },

  insertTask(state, payload: Task) {
    state.task = payload;
  },

  removeTask(state, payload: Task) {
    state.task = payload;
  },

  updateTask(state, payload: Task) {
    state.task = payload;
  },
};
