export const getPayments = async (sale: any) => {
  //entity pode ser sale ou salefake montado na emissao da nota fiscal direta
  /*  if (sale.accountReceivable) { */

  //for (const account of sale.accountReceivable) { reduce map nao lidao bem com async await
  const accounts = await sale.accountReceivable.reduce(
    async (accPromise: Promise<any[]>, account: any) => {
      const acc = await accPromise; // Aguardando a resolução da promessa anterior

      acc.push({
        aVista: sale.accountReceivable.length == 1 ? true : false,
        meio: await getMeio(account.MeioPagamentoTipo),
        valor:
          account.MeioPagamentoTipo == 10
            ? 0
            : +(+account.Valor.toString()).toFixed(2),
      });

      return acc;
    },
    Promise.resolve([]) // Inicializa como uma promessa resolvida
  );

  return accounts;
  /*  }  else {
    return [
      {
        meio: getMeio(),
        valor: 0,
      },
    ];
  } */
};

const getMeio = (tipo: number) => {
  switch (tipo) {
    case 1: //01 - Dinheiro
      return "01";

    case 2: //03 - Cartão de Crédito
      return "03";

    case 3: //04 - Cartão de Débito
      return "04";

    case 6: //15 - Boleto Bancário
      return "15";

    case 7: //18 - Transferência bancária, Carteira Digital
      return "18";

    case 8: //20 - Pagamento Instantâneo (PIX) - Estático
      return "17";

    case 9: //16 - Depósito Bancário
      return "16";

    case 10: //90 - Sem pagamento
      return "90";

    case 4: //90 - chegue
      return "02";

    default: //99 - Outros
      return "99";
  }
};

/* dinheiro =1
cc = 2
cartao debito = 34
boleto = 6
transferencia = 7
pix=8
deposito =9 */
