import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Dashboard,
  DashboardState,
} from "@/models/interfaces/dashboard/dashboard.interface";

export const getters: GetterTree<DashboardState, RootState> = {
  getDashboard(state): Dashboard {
    return state.dashboard;
  },
};
