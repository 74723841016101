import { RouteRecordRaw } from "vue-router";
import AccountReceivable from "../../views/financial/accountsreceivable/Index.vue";
import AccountsReceivable from "../../views/financial/accountsreceivable/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/financial/accounts-receivable/:id",
  name: "AccountReceivable",
  component: AccountReceivable,
  beforeEnter: Auth,
});
itens.push({
  path: "/financial/accounts-receivable/list",
  name: "AccountsReceivable",
  component: AccountsReceivable,
  beforeEnter: Auth,
});

export default itens;
