import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Taxation,
  TaxationState,
} from "@/models/interfaces/commercial/taxation.interface";

export const getters: GetterTree<TaxationState, RootState> = {
  getNfse(state): Taxation {
    return state.taxation;
  },
};
