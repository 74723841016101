import messages from "@/helpers/validate/messages";
import store from "@/store";
import * as yup from "yup";

export default yup.object({
  dateofcompetence: yup
    .string()
    .test(
      "dateofcompetence",
      messages.compile({ id: "required", value: "Competência" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  financialaccount: yup
    .number()
    .test(
      "financialaccount",
      messages.compile({ id: "required", value: "Conta" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),

  openingvalue: yup
    .number()
    .test(
      "openingvalue",
      messages.compile({ id: "required", value: "Valor em caixa" }),
      (value: any) => {
        const result = !!value;

        if (!result) store.state.login.browsing.wait = 0;
        return result;
      }
    ),
});
