import useLocalStorage from "@/composables/localStorage";
import { data } from "@/constants/shared/contacttype";
import { ContactType } from "@/models/interfaces/shared/contacttype.interface";

const storage = useLocalStorage("contactType", data);

const getAll = async (): Promise<ContactType[]> => storage.getAll();

const get = async (id: string): Promise<ContactType> => storage.get(id);

const getAllExcept = async (ids: string[]): Promise<ContactType[]> => {
  const list: ContactType[] = await storage.getAll();

  const allExcept: ContactType[] = list.filter(item => !ids.includes(item.id));

  return allExcept;
};

export const ContactTypeService = {
  getAll,
  get,
  getAllExcept,
};
