import {
  SpsadtGuideState,
  SpsadtGuide,
} from "@/models/interfaces/billing/spsadtguide.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<SpsadtGuideState> = {
  setSpsadtGuides(state, payload: SpsadtGuide[]) {
    state.spsadtGuides = payload;
  },

  setSpsadtGuide(state, payload: SpsadtGuide) {
    state.spsadtGuide = payload;
  },

  insertSpsadtGuide(state, payload: SpsadtGuide) {
    state.spsadtGuide = payload;
  },

  removeSpsadtGuide(state, payload: SpsadtGuide) {
    state.spsadtGuide = payload;
  },

  updateSpsadtGuide(state, payload: SpsadtGuide) {
    state.spsadtGuide = payload;
  },
};
