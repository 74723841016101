import { RouteRecordRaw } from "vue-router";
import Supplier from "../../views/material/supplier/Index.vue";
import Suppliers from "../../views/material/supplier/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/supplier/:id",
  name: "Supplier",
  component: Supplier,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/supplier/list",
  name: "Suppliers",
  component: Suppliers,
  beforeEnter: Auth,
});

export default itens;
