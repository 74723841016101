import {
  EmployeeState,
  Employee,
} from "@/models/interfaces/administration/employee.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<EmployeeState> = {
  setEmployees(state, payload: Employee[]) {
    state.employees = payload;
  },

  setEmployee(state, payload: Employee) {
    state.employee = payload;
  },

  insertEmployee(state, payload: Employee) {
    state.employee = payload;
  },

  removeEmployee(state, payload: Employee) {
    state.employee = payload;
  },

  updateEmployee(state, payload: Employee) {
    state.employee = payload;
  },
};
