import { API_URL } from "@/constants/env";
import { Address } from "@/models/interfaces/shared/address.interface";
import axios from "axios";

const get = async (zipcode: string): Promise<Address> => {
  return (await axios.get(`${API_URL}/shared/address/${zipcode}`)).data;
};

export const AddressService = {
  get,
};
