export const getShare = (
  taxation: any,
  product: any,
  recipient: any,
  consumidorFinal: any
) => {
  console.log(recipient);

  const ufIssuer = taxation.UfSigla;
  const ufRecipient = recipient.endereco.estado;

  const idDest = ufIssuer == ufRecipient ? 1 : 2;

  const ie = recipient.inscricaoEstadual;

  const IndIEDest = !ie ? 9 : ie.toUpperCase() == "ISENTO" ? 2 : 1;

  if (idDest == 2 && consumidorFinal && IndIEDest == 9) {
    const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
    const ncm = ncms[0];

    const difals = ncm.CenarioIcmsInterestaduais.filter(
      (cenario: any) => cenario.UfSigla == ufRecipient
    );
    const difal = difals[0];

    if (!difal) return null;

    const percentualIcmsFcp = difal?.FundoCombatePobreza
      ? parseFloat(difal.FundoCombatePobreza) //.replace(",", ".")
      : 0;

    const aliquotaInterna = difal?.AliquotaInternaUfDestino
      ? parseFloat(difal.AliquotaInternaUfDestino)
      : 0;
    const aliquotaInterestadual = difal?.AliquotaInterestadualUfs
      ? parseFloat(difal.AliquotaInterestadualUfs)
      : 0;

    return {
      ufDestino: {
        percentualIcmsFcp,
        aliquotaInterna,
        aliquotaInterestadual,
      },
    };
  }
  return null;
};

/* se a NF-e indica uma operação interestadual, idDest=2, se o campo indFinal=1 indica consumidor final, se o IndIEDest=9 é não contribuinte, 
se esses campos forem preenchidos com os valores acima, você deverá informar o Grupo de ICMSUFDest e os respectivos campos nos totalizadores. */

//https://atendimento.tecnospeed.com.br/hc/pt-br/articles/360012311734-Rejei%C3%A7%C3%A3o-694-N%C3%A3o-informado-o-grupo-de-ICMS-para-a-UF-de-destino
