import {
  AccountReceivableState,
  AccountReceivable,
} from "@/models/interfaces/financial/accountreceivable.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<AccountReceivableState> = {
  setAccountsReceivable(state, payload: AccountReceivable[]) {
    state.accountsReceivable = payload;
  },

  setAccountReceivable(state, payload: AccountReceivable) {
    state.accountReceivable = payload;
  },

  insertAccountReceivable(state, payload: AccountReceivable) {
    state.accountReceivable = payload;
  },

  removeAccountReceivable(state, payload: AccountReceivable) {
    state.accountReceivable = payload;
  },

  updateAccountReceivable(state, payload: AccountReceivable) {
    state.accountReceivable = payload;
  },
};
