import { RouteRecordRaw } from "vue-router";
import Test from "../../views/commercial/test/Index.vue";
import Tests from "../../views/commercial/test/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/test/:id",
  name: "Test",
  component: Test,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/test/list",
  name: "Tests",
  component: Tests,
  beforeEnter: Auth,
});

export default itens;
