import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Taxation } from "@/models/interfaces/commercial/taxation.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "tributacao").fetchData();

const getAllCombo = async (): Promise<Taxation[]> => {
  const credential = { ...store.state.login.credential };
  const r = (
    await axios.get(
      `${API_URL}/tributacao/todos?cliente=${credential.tenantId}`
    )
  ).data;
  return r;
};

const get = async (id: string): Promise<Taxation> => {
  if (id == "new") {
    const entity: Taxation = {
      id: "",
      Nome: "",
      Cenq: "",
      CfopId: 0,
      CstIcmsId: 0,
      CsosnId: 0,
      CstIpiId: 0,
      CstCofinsId: 0,
      CstPisId: 0,
      ModBcIcmsId: 0,
      ModBcIcmsStId: 0,
      UfId: 0,
      PIcms: 0,
      PReducaoIcms: 0,
      PIcmsSt: 0,
      PReducaoIcmsSt: 0,
      PIpi: 0,
      ExtIpi: 0,
      PPis: 0,
      PCofins: 0,
      Padrao: 0,
      IdporClienteNotePro: 0,
      IdCliente: 0,
      QtdePaginasGrid: 0,
      IdCriptografado: "",
      Erro: "",
      Id: 0,
      Apagado: false,
      Ativo: false,
      Usuario: 0,
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/tributacao?id=${id}`)).data;
};

const getById = async (id: string) =>
  (await axios.get(`${API_URL}/tributacao?id=${id}`)).data;

const getByCenarioNcm = async (cenario: string, ncm: string) => {
  return (
    await axios.get(
      `${API_URL}/cenarioimpostos/getbycenarioncm?cenario=${cenario}&ncm=${ncm}`
    )
  ).data;
};

const insert = async (entity: Taxation): Promise<Taxation[]> => {
  return (await axios.post(`${API_URL}/tributacao/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Taxation[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/tributacao/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: Taxation): Promise<Taxation[]> => {
  return (await axios.post(`${API_URL}/tributacao/Salvar`, prepareData(entity)))
    .data;
};

const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /*   const { id, ...updateEntity } = entity; */

  /*   const credential = { ...store.state.login.credential };

  const entitySave = {}; */

  return entity;
};

export const TaxationService = {
  getAll,
  getAllCombo,
  getById,
  get,
  getByCenarioNcm,
  insert,
  remove,
  update,
};
