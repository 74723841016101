import { API_URL } from "@/constants/env";
import { Consigned } from "@/models/interfaces/commercial/consigned.interface";
import axios from "axios";

const getAll = async (): Promise<Consigned[]> => {
  const r = (await axios.get(`${API_URL}/commercial/consignedproduct`)).data;
  return r;
};

const get = async (id: string): Promise<Consigned> => {
  if (id == "new") {
    const entity: Consigned = {
      id: "",
      partnerId: 0,
      note: "",
      consignedproductitem: [],
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/commercial/consignedproduct/${id}`)).data;
};

const insert = async (entity: Consigned): Promise<Consigned[]> => {
  return (
    await axios.post(
      `${API_URL}/commercial/consignedproduct`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Consigned[]> =>
  (await axios.delete(`${API_URL}/commercial/consignedproduct/${id}`)).data;

const update = async (id: string, entity: Consigned): Promise<Consigned[]> => {
  return (
    await axios.put(
      `${API_URL}/commercial/consignedproduct/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Consigned) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const ConsignedService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
