import { RouteRecordRaw } from "vue-router";
import Unit from "@/views/config/administration/unit/Index.vue";
import Units from "@/views/config/administration/unit/List.vue";
import AdministrationMenu from "@/components/menu/AdministrationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/administration/unit/:id",
  name: "Unit",
  components: {
    default: Unit,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/administration/unit/list",
  name: "Units",
  components: {
    default: Units,
    sidebar: AdministrationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
