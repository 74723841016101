import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { CostCenter } from "@/models/interfaces/financial/costcenter.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "centrocusto").fetchData();

const getAllByTenant = async (): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const r = (
    await axios.get(
      `${API_URL}/centrocusto/Todos?cliente=${credential.tenantId}`
    )
  ).data;

  return r;
};

const get = async (id: string): Promise<CostCenter> => {
  if (id == "new") {
    const entity: CostCenter = { id: "0", description: "" };
    return entity;
  }

  const r = (await axios.get(`${API_URL}/centrocusto?id=${id}`)).data;

  return r;
};

const insert = async (entity: CostCenter): Promise<CostCenter[]> => {
  return (
    await axios.post(`${API_URL}/centrocusto/Salvar`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<CostCenter[]> => {
  const credential = { ...store.state.login.credential };

  return (
    await axios.delete(
      `${API_URL}/centrocusto/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: CostCenter
): Promise<CostCenter[]> => {
  return (
    await axios.post(`${API_URL}/centrocusto/Salvar`, prepareData(entity))
  ).data;
};

const prepareData = (entity: CostCenter) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  return { Id, IdCliente, usuario, ...updateEntity };
};

export const CostCenterService = {
  getAll,
  getAllByTenant,
  get,
  insert,
  remove,
  update,
};
