import { API_URL } from "@/constants/env";
import { Voucher } from "@/models/interfaces/commercial/voucher.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

export const update = async (
  id: string,
  entity: Voucher
): Promise<Voucher[]> => {
  return (
    await axios.post(`${API_URL}/cupomdesconto/Salvar`, prepareData(entity))
  ).data;
};
