//import Sidebar from "@/views/_layout/Sidebar_Material.vue";
import { RouteRecordRaw } from "vue-router";

//import Main from "@/views/config/material/brand/List.vue";

import Brand from "./brandRoute";
import SupplierType from "./suppliertypeRoute";

// const main = [
//   {
//     path: "/config/material/",
//     name: "MainMaterial",
//     component: Main,
//     //beforeEnter: Auth,
//   },
// ];

// const childrenSidebar = [...main, ...Brand, ...SupplierType];

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: "/config/material/",
//     name: "Sidebar_Material",
//     component: Sidebar,
//     children: childrenSidebar,
//   },
// ];

// export default routes;

const itens: Array<RouteRecordRaw> = [...Brand, ...SupplierType];
export default itens;
