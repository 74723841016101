import { RouteRecordRaw } from "vue-router";
import Block from "@/views/config/schedule/block/Index.vue";
import Blocks from "@/views/config/schedule/block/List.vue";
import ScheduleMenu from "@/components/menu/ScheduleMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/schedule/block/:id",
  name: "Block",
  component: {
    default: Block,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/schedule/block/list",
  name: "Blocks",
  components: {
    default: Blocks,
    sidebar: ScheduleMenu,
  },
  beforeEnter: Auth,
});

export default itens;
