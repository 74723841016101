import { RouteRecordRaw } from "vue-router";
import Product from "../../views/material/product/Index.vue";
import Products from "../../views/material/product/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/product/:id",
  name: "Product",
  component: Product,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/product/list",
  name: "Products",
  component: Products,
  beforeEnter: Auth,
});

export default itens;
