import {
  AccountPayable,
  AccountPayableState,
  Payee,
} from "@/models/interfaces/financial/accountpayable.interface";

const accountPayable: AccountPayable = {
  id: "",
  personId: undefined,
  financialcategoryId: undefined,
  description: "",
  competencedate: "",
  value: undefined,
  storeId: undefined,
  createddate: undefined,
  duedate: undefined,
  isentry: false,
  supplierName: "",
  paymentValue: 0,
  installmentId: 0,
  installments: [],
  installmenttopay: undefined,
  payments: [],
};

const accountsPayable: Array<AccountPayable> = [];

const payee: Payee = {
  id: "",
  name: "",
  idCriptografado: "",
};

const payees: Array<Payee> = [];

export const state: AccountPayableState = {
  accountPayable,
  accountsPayable,
  payee,
  payees,
};
