import { API_URL } from "@/constants/env";
import { Customer } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";

const url = `${API_URL}/paciente/Salvar`;

export const update = async (id: string, entity: Customer) => {
  return (await axios.post(url, prepareData(entity))).data;
};
