import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Employee,
  EmployeeState,
} from "@/models/interfaces/administration/employee.interface";

export const getters: GetterTree<EmployeeState, RootState> = {
  getEmployee(state): Employee {
    return state.employee;
  },
};
