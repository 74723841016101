import { RouteRecordRaw } from "vue-router";
import Workorder from "@/views/commercial/workorder/internal/Index.vue";
import Workorders from "@/views/commercial/workorder/internal/List.vue";
import WorkOrderMenuVue from "@/components/menu/WorkOrderMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/workorder/internal/:id",
  name: "WorkorderInternal",
  components: {
    default: Workorder,
    sidebar: WorkOrderMenuVue,
  },
  beforeEnter: Auth,
});

itens.push({
  path: "/commercial/workorder/internal/list",
  name: "WorkordersInternal",
  components: {
    default: Workorders,
    sidebar: WorkOrderMenuVue,
  },
  beforeEnter: Auth,
});

export default itens;
