import {
  CivilStatus,
  CivilStatusState,
} from "@/models/interfaces/shared/civilstatus.interface";

const civilStatus: CivilStatus = {
  id: "",
  description: "",
};

const civilStatuss: Array<CivilStatus> = [];

export const state: CivilStatusState = {
  civilStatus,
  civilStatuss,
};
