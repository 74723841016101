export const getCofins = (taxation: any, product: any, valor: any) => {
  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const cst = ncm.CstCofinsCodigo.toString();

  const aliquota = ncm.AliquotaCofins
    ? ncm.AliquotaCofins // parseFloat(ncm.AliquotaCofins.replace(",", "."))
    : 0;

  switch (cst) {
    case "06":
    case "08":
      return {
        cst,
      };
    default:
      return {
        cst,
        baseCalculo: {
          valor,
        },
        aliquota,
        valor,
      };
  }
};
