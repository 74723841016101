import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PerformingPhysician,
  PerformingPhysicianState,
} from "@/models/interfaces/billing/performingphysician.interface";

export const getters: GetterTree<PerformingPhysicianState, RootState> = {
  getPerformingPhysician(state): PerformingPhysician {
    return state.performingPhysician;
  },
};
