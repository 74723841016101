import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Loading,
  LoadingState,
} from "@/models/interfaces/shared/loading.interface";

export const getters: GetterTree<LoadingState, RootState> = {
  getLoading(state): Loading {
    return state.loading;
  },
};
