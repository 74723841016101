import useLocalStorage from "@/composables/localStorage";
import { Profession } from "@/models/interfaces/shared/profession.interface";

const storage = useLocalStorage("profession");

const getAll = async (): Promise<Profession[]> =>
  storage.getAll("/profissao/todos");

const get = async (id: string): Promise<Profession> =>
  storage.get(id, "/profissao/todos");

export const ProfessionService = {
  getAll,
  get,
};
