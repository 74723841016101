import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Gender,
  GenderState,
} from "@/models/interfaces/shared/gender.interface";
import { GenderService as gender } from "@/services/shared/gender.services";
import { constants } from "@/constants/shared/gender";

export const actions: ActionTree<GenderState, RootState> = {
  getAll({ commit }): void {
    gender.getAll().then((res: Gender[]) => {
      commit(constants.SET_GENDERS, res);
    });
  },
  get({ commit }, id): void {
    gender.get(id).then((res: Gender) => {
      commit(constants.SET_GENDER, res);
    });
  },
};
