export const NAME = {
  field: "Nome",
  title: "Nome",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const PERFIL_DESCRIPTION = {
  field: "PerfilNome",
  title: "Perfil",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DESCRIPTION = {
  field: "Descricao",
  title: "Descrição",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const TELEFONE = {
  field: "Telefone1",
  title: "Telefone",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const FONE_NUMBER = {
  field: "Telefone",
  title: "Telefone",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const UNIT_DESCRIPTION = {
  field: "UnidadeNome",
  title: "Unidade",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const EMPLOYEE_DESCRIPTION = {
  field: "ProfissionalNome",
  title: "Colaborador",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const START_TIME = {
  field: "HoraInicialFormatada",
  title: "Hora Início",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const END_TIME = {
  field: "HoraFinalFormatada",
  title: "Hora Fim",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const START_DATE = {
  field: "DataInicialFormatada",
  title: "Data Início",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const END_DATE = {
  field: "DataFinalFormatada",
  title: "Data Fim",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const END_DATE2 = {
  field: "DataFimFormatada",
  title: "Data Fim",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const WEEKDAY = {
  field: "DiaSemanaNome",
  title: "Dia da Semana",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DATE = {
  field: "DataFormatada",
  title: "Data",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DATE_LOAN = {
  field: "DataEmprestimoFormatada",
  title: "Data",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const INSERT_DATE = {
  field: "DataCadastroFormatada",
  title: "Cadastro",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CNPJ = {
  field: "EmitCnpj",
  title: "Cnpj",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CATEGORY_TYPE = {
  field: "TipoCategoriaExtenso",
  title: "Tipo",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ID_BY_TENENT = {
  field: "IdporClienteNotePro",
  title: "Id",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CELL_PHONE = {
  field: "TelefoneCelular",
  title: "Celular",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const PHONE = {
  field: "TelefoneResidencial",
  title: "Residencial",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STREET = {
  field: "Logradouro",
  title: "Logradouro",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const NEIGHBORHOOD = {
  field: "Bairro",
  title: "Bairro",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CITY = {
  field: "CidadeNome",
  title: "Cidade",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const PARTNERSHIP_NAME = {
  field: "ConvenioNome",
  title: "Convênio",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const BATCH_NUMBER = {
  field: "NumeroLote",
  title: "Número",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const TISS_VERSION = {
  field: "VersaoTISSNome",
  title: "Versão",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CUSTOMER_NAME = {
  field: "ClienteNome",
  title: "Cliente",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CUSTOMER_NFE = {
  field: "ClienteNfe",
  title: "Destinatário",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CONSULTATION_DATE = {
  field: "DataConsultaFormatada",
  title: "Consulta",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const COMPETENCE_DATE = {
  field: "DataCompetenciaFormatada",
  title: "Data de competência",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ATENDIMENTO_DATE = {
  field: "DataAtendimentoFormatada",
  title: "Consulta",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const BILLING_DATE = {
  field: "DataFaturamentoFormatada",
  title: "Faturado",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_BILLING = {
  field: "StatusExtenso",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const NFSE_NUMBER2 = {
  field: "NotaFiscal",
  title: "Num. NF",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const NFSE_NUMBER = {
  field: "NumeroNf",
  title: "Num. NF",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CNPJ_UNIT = {
  field: "Cnpj",
  title: "Cnpj",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ISSUE_DATE = {
  field: "DataEmissaoFormatada",
  title: "Emissão",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_NFE = {
  field: "StatusPorExtenso",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS = {
  field: "StatusPorExtenso",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_VOUCHER = {
  field: "Status",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const VOUCHER = {
  field: "Cupom",
  title: "Cupom",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ID_PRODUCT = {
  field: "IdProduto",
  title: "Código",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ID_TEST = {
  field: "IdTeste",
  title: "Código",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ID_LOAN = {
  field: "IdEmprestimo",
  title: "Código",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const SUPPLIER_NAME = {
  field: "ContatoNome",
  title: "Fornecedor",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const PRICE = {
  field: "ValorFormatado",
  title: "Preço",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ID_PRODUCT_ORDER = {
  field: "Codigo",
  title: "Id",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_PRODUCT_ORDER = {
  field: "StatusPorExtenso",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DELIVERY_DATETIME = {
  field: "DataHoraEntregaFormatada",
  title: "Data Entrega",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const BACK_ORDER = {
  field: "BackOrderPorExtenso",
  title: "Back Order",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DATE_TIME = {
  field: "DataHoraFormatada",
  title: "Data",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DUE_DATE = {
  field: "DataVencimentoFormatada",
  title: "Vencimento",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const PAYMENT_DATE = {
  field: "DataPagamentoFormatada",
  title: "Pagto.",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const VALUE = {
  field: "ValorFormatado", // ValorReceitaFormatada
  title: "Valor",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const OBSERVACAO = {
  field: "Observacao", // ValorReceitaFormatada
  title: "",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
  tag: "error",
};

export const CRITICA = {
  field: "Critica", // ValorReceitaFormatada
  title: "",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
  tag: "error",
};

export const PAID = {
  field: "PagoExtenso",
  title: "Pago",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
  tag: "paid",
};

export const MEANOFPAYMENT = {
  field: "MeioPagamentoNome",
  title: "Meio Pagto.",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const ORIGIN = {
  field: "ContaNome",
  title: "Origem",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DESTINATION = {
  field: "ContaDestinoNome",
  title: "Destino",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const TYPE_SUPPLIER = {
  field: "TipoContatoNome",
  title: "Tipo",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const WORKORDER_ID = {
  field: "IdOrdemServico",
  title: "Código",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const CUSTOMER_ID = {
  field: "ClienteIdCliente",
  title: "Cód. Cliente",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const EXPECTED_DATE = {
  field: "DataPrevistaFormatada",
  title: "Data Prevista",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const DELIVERY_DATE = {
  field: "DataHoraEntregaFormatada",
  title: "Data Entrega",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_OS = {
  field: "StatusOsFormatada",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
};

export const STATUS_CASHIER = {
  field: "Fechado",
  title: "Situação",
  type: undefined,
  items: undefined,
  iscalculated: undefined,
  tag: "close",
};
