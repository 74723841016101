import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  StockTransfer,
  StockTransferState,
} from "@/models/interfaces/material/stocktransfer.interface";

export const getters: GetterTree<StockTransferState, RootState> = {
  getStockTransfer(state): StockTransfer {
    return state.stockTransfer;
  },
};
