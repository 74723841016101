import {
  PurchaseState,
  Purchase,
} from "@/models/interfaces/material/purchase.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<PurchaseState> = {
  setPurchases(state, payload: Purchase[]) {
    state.purchases = payload;
  },

  setPurchase(state, payload: Purchase) {
    state.purchase = payload;
  },

  insertPurchase(state, payload: Purchase) {
    state.purchase = payload;
  },

  removePurchase(state, payload: Purchase) {
    state.purchase = payload;
  },

  updatePurchase(state, payload: Purchase) {
    state.purchase = payload;
  },

  addItem(state, item) {
    state.purchase.Itens.push(item);
  },
  addAccountPayable(state, account) {
    state.purchase.ContasAPagar.push(account);
  },
};
