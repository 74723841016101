import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  UnitOfMeasurement,
  UnitOfMeasurementState,
} from "@/models/interfaces/shared/unitofmeansurement.interface";
import { UnitOfMeasurementService as unitOfMeasurement } from "@/services/shared/unitofmeasurement.services";
import { constants } from "@/constants/shared/unitofmeasurement";

export const actions: ActionTree<UnitOfMeasurementState, RootState> = {
  getAll({ commit }): void {
    unitOfMeasurement.getAll().then((res: UnitOfMeasurement[]) => {
      commit(constants.SET_UNITSOFMEASUREMENT, res);
    });
  },
  get({ commit }, id): void {
    unitOfMeasurement.get(id).then((res: UnitOfMeasurement) => {
      commit(constants.SET_UNITOFMEASUREMENT, res);
    });
  },
};
