import {
  ExceptionState,
  Exception,
} from "@/models/interfaces/schedule/exception.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ExceptionState> = {
  setExceptions(state, payload: Exception[]) {
    state.exceptions = payload;
  },

  setException(state, payload: Exception) {
    state.exception = payload;
  },

  insertException(state, payload: Exception) {
    state.exception = payload;
  },

  removeException(state, payload: Exception) {
    state.exception = payload;
  },

  updateException(state, payload: Exception) {
    state.exception = payload;
  },
};
