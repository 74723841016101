import { RouteRecordRaw } from "vue-router";
import Checklist from "@/views/config/commercial/checklist/Index.vue";
import Checklists from "@/views/config/commercial/checklist/List.vue";
import CommercialMenu from "@/components/menu/CommercialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/commercial/checklist/:id",
  name: "Checklist",
  components: {
    default: Checklist,
    sidebar: CommercialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/commercial/checklist/list",
  name: "Checklists",
  components: {
    default: Checklists,
    sidebar: CommercialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
