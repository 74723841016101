import { CityState, City } from "@/models/interfaces/shared/city.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CityState> = {
  setCities(state, payload: City[]) {
    state.cities = payload;
    state.citiesNf = payload;
  },

  setCity(state, payload: City) {
    state.city = payload;
  },
};
