import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  MeanOfPayment,
  MeanOfPaymentState,
} from "@/models/interfaces/financial/meanofpayment.interface";
import { MeanOfPaymentService as service } from "@/services/financial/meanOfPayment.services";
import { constants } from "@/constants/financial/meanofpayment";

export const actions: ActionTree<MeanOfPaymentState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: MeanOfPayment[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_MEANSOFPAYMENT, res);
  },
  getAllExcept({ commit }, ids): void {
    service.getAllExcept(ids).then((res: MeanOfPayment[]) => {
      commit(constants.SET_MEANSOFPAYMENT, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: MeanOfPayment) => {
      commit(constants.SET_MEANOFPAYMENT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: MeanOfPayment[] = await service.insert(state.meanOfPayment);
    commit(constants.INSERT_MEANOFPAYMENT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: MeanOfPayment[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_MEANSOFPAYMENT, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: MeanOfPayment[] = await service.update(id, state.meanOfPayment);
    commit(constants.UPDATE_MEANOFPAYMENT, res);
  },
};
