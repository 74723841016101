import { RouteRecordRaw } from "vue-router";
import Bankslip from "../../views/commercial/bankslip/Index.vue";
import Bankslips from "../../views/commercial/bankslip/List.vue";
import Dashboard from "../../views/commercial/bankslip/Dashboard.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/bankslip/:id",
  name: "Bankslip",
  component: Bankslip,
});
itens.push({
  path: "/commercial/bankslip/list",
  name: "Bankslips",
  component: Bankslips,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/bankslip/dashboard",
  name: "BankslipDashboard",
  component: Dashboard,
  beforeEnter: Auth,
});

export default itens;
