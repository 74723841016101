import {
  UnitOfMeasurement,
  UnitOfMeasurementState,
} from "@/models/interfaces/shared/unitofmeansurement.interface";

const unitOfMeasurement: UnitOfMeasurement = {
  id: "",
  description: "",
  acronyml: "",
};

const unitsOfMeasurement: Array<UnitOfMeasurement> = [];

export const state: UnitOfMeasurementState = {
  unitOfMeasurement,
  unitsOfMeasurement,
};
