import { API_URL } from "@/constants/env";
import {
  Action,
  Functionality,
  Module,
  Permission,
  Profile,
} from "@/models/interfaces/administration/profile.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

/* const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  const browsing = await { ...store.state.login.browsing };
  browsing.lastFilters = lastFilters;
  browsing.lastSearchText = searchText;
  browsing.lastPage = page;
  store.state.login.browsing = browsing;

  let active = "Ativo";

  for (let i = 0; i < lastFilters?.length; i++) {
    const line = lastFilters[i];

    for (let j = 0; j < lastFilters?.length; j++) {
      const field = line[j];

      if (field.field == "Ativo") {
        active = field.model;
      }
    }
  }

  return (
    await axios.get(
      `${API_URL}/api/perfil?cliente=${credential.tenantId}&pos=${page}&tipoRegistro=${active}&filtro=${searchText}`
    )
  ).data;
}; */

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "perfil").fetchData();

const getAllbyTenant = async (): Promise<any[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.get(`${API_URL}/perfil/todos?cliente=${credential.tenantId}`)
  ).data;
};

const get = async (id: string): Promise<Profile> => {
  if (id == "new") {
    const modules: [] = (
      await axios.get(`${API_URL}/config/administration/profile/modules`)
    ).data;

    const entity: Profile = {
      id: "",
      description: "",
      modules,
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/config/administration/profile/${id}`))
    .data;
};

const insert = async (entity: Profile): Promise<Profile[]> => {
  return (
    await axios.post(
      `${API_URL}/config/administration/profile`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Profile[]> =>
  (await axios.delete(`${API_URL}/config/administration/profile/${id}`)).data;

const update = async (id: string, entity: Profile): Promise<Profile[]> => {
  return (
    await axios.put(
      `${API_URL}/config/administration/profile/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Profile) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, description, modules } = entity;

  const permissions: Array<Permission> = [];

  modules.map((module: Module) =>
    module.functionalities.map((functionality: Functionality) =>
      functionality.actions.map((action: Action) => {
        if (action.isaction)
          permissions.push({
            functionalityId: functionality.functionalityId,
            actionId: action.actionId,
          });
      })
    )
  );

  /*   const initialValue = 0;
const sumWithInitial = array1.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
 */

  return { description, permissions };
};

/* {
  "description": "Administrator",
  "permissions": [{"actionId":1,"functionalityId":1}]
} */

export const ProfileService = {
  getAll,
  getAllbyTenant,
  get,
  insert,
  remove,
  update,
};
