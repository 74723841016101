import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Voucher,
  VoucherState,
} from "@/models/interfaces/commercial/voucher.interface";

export const getters: GetterTree<VoucherState, RootState> = {
  getVoucher(state): Voucher {
    return state.voucher;
  },
};
