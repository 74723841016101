import {
  InvoiceProductState,
  InvoiceProduct,
} from "@/models/interfaces/commercial/invoiceproduct.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<InvoiceProductState> = {
  setInvoices(state, payload: InvoiceProduct[]) {
    state.invoicesProduct = payload;
  },

  setInvoice(state, payload: InvoiceProduct) {
    state.invoiceProduct = payload;
  },

  insertInvoice(state, payload: InvoiceProduct) {
    state.invoiceProduct = payload;
  },

  removeInvoice(state, payload: InvoiceProduct) {
    state.invoiceProduct = payload;
  },

  updateInvoice(state, payload: InvoiceProduct) {
    state.invoiceProduct = payload;
  },
};
