import {
  ProductTypeState,
  ProductType,
} from "@/models/interfaces/shared/producttype.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProductTypeState> = {
  setProductTypes(state, payload: ProductType[]) {
    state.productTypes = payload;
  },

  setProductType(state, payload: ProductType) {
    state.productType = payload;
  },
};
