import { changeStatusActive } from "./changestatusactive.service";
import { getAll } from "./getall.service";
import { getAnamnesis } from "./getanamnesis.service";
import { getById } from "./getbyid.service";
import { getByNameForAutoComplete } from "./getbynameforautocomplete.service";
import { getMedicalCertificate } from "./getmedicalcertificate.service";
import { getMedicalPrescription } from "./getmedicalprescription.service";
import { getMedicalRecord } from "./getmedicalrecord.service";
import { getMedicalRecords } from "./getmedicalrecords.service";
import { getMedicalReport } from "./getmedicalreport.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { update } from "./update.service";

export const CustomerService = {
  getAll,
  getById,
  getByNameForAutoComplete,
  insert,
  remove,
  update,
  changeStatusActive,
  getMedicalRecords,
  getMedicalRecord,
  getAnamnesis,
  getMedicalCertificate,
  getMedicalPrescription,
  getMedicalReport,
};
