import { RouteRecordRaw } from "vue-router";
import PartnershipReport from "@/views/config/medicalconsultation/partnershipreport/Index.vue";
import PartnershipReports from "@/views/config/medicalconsultation/partnershipreport/List.vue";
import MedicalConsultationMenu from "@/components/menu/MedicalConsultationMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/medical-consultation/partnership-report/:id",
  name: "PartnershipReport",
  components: {
    default: PartnershipReport,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/medical-consultation/partnership-report/list",
  name: "PartnershipReports",
  components: {
    default: PartnershipReports,
    sidebar: MedicalConsultationMenu,
  },
  beforeEnter: Auth,
});

export default itens;
