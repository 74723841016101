import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Block,
  BlockState,
} from "@/models/interfaces/schedule/block.interface";
import { BlockService as block } from "@/services/schedule/block.services";
import { constants } from "@/constants/schedule/block";

export const actions: ActionTree<BlockState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Block[] = await block.getAll(page, searchText, lastFilters);
    commit(constants.SET_BLOCKS, res);
  },
  get({ commit }, id): void {
    block.get(id).then((res: Block) => {
      commit(constants.SET_BLOCK, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Block[] = await block.insert(state.block);
    commit(constants.INSERT_BLOCK, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await block.remove(ids);
    /*     const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Block[] = await block.getAll(page, searchText, lastFilters);

    commit(constants.SET_BLOCKS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Block[] = await block.update(id, state.block);
    commit(constants.UPDATE_BLOCK, res);
  },
};
