import { API_URL } from "@/constants/env";
import { Supplier } from "@/models/interfaces/material/supplier.interface";
import store from "@/store";
import axios from "axios";

export const getAllSupplierCore = async (): Promise<Supplier[]> => {
  const credential = { ...store.state.login.credential };
  const r = (
    await axios.get(
      `${API_URL}/contato/TodosPorTipo?cliente=${credential.tenantId}&tipo=4`
    )
  ).data;

  return r;
};
