import {
  DocumentTypeState,
  DocumentType,
} from "@/models/interfaces/shared/documenttype.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<DocumentTypeState> = {
  setDocumentTypes(state, payload: DocumentType[]) {
    state.documentTypes = payload;
  },

  setDocumentType(state, payload: DocumentType) {
    state.documentType = payload;
  },
};
