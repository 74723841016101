import { API_URL } from "@/constants/env";
import axios from "axios";
import { useDate } from "@/composables/date";
import { prepareData } from "../invoiceproduct/preparedata.service";
import store from "@/store";

export const newInvoice = async (entity: any) => {
  if (!entity.newInvoice) return;

  const credential = { ...store.state.login.credential };

  if (entity.Id) {
    const invoiceSale: any = (
      await axios.get(
        `${API_URL}/nfe/notafiscalporvenda?cliente=${credential.tenantId}&id=${entity.Id}`
      )
    ).data;

    if (invoiceSale.length) {
      return;
    }
  }

  entity.ChaveAcesso = "";
  entity.CfopId = 0;
  entity.DataEmissaoFormatada = useDate("pt-BR").today().format;
  entity.NumeroNf = 0;
  entity.StatusNfe = 1;
  entity.TipoCliente = 1;
  entity.TipoNf = 2;
  entity.Natureza = "VENDA";
  entity.Saida = 1;
  entity.FinalidadeOperacao = 1;

  const entitySave = prepareData(entity, true);

  /*   const invoiceProduct =  */
  (await axios.post(`${API_URL}/nfe/Salvar`, entitySave)).data;
};
