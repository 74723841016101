import {
  SaleConsumerState,
  SaleConsumer,
} from "@/models/interfaces/commercial/saleConsumer.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<SaleConsumerState> = {
  setSales(state, payload: SaleConsumer[]) {
    state.salesConsumer = payload;
  },

  setSale(state, payload: SaleConsumer) {
    state.saleConsumer = payload;
  },

  insertSale(state, payload: SaleConsumer) {
    state.saleConsumer = payload;
  },

  removeSale(state, payload: SaleConsumer) {
    state.saleConsumer = payload;
  },

  updateSale(state, payload: SaleConsumer) {
    state.saleConsumer = payload;
  },
};
