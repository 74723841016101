import { API_URL } from "@/constants/env";
import { Anamnesis } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";

export const getAnamnesis = async (
  id: string,
  customer: string
): Promise<Anamnesis> => {
  const url = `${API_URL}/prontuario/ficha?id=${id}&paciente=${customer}`;

  const entity: Anamnesis = {
    description: "",
    fieldsAnamnesis: [],
  };

  entity.fieldsAnamnesis = (await axios.get(url)).data;

  return entity;
};
