import { cancel } from "./cancel.service";
import { correctionletter } from "./correctionletter.service";
import { get } from "./get.service";
import { getAll } from "./getall.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { update } from "./update.service";

export const InvoiceProductService = {
  getAll,
  get,
  insert,
  remove,
  update,
  cancel,
  correctionletter,
};
