import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { City, CityState } from "@/models/interfaces/shared/city.interface";
import { CityService as city } from "@/services/shared/city.services";
import { constants } from "@/constants/shared/city";

export const actions: ActionTree<CityState, RootState> = {
  getAll({ commit }): void {
    city.getAll().then((res: City[]) => {
      commit(constants.SET_CITIES, res);
    });
  },
  get({ commit }, id): void {
    city.get(id).then((res: City) => {
      commit(constants.SET_CITY, res);
    });
  },

  async getByState({ commit }, id): Promise<City[]> {
    const res: City[] = await city.getByState(id);

    /* commit(constants.SET_CITIES, res); */

    return res;
  },
};
