import { API_INVOICE_URL, X_API_KEY, API_URL } from "@/constants/env";
import axios from "axios";
import { prepareDataInvoice } from "./prepareDataInvoice";
import { prepareData } from "./preparedata.service";
import { CONSULTATION_DATE } from "@/helpers/fields";

export const emit = async (entity: any) => {
  if (entity.StatusNfe == 5 || entity.StatusNfe == 1) {
    const dataInvoice = await prepareDataInvoice(entity);
    console.log("data", dataInvoice);
    try {
      const invoice = (
        await axios.post(`${API_INVOICE_URL}/nfe`, dataInvoice, {
          headers: {
            "X-API-KEY": X_API_KEY,
          },
        })
      ).data;

      entity.ChaveAcesso = invoice.documents[0].id;
      entity.StatusNfe = 2;

      const url = `${API_URL}/nfe/updatestatuschave?id=${entity.Id}&status=2&chave=${entity.ChaveAcesso}`;

      await axios.get(url);

      return entity.ChaveAcesso;
    } catch (ex: any) {
      console.log("Error: ", ex.response.data, dataInvoice);
      const fields = ex.response.data.error?.data?.fields;

      if (fields) {
        if (Array.isArray(fields)) {
          entity.Critica = ex.response.data.error.data.fields.reduce(
            (resultado: string, item: any) => {
              const [key, value] = Object.entries(item);
              resultado += `${key}: ${value}`;
              return resultado;
            },
            `${ex.response.data.message}: `
          );
        } else {
          const [key, value] = Object.entries(fields);
          entity.Critica = `${key}: ${value}`;
        }
      } else {
        entity.Critica = ex.response.data.error.message;
      }

      if (entity.Id) {
        entity.StatusNfe = 5;
        await axios.post(`${API_URL}/nfe/Salvar`, prepareData(entity));
      }
    }
  }
};
