import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Product,
  ProductState,
} from "@/models/interfaces/material/product.interface";

export const getters: GetterTree<ProductState, RootState> = {
  getProduct(state): Product {
    return state.product;
  },
};
