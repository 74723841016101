import {
  Anamnesis,
  Customer,
  CustomerState,
  MedicalCertificate,
  MedicalPrescription,
  MedicalRecord,
  MedicalRecords,
  MedicalReport,
} from "@/models/interfaces/customer/customer.interface";

const customer: Customer = {
  /* physicalperson */
  id: "",
  Id: "",
  cpf: "",
  birthdate: undefined,

  /* historicphysicalperson */
  civilstatusId: undefined,
  professionId: undefined,
  genderId: undefined,
  personaltreatmentId: undefined,
  name: "",
  preferredname: "",
  anothergender: "",
  placeofbird: "",
  userId: undefined,

  /* customer */
  storeId: undefined,
  /*   active: true, */
  /* user */
  /* isuser: false,
  profileId: undefined,
  password: "", */
  /* person */
  finalconsumer: false,

  personnotes: [],

  contacts: [],

  address: {
    zipcode: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    id: "",
    ibgecode: undefined,
    stateId: undefined,
    stateAcronym: "",
    cityId: undefined,
    cityName: "",
    number: "",
    complement: "",
  },
  addressnf: {
    zipcode: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    id: "",
    ibgecode: undefined,
    stateId: undefined,
    stateAcronym: "",
    cityId: undefined,
    cityName: "",
    number: "",
    complement: "",
  },

  documents: [],

  DataNascimento: undefined,

  idCriptografado: "",
  idByCustomer: "",
};

const customers: Array<Customer> = [];

const medicalRecords: MedicalRecords = {
  cliente: {
    Id: 0,
    Nome: "",
  },
  profissional: {
    Id: 0,
    Nome: "",
    CompartilharProntuario: false,
    ResponsavelTecnico: false,
    ModelosAnamnese: [],
    ModelosAtestado: [],
    ModelosPrescricao: [],
    ModelosRelatorio: [],
  },
  atendimentos: [],
};

const medicalRecord: MedicalRecord = {
  Data: "",
  DataFormatada: "",
  Cliente: {
    Id: 0,
    Nome: "",
  },
  Atendimentos: [],
};

const anamnesis: Anamnesis = {
  fieldsAnamnesis: [],
  description: "",
};

const medicalCertificate: MedicalCertificate = {
  description: "",
  text: "",
};

const medicalPrescription: MedicalPrescription = {
  description: "",
  text: "",
};

const medicalReport: MedicalReport = {
  description: "",
  text: "",
};

export const state: CustomerState = {
  customer,
  customers,
  medicalRecords,
  medicalRecord,
  anamnesis,
  medicalCertificate,
  medicalPrescription,
  medicalReport,
};
