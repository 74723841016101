import {
  Prescription,
  PrescriptionState,
} from "@/models/interfaces/medicalconsultation/prescription.interface";

const prescription: Prescription = {
  id: "",
  description: "",
};

const prescriptions: Array<Prescription> = [];

export const state: PrescriptionState = {
  prescription,
  prescriptions,
};
