import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Prescription,
  PrescriptionState,
} from "@/models/interfaces/medicalconsultation/prescription.interface";

export const getters: GetterTree<PrescriptionState, RootState> = {
  getPrescription(state): Prescription {
    return state.prescription;
  },
};
