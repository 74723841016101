import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Profession,
  ProfessionState,
} from "@/models/interfaces/shared/profession.interface";

export const getters: GetterTree<ProfessionState, RootState> = {
  getProfession(state): Profession {
    return state.profession;
  },
};
