import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  name: yup
    .string()
    .required(messages.compile({ id: "required", value: "Nome" })),

  /* cpf: yup
    .string()
    .required(messages.compile({ id: "required", value: "CPF" })), */

  /*  addressZipcode: yup
    .string()
    .required(messages.compile({ id: "required", value: "Cep" })),
  addressType: yup
    .string()
    .required(messages.compile({ id: "required", value: "Tipo de endereço" })),
  addressStreetType: yup
    .string()
    .required(
      messages.compile({ id: "required", value: "Tipo de logradouro" })
    ),
  addressCity: yup
    .string()
    .required(messages.compile({ id: "required", value: "Cidade" })),
  addressNeighborhood: yup
    .string()
    .required(messages.compile({ id: "required", value: "Bairro" })),
  addressStreet: yup
    .string()
    .required(messages.compile({ id: "required", value: "Logradouro" })),
  addressNumber: yup
    .string()
    .required(messages.compile({ id: "required", value: "Número" })), */
});
