import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Person,
  PersonState,
} from "@/models/interfaces/shared/person.interface";
import { PersonService as person } from "@/services/shared/person.services";
import { constants } from "@/constants/shared/person";

export const actions: ActionTree<PersonState, RootState> = {
  getAll({ commit }): void {
    person.getAll().then((res: Person[]) => {
      commit(constants.SET_PERSONS, res);
    });
  },
  get({ commit }, id): void {
    person.get(id).then((res: Person) => {
      commit(constants.SET_PERSON, res);
    });
  },
  getByNameDocument({ commit }, value): void {
    person.getByNameDocument(value).then((res: Person) => {
      commit(constants.SET_PERSONS, res);
    });
  },
};
