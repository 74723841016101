import { API_URL } from "@/constants/env";
import { TypeOfPaymentMethod } from "@/models/interfaces/financial/typeofpaymentmethod.interface";
import axios from "axios";

const getAll = async (): Promise<TypeOfPaymentMethod[]> =>
  (await axios.get(`${API_URL}/config/financial/typeofpaymentmethod`)).data;

const get = async (id: string): Promise<TypeOfPaymentMethod> =>
  (await axios.get(`${API_URL}/config/financial/typeofpaymentmethod/${id}`))
    .data;

export const TypeOfPaymentMethodService = {
  getAll,
  get,
};
