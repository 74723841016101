import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Address,
  AddressState,
} from "@/models/interfaces/shared/address.interface";

export const getters: GetterTree<AddressState, RootState> = {
  getAddress(state): Address {
    return state.address;
  },
};
