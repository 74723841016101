import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PersonalTreatment,
  PersonalTreatmentState,
} from "@/models/interfaces/shared/personaltreatment.interface";
import { PersonalTreatmentService as personaltreatment } from "@/services/shared/personaltreatment.services";
import { constants } from "@/constants/shared/personaltreatment";

export const actions: ActionTree<PersonalTreatmentState, RootState> = {
  getAll({ commit }): void {
    personaltreatment.getAll().then((res: PersonalTreatment[]) => {
      commit(constants.SET_PERSONALTREATMENTS, res);
    });
  },
  get({ commit }, id): void {
    personaltreatment.get(id).then((res: PersonalTreatment) => {
      commit(constants.SET_PERSONALTREATMENT, res);
    });
  },
};
