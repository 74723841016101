import {
  MeanOfPayment,
  MeanOfPaymentState,
} from "@/models/interfaces/financial/meanofpayment.interface";

const meanOfPayment: MeanOfPayment = {
  id: "",
  description: "",
  typeofpaymentmethodId: undefined,
};

const meansOfPayment: MeanOfPayment[] = [];

export const state: MeanOfPaymentState = {
  meanOfPayment,
  meansOfPayment,
};
