import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TimeTable,
  TimeTableState,
} from "@/models/interfaces/schedule/timetable.interface";

export const getters: GetterTree<TimeTableState, RootState> = {
  getTimeTable(state): TimeTable {
    return state.timeTable;
  },
};
