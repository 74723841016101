import { RouteRecordRaw } from "vue-router";

import Consigned from "./consignedRoute";
import Loan from "./loanRoute";
import Sale from "./sale";
import Test from "./testRoute";
import Voucher from "./voucherRoute";
import WorkOrder from "./workorder";
import Budget from "./budgetRoute";
import Invoice from "./invoice";
import Bankslip from "./bankslipRoute";
import Gift from "./giftRoute";

const itens: Array<RouteRecordRaw> = [
  ...Consigned,
  ...Loan,
  ...Sale,
  ...Test,
  ...Voucher,
  ...WorkOrder,
  ...Budget,
  ...Invoice,
  ...Bankslip,
  ...Gift,
];

export default itens;
