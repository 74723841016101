import { API_URL } from "@/constants/env";
import { InvoiceConsumer } from "@/models/interfaces/commercial/invoiceconsumer.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import { emit } from "./emit.service";

export const insert = async (entity: any): Promise<InvoiceConsumer[]> => {
  entity.Critica = "";

  const entitySave = prepareData(entity);

  const invoiceConsumer = (
    await axios.post(`${API_URL}/nfe/Salvar`, entitySave)
  ).data;

  emit(invoiceConsumer);

  return invoiceConsumer;
};
