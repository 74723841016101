import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ProductOrigin,
  ProductOriginState,
} from "@/models/interfaces/shared/productorigin.interface";
import { ProductOriginService as productOrigin } from "@/services/shared/productorigin.services";
import { constants } from "@/constants/shared/productorigin";

export const actions: ActionTree<ProductOriginState, RootState> = {
  getAll({ commit }): void {
    productOrigin.getAll().then((res: ProductOrigin[]) => {
      commit(constants.SET_PRODUCTORIGINS, res);
    });
  },
  get({ commit }, id): void {
    productOrigin.get(id).then((res: ProductOrigin) => {
      commit(constants.SET_PRODUCTORIGIN, res);
    });
  },
};
