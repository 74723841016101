import {
  ProductType,
  ProductTypeState,
} from "@/models/interfaces/shared/producttype.interface";

const productType: ProductType = {
  id: "",
  description: "",
};

const productTypes: Array<ProductType> = [];

export const state: ProductTypeState = {
  productType,
  productTypes,
};
