import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Exception,
  ExceptionState,
} from "@/models/interfaces/schedule/exception.interface";
import { ExceptionService as exception } from "@/services/schedule/exception.services";
import { constants } from "@/constants/schedule/exception";

export const actions: ActionTree<ExceptionState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Exception[] = await exception.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_EXCEPTIONS, res);
  },
  get({ commit }, id): void {
    exception.get(id).then((res: Exception) => {
      commit(constants.SET_EXCEPTION, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Exception[] = await exception.insert(state.exception);
    commit(constants.INSERT_EXCEPTION, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await exception.remove(ids);
    /*     const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Exception[] = await exception.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_EXCEPTIONS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Exception[] = await exception.update(id, state.exception);
    commit(constants.UPDATE_EXCEPTION, res);
  },
};
