import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { Loan, LoanState } from "@/models/interfaces/commercial/loan.interface";
import { LoanService as service } from "@/services/commercial/loan.services";
import { constants } from "@/constants/commercial/loan";

export const actions: ActionTree<LoanState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Loan[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_LOANS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Loan) => {
      commit(constants.SET_LOAN, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Loan[] = await service.insert(state.loan);
    commit(constants.INSERT_LOAN, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Loan[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_LOANS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Loan[] = await service.update(id, state.loan);
    commit(constants.UPDATE_LOAN, res);
  },
};
