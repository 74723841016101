import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Consigned,
  ConsignedState,
} from "@/models/interfaces/commercial/consigned.interface";

export const getters: GetterTree<ConsignedState, RootState> = {
  getConsigned(state): Consigned {
    return state.consigned;
  },
};
