import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  SpsadtGuide,
  SpsadtGuideState,
} from "@/models/interfaces/billing/spsadtguide.interface";

export const getters: GetterTree<SpsadtGuideState, RootState> = {
  getSpsadtGuide(state): SpsadtGuide {
    return state.spsadtGuide;
  },
};
