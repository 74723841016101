import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  FinancialCategory,
  FinancialCategoryState,
} from "@/models/interfaces/financial/financialCategory.interface";
import { FinancialCategoryService as service } from "@/services/financial/financialCategory.services";
import { constants } from "@/constants/financial/financialcategory";

export const actions: ActionTree<FinancialCategoryState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: FinancialCategory[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_FINANCIALCATEGORIES, res);
  },
  getAllDad({ commit }): void {
    service.getAllDad().then((res: FinancialCategory[]) => {
      commit(constants.SET_FINANCIALCATEGORIES, res);
    });
  },
  getAllByType({ commit }, { type }): void {
    service.getAllByType(type).then((res: FinancialCategory[]) => {
      commit(constants.SET_FINANCIALCATEGORIES, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: FinancialCategory) => {
      commit(constants.SET_FINANCIALCATEGORY, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: FinancialCategory[] = await service.insert(
      state.financialCategory
    );
    commit(constants.INSERT_FINANCIALCATEGORY, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*  const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: FinancialCategory[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_FINANCIALCATEGORIES, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: FinancialCategory[] = await service.update(
      id,
      state.financialCategory
    );
    commit(constants.UPDATE_FINANCIALCATEGORY, res);
  },
};
