export default (data: any) => {
  const { product, valor, cenarioIcms, taxation, recipient } = data;

  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const ufRecipient = recipient.endereco.estado;

  const cenariosIcmsSt = ncm.CenarioIcmsSts.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioIcmsSt = cenariosIcmsSt[0];

  const cenariosInterestadual = ncm.CenarioIcmsInterestaduais.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioInterestadual = cenariosInterestadual[0];

  const cst = cenarioIcms.Cst.toString();

  const aliquotaInterna = cenarioInterestadual.AliquotaInternaUfDestino;
  const aliquotaSt = cenarioIcmsSt.Aliquota;
  const aliquotaInterestadual = cenarioInterestadual.AliquotaInterestadualUfs;

  const mva = 1 + cenarioIcmsSt.MargemValorAdicionado / 100;

  const valorBc = valor * mva;

  const icmsStDevidoDestino = (valorBc * aliquotaInterna) / 100;

  const icmsASerCreditadoOrigem = (valorBc * aliquotaInterestadual) / 100;

  const valorIcmsStUfDestino = icmsStDevidoDestino - icmsASerCreditadoOrigem;

  return {
    origem: product.OrigemCodigo.toString(),
    cst,
    substituicaoTributaria: {
      aliquota: aliquotaSt,
      baseCalculo: {
        valor: valorBc,
      },
      ufDestino: {
        baseCalculo: {
          valor,
        },
        valor: valorIcmsStUfDestino < 0 ? 0 : valorIcmsStUfDestino,
      },
    },
  };
};
