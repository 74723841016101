import {
  IssuingAuthority,
  IssuingAuthorityState,
} from "@/models/interfaces/shared/issuingauthority.interface";

const issuingAuthority: IssuingAuthority = {
  id: "",
  description: "",
};

const issuingAuthorities: Array<IssuingAuthority> = [];

export const state: IssuingAuthorityState = {
  issuingAuthority,
  issuingAuthorities,
};
