import {
  SupplierState,
  Supplier,
} from "@/models/interfaces/material/supplier.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<SupplierState> = {
  setSuppliers(state, payload: Supplier[]) {
    state.suppliers = payload;
  },

  setSupplier(state, payload: Supplier) {
    state.supplier = payload;
  },

  insertSupplier(state, payload: Supplier) {
    /* state.supplier = payload; */
  },

  removeSupplier(state, payload: Supplier) {
    /* state.supplier = payload; */
  },

  updateSupplier(state, payload: Supplier) {
    /* state.supplier = payload; */
  },
};
