import {
  CashManagementState,
  DailyCashFlow,
} from "@/models/interfaces/financial/cashmanagement.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CashManagementState> = {
  setCashManagements(state, payload: DailyCashFlow[]) {
    state.dailyCashFlows = payload;
  },

  setCashManagement(state, payload: DailyCashFlow) {
    state.dailyCashFlow = payload;
  },

  insertCashManagement(state, payload: DailyCashFlow) {
    state.dailyCashFlow = payload;
  },

  removeCashManagement(state, payload: DailyCashFlow) {
    state.dailyCashFlow = payload;
  },

  updateCashManagement(state, payload: DailyCashFlow) {
    state.dailyCashFlow = payload;
  },
};
