import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TypeOfPaymentMethod,
  TypeOfPaymentMethodState,
} from "@/models/interfaces/financial/typeofpaymentmethod.interface";

export const getters: GetterTree<TypeOfPaymentMethodState, RootState> = {
  getTypeOfPaymentMethod(state): TypeOfPaymentMethod {
    return state.typeOfPaymentMethod;
  },
};
