import { API_URL } from "@/constants/env";
import { InvoiceConsumer } from "@/models/interfaces/commercial/invoiceconsumer.interface";
import axios from "axios";
import { prepareData } from "./preparedata.service";
import { emit } from "./emit.service";

export const update = async (
  id: string,
  entity: InvoiceConsumer
): Promise<InvoiceConsumer[]> => {
  entity.Critica = "";
  const invoiceConsumer = (
    await axios.post(`${API_URL}/nfe/Salvar`, prepareData(entity))
  ).data;

  emit(entity);

  return invoiceConsumer;
};
