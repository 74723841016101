import {
  SaleConsumer,
  SaleConsumerState,
} from "@/models/interfaces/commercial/saleConsumer.interface";

const saleConsumer: SaleConsumer = {
  id: "",
  personId: 0,
  storeId: 0,
  salemanId: 0,
  userId: 0,
  canceldate: undefined,
  voucherId: undefined,
  noteofsale: [],
  saleitem: [],
  accountReceivable: [],
  ProfissionalId: 0,
  newInvoice: false,
  ContaId: 0,
  Id: "",
  MeioPagamentoId: 0,
  ClienteId: 0,
  ClienteIdCriptografado: "",
  Invoice: "",
  Presencial: 1,
  ConsumidorFinal: 1,
  TipoVenda: 2,
  VendaId: 0,
};

const salesConsumer: Array<SaleConsumer> = [];

export const state: SaleConsumerState = {
  saleConsumer,
  salesConsumer,
};
