import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Product,
  ProductState,
} from "@/models/interfaces/material/product.interface";
import { ProductService as service } from "@/services/material/product";
import { constants } from "@/constants/material/product";

export const actions: ActionTree<ProductState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Product[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_PRODUCTS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Product) => {
      commit(constants.SET_PRODUCT, res);
    });
  },
  async getByDescriptionSerialForAutoComplete(
    { commit },
    filter
  ): Promise<void> {
    const res = await service.getByDescriptionSerialForAutoComplete(filter);

    commit(constants.SET_PRODUCTSSTORE, res);
  },
  async getByDescriptionForAutoComplete({ commit }, filter): Promise<void> {
    const res = await service.getByDescriptionForAutoComplete(filter);

    commit(constants.SET_PRODUCTSSTORE, res);
  },
  async codeVerify({ commit }, filter): Promise<void> {
    const res = await service.getByCode(filter);
    commit(constants.SET_RESULTCODEVERIFY, res);
  },
  async getByProductCode({ commit }, filter): Promise<Product> {
    return await service.getByCode(filter);
    /* commit(constants.SET_RESULTCODEVERIFY, res); */
  },
  async insert({ commit, state }): Promise<void> {
    const res: Product[] = await service.insert(state.product);
    commit(constants.INSERT_PRODUCT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /*     const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Product[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_PRODUCTS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Product[] = await service.update(id, state.product);
    commit(constants.UPDATE_PRODUCT, res);
  },
};
