import {
  BrandState,
  Brand,
} from "@/models/interfaces/material/brand.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<BrandState> = {
  setBrands(state, payload: Brand[]) {
    state.brands = payload;
  },

  setBrand(state, payload: Brand) {
    state.brand = payload;
  },

  insertBrand(state, payload: Brand) {
    state.brand = payload;
  },

  removeBrand(state, payload: Brand) {
    state.brand = payload;
  },

  updateBrand(state, payload: Brand) {
    state.brand = payload;
  },
};
