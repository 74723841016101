import {
  ChecklistState,
  Checklist,
} from "@/models/interfaces/commercial/checklist.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ChecklistState> = {
  setChecklists(state, payload: Checklist[]) {
    state.checklists = payload;
  },

  setChecklist(state, payload: Checklist) {
    state.checklist = payload;
  },

  insertChecklist(state, payload: Checklist) {
    state.checklist = payload;
  },

  removeChecklist(state, payload: Checklist) {
    state.checklist = payload;
  },

  updateChecklist(state, payload: Checklist) {
    state.checklist = payload;
  },
};
