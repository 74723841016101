import { RouteRecordRaw } from "vue-router";
import Batch from "../../views/billing/batch/Index.vue";
import BatchList from "../../views/billing/batch/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/billing/batch/:id",
  name: "Batch",
  component: Batch,
  beforeEnter: Auth,
});
itens.push({
  path: "/billing/batch/list",
  name: "BatchList",
  component: BatchList,
  beforeEnter: Auth,
});

export default itens;
