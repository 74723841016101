import { get } from "./get.service";
import { getAll } from "./getall.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { stockTransfer } from "./stockTransfer.service";
import { update } from "./update.service";

export const ProductOrderService = {
  getAll,
  get,
  insert,
  remove,
  update,
  stockTransfer,
};
