import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  DocumentType,
  DocumentTypeState,
} from "@/models/interfaces/shared/documenttype.interface";

export const getters: GetterTree<DocumentTypeState, RootState> = {
  getDocumentType(state): DocumentType {
    return state.documentType;
  },
};
