import { get } from "./get.service";
import { getAll } from "./getall.service";
import { insert } from "./insert.service";
import { redeem } from "./redeem.service";
import { remove } from "./remove.service";
import { setVoucher } from "./setvoucher.service";
import { update } from "./update.service";

export const VoucherService = {
  getAll,
  get,
  insert,
  remove,
  update,
  setVoucher,
  redeem,
};
