import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Reason,
  ReasonState,
} from "@/models/interfaces/schedule/reason.interface";

export const getters: GetterTree<ReasonState, RootState> = {
  getReason(state): Reason {
    return state.reason;
  },
};
