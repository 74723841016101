import { API_INVOICE_URL, X_API_KEY, API_URL } from "@/constants/env";
import axios from "axios";

export const cancel = async (
  id: number,
  chave: string,
  reason: string,
  status: number
) => {
  if (!!chave && !(status == 5 || status == 7 || status == 4)) {
    try {
      const dataInvoice = {
        justificativa: reason,
      };

      const invoice = (
        await axios.post(
          `${API_INVOICE_URL}/nfe/${chave}/cancelamento`,
          dataInvoice,
          {
            headers: {
              "X-API-KEY": X_API_KEY,
            },
          }
        )
      ).data;
      const url = `${API_URL}/nfe/updatestatus?id=${id}&status=7`;
      await axios.get(`${url}`);
    } catch (ex: any) {
      console.log("Error: ", ex.response.data);

      const url = `${API_URL}/nfe/updatestatus?id=${id}&status=5`;
      await axios.get(`${url}`);
    }
  }
};
