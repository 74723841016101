import { RouteRecordRaw } from "vue-router";
import SpsadtGuide from "../../views/billing/spsadtguide/Index.vue";
import SpsadtGuideList from "../../views/billing/spsadtguide/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/billing/spsadt-guide/:id",
  name: "SpsadtGuide",
  component: SpsadtGuide,
  beforeEnter: Auth,
});
itens.push({
  path: "/billing/spsadt-guide/list",
  name: "SpsadtGuideList",
  component: SpsadtGuideList,
  beforeEnter: Auth,
});

export default itens;
