export default (data: any) => {
  const { product, valor, cenarioIcms, taxation, recipient } = data;

  const origem = product.OrigemCodigo.toString();

  const ncms = taxation.Ncms.filter((ncm: any) => ncm.Codigo == product.Ncm);
  const ncm = ncms[0];

  const ufRecipient = recipient.endereco.estado;

  const cenariosIcmsSt = ncm.CenarioIcmsSts.filter(
    (cenario: any) => cenario.UfSigla == ufRecipient
  );
  const cenarioIcmsSt = cenariosIcmsSt[0];

  const cst = cenarioIcms.Cst.toString();

  const aliquota = cenarioIcmsSt.Aliquota;

  const valorBc = valor;

  return {
    origem,
    cst,
    substituicaoTributaria: {
      aliquota,
      baseCalculo: {
        valor: valorBc,
      },
    },
  };
};
