import {
  OtherGuide,
  OtherGuideState,
} from "@/models/interfaces/billing/otherguide.interface";

const otherGuide: OtherGuide = {
  id: "",
  description: "",
};

const otherGuides: Array<OtherGuide> = [];

export const state: OtherGuideState = {
  otherGuide,
  otherGuides,
};
