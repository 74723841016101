import {
  TaxScenario,
  TaxScenarioState,
} from "@/models/interfaces/commercial/taxscenario.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TaxScenarioState> = {
  setTaxScenarios(state, payload: TaxScenario[]) {
    state.taxScenarios = payload;
  },

  setTaxScenario(state, payload: TaxScenario) {
    state.taxScenario = payload;
  },

  insertTaxation(state, payload: TaxScenario) {
    state.taxScenario = payload;
  },

  removeTaxation(state, payload: TaxScenario) {
    state.taxScenario = payload;
  },

  updateTaxation(state, payload: TaxScenario) {
    state.taxScenario = payload;
  },
};
