import {
  InvoiceProduct,
  InvoiceProductState,
} from "@/models/interfaces/commercial/invoiceproduct.interface";

const invoiceProduct: InvoiceProduct = {
  id: "",
  ChaveAcesso: "",
  nfeitem: [],
  CfopId: 0,
  ClienteId: 0,
  Cnpj: "",
  ConsumidorFinal: 0,
  DataEmissaoFormatada: "",
  FinalidadeOperacao: 0,
  Id: 0,
  IdCliente: 0,
  Natureza: "",
  NumeroNf: 0,
  Observacao: "",
  Presencial: 0,
  Saida: 0,
  StatusNfe: 0,
  TipoCliente: 0,
  TipoNf: 0,
  Usuario: 0,
  CenarioImpostosId: 0,
};

const invoicesProduct: Array<InvoiceProduct> = [];

export const state: InvoiceProductState = {
  invoiceProduct,
  invoicesProduct,
};
