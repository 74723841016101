import {
  Employee,
  EmployeeState,
} from "@/models/interfaces/administration/employee.interface";

const employee: Employee = {
  /* physicalperson */
  id: "",
  cpf: "",
  CPF: "",
  birthdate: undefined,

  /* historicphysicalperson */
  civilstatusId: undefined,
  professionId: undefined,
  genderId: undefined,
  personaltreatmentId: undefined,
  name: "",
  preferredname: "",
  anothergender: "",
  placeofbird: "",
  userId: undefined,

  /* employee */
  storeId: undefined,
  /*   active: true, */
  /* salesman */
  issalesman: false,

  /* user */
  isuser: false,
  profileId: undefined,
  password: "",

  /* person */
  finalconsumer: false,

  personnotes: [],

  contacts: [],

  address: {
    zipcode: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    id: "",
    ibgecode: undefined,
    stateId: undefined,
    stateAcronym: "",
    cityId: undefined,
    cityName: "",
    number: "",
    complement: "",
  },

  documents: [],
  Id: "",
  Senha: "",
};

const employees: Array<Employee> = [];

export const state: EmployeeState = {
  employee,
  employees,
};
