import { Test, TestState } from "@/models/interfaces/commercial/test.interface";

const test: Test = {
  id: "",
  customerId: 0,
  note: "",
  testitem: [],
};

const tests: Array<Test> = [];

export const state: TestState = {
  test,
  tests,
};
