import { API_URL, BANKSLIP, API_BANKSLIP_URL } from "@/constants/env";
import axios from "axios";
import { useDate } from "@/composables/date";
import store from "@/store";
import Enterprise from "@/components/selected/Enterprise.vue";
import { prepareData } from "./prepareData";

export const updateBankslipStatus = async () => {
  const credential = { ...store.state.login.credential };

  const accountsreceivable: any = (
    await axios.get(
      `${API_URL}/receita/getPendingBankslip?tenant=${credential.tenantId}&store=${credential.storeId}`
    )
  ).data;

  if (accountsreceivable.length == 0) return;

  const account: any = (
    await axios.get(
      `${API_URL}/conta/getId?id=${accountsreceivable[0].ContaId}`
    )
  ).data;

  const credentialsBankslip = BANKSLIP.filter(
    item => item.beneficiario == account.Carteira
  );
  const credentialBankslip = credentialsBankslip[0];

  const bankslip = {
    clientId: credentialBankslip.clientId, //"2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
    urlCoperativa: credentialBankslip.urlCoperativa, //"svc.bnf.homolog", //usuario
    codCoperativa: credentialBankslip.codCoperativa, //"uL&CtJ5J", //senha
    idBeneficiario: account.Carteira, //"162B62923A8B46CFB5E567A23E2D621A",
    cooperativa: account.CedenteCodigo, //9184,
    id: "",
  };

  for (const accountreceivable of accountsreceivable) {
    bankslip.id = accountreceivable.Invoice;

    if (bankslip.id) {
      try {
        const response = await axios.post(
          `${API_BANKSLIP_URL}/boletos/unicred/status`,
          bankslip
        );

        const result = response.data;

        console.log(response.data);

        /*  atualizar status */
        if (result.status == "LIQUIDADO") {
          const date = result.pagamentos[0].data.split("-");
          const paymentDate = `${date[2]}/${date[1]}/${date[0]}`;
          accountreceivable.DataPagamentoFormatada = paymentDate;
          const data = prepareData(accountreceivable);
          const r = (await axios.post(`${API_URL}/receita/Salvar`, data)).data;
        }
      } catch (ex: any) {
        console.log("erro ao atualizar boleto", ex);
        try {
          accountreceivable.Erro = ex.response.data.body.reduce(
            (resultado: string, item: any) => {
              resultado += `${item.message};`;
              return resultado;
            },
            `${ex.response.data.message}: `
          );
        } catch (e: any) {
          accountreceivable.Erro = ex.response.data.message;
        }
      }
    }
  }
};
