// messages.js
interface Message {
  [key: string]: string;
}

const schema: Message = {
  required: "O campo #campo é obrigatório.",
  email: "O e-mail inserido não é válido.",
  cep: "O cep inserido não é válido.",
  telefone:
    "Por favor, adicione o campo telefone ou celular à lista de contato.",
  requiredList: "Por favor, adicione pelo menos um item à lista #campo.",
  saleCustomer:
    "Os campos E-mail, Endereço e Cpf ou o cadastro completo do responsável pelo paciente, são obrigatórios.",
  giftCustomer:
    "Os campos E-mail, Endereço e Cpf ou o cadastro completo do responsável pelo paciente, são obrigatórios.",
};

export default {
  compile: (message: Message) => {
    const messageValue = schema[message.id];
    return messageValue ? messageValue.replace("#campo", message.value) : "";
  },
  schema,
};
