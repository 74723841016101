import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Treatment,
  TreatmentState,
} from "@/models/interfaces/commercial/treatment.interface";

export const getters: GetterTree<TreatmentState, RootState> = {
  getTreatment(state): Treatment {
    return state.treatment;
  },
};
