import { RouteRecordRaw } from "vue-router";

import Administration from "./administration";
import Commercial from "./commercial";
import Dashboard from "./dashboard";
import Financial from "./financial";
import Material from "./material";
import Schedule from "./schedule";
import Billing from "./billing";
import MedicalConsultation from "./medicalconsultation";

const itens: Array<RouteRecordRaw> = [
  ...Administration,
  ...Commercial,
  ...Dashboard,
  ...Financial,
  ...Material,
  ...Schedule,
  ...Billing,
  ...MedicalConsultation,
];

export default itens;
