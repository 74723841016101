import { API_URL } from "@/constants/env";
import { ProductStore } from "@/models/interfaces/material/product.interface";
import store from "@/store";
import axios from "axios";

export const getByDescriptionSerialForAutoComplete = async (
  filter: string
): Promise<any[]> => {
  if (filter.length > 2) {
    const credential = { ...store.state.login.credential };

    const list: Array<ProductStore> = (
      await axios.get(
        `${API_URL}/produtoconf/getestoqueporprodutoserie?cliente=${credential.tenantId}&unidade=${credential.storeId}&filtro=${filter}`
      )
    ).data;

    const products: ProductStore[] = list.map((item: ProductStore) => {
      const description = item.serial
        ? `${item.serial} - ${item.name}`
        : item.name;
      const entity: ProductStore = {
        id: item.id,
        idProduct: item.idProduct,
        name: item.name,
        description,
        value: Number(item.value.toString().replace(",", ".")),
        serial: item.serial,
        amount: item.amount,
        maxdiscount: item.maxdiscount,
        useserialnumber: item.useserialnumber,
      };

      //todo: recuperar serial number
      return entity;
    });

    return products;
  }
  return [];
};
