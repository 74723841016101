import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PassingOn,
  PassingOnState,
} from "@/models/interfaces/financial/passingon.interface";
import { PassingOnService as service } from "@/services/financial/passingon.services";
import { constants } from "@/constants/financial/passingon";

export const actions: ActionTree<PassingOnState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: PassingOn[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_PASSINGONS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: PassingOn) => {
      commit(constants.SET_PASSINGON, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: PassingOn[] = await service.insert(state.passingOn);
    commit(constants.INSERT_PASSINGON, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    /* const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: PassingOn[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_PASSINGONS, res); */
  },
  async update({ commit, state }, id): Promise<void> {
    const res: PassingOn[] = await service.update(id, state.passingOn);
    commit(constants.UPDATE_PASSINGON, res);
  },
};
