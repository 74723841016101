import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Unit } from "@/models/interfaces/administration/unit.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "unidade").fetchData();

const getAllSchedule = async () => {
  const credential = { ...store.state.login.credential };
  const customer = credential.tenantId;
  const user = credential.userId;
  const profile = credential.profileId;

  let url = `${API_URL}/unidade/todos?cliente=${customer}`;

  if (profile > 1) {
    url += `&profissionalLogado=${user}`;
  }

  const entity = (await axios.get(url)).data;

  return entity;
};

const get = async (id: string): Promise<Unit> => {
  if (id == "new") {
    const entity: Unit = {
      /* legalperson */
      id: "",
      cnpj: "",

      /* historiclegalperson */
      corporatename: "",
      fantasyname: "",
      userId: 1,

      /* unit */
      description: "",
      cantransferinventory: false,
      businessgroupId: undefined,

      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      addresses: [],

      documents: [],
    };

    return entity;
  }

  const entity = (
    await axios.get(`${API_URL}/config/administration/unit/${id}`)
  ).data;

  const { cantransferinventory, finalconsumer, ...updateEntity } = entity;

  const treatedEntity = {
    ...updateEntity,
    cantransferinventory: cantransferinventory == 1,
    finalconsumer: finalconsumer == 1,
  };

  if (treatedEntity.personnotes.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};

const getById = async (id: number): Promise<Unit> =>
  (await axios.get(`${API_URL}/unidade/getid?id=${id}`)).data;

const insert = async (entity: Unit): Promise<Unit[]> => {
  return (
    await axios.post(
      `${API_URL}/config/administration/unit`,
      prepareData(entity)
    )
  ).data;
};

const remove = async (id: string): Promise<Unit[]> =>
  (await axios.delete(`${API_URL}/config/administration/unit/${id}`)).data;

const update = async (id: string, entity: Unit): Promise<Unit[]> => {
  return (
    await axios.put(
      `${API_URL}/config/administration/unit/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: Unit) => {
  const updateEntity = {
    cnpj: entity.cnpj,
    corporatename: entity.corporatename,
    fantasyname: entity.fantasyname,
    userId: entity.userId,
    description: entity.description,
    businessgroupId: entity.businessgroupId,
    cantransferinventory: entity.cantransferinventory,
    finalconsumer: entity.finalconsumer,
    personnotes: entity.personnotes,
    contacts: entity.contacts,
    addresses: entity.addresses,
    documents: entity.documents,
    // rooms: entity.rooms,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { id, ...updateEntity } = entity;

  return updateEntity;
};

export const UnitService = {
  getAll,
  getAllSchedule,
  get,
  getById,
  insert,
  remove,
  update,
};
