import { RouteRecordRaw } from "vue-router";
import Budget from "../../views/commercial/budget/Index.vue";
import Budgets from "../../views/commercial/budget/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/budget/:id",
  name: "Budget",
  component: Budget,
});
itens.push({
  path: "/commercial/budget/list",
  name: "Budgets",
  component: Budgets,
  beforeEnter: Auth,
});

export default itens;
