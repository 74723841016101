import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Gender,
  GenderState,
} from "@/models/interfaces/shared/gender.interface";

export const getters: GetterTree<GenderState, RootState> = {
  getGender(state): Gender {
    return state.gender;
  },
};
