import { GetterTree } from "vuex";
import { RootState } from "@/store/type";
import {
  WorkOrder,
  WorkOrderState,
} from "@/models/interfaces/commercial/workorder.interface";

export const getters: GetterTree<WorkOrderState, RootState> = {
  getWorkOrder(state): WorkOrder {
    return state.workorder;
  },
};
