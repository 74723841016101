import {
  Treatment,
  TreatmentState,
} from "@/models/interfaces/commercial/treatment.interface";

const treatment: Treatment = {
  id: "",
  description: "",
};

const treatments: Array<Treatment> = [];

export const state: TreatmentState = {
  treatment,
  treatments,
};
