export const treatedData = (entity: any): any => {
  if (entity.DataFormatada) {
    const parts = entity.DataFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataFormatada = new Date(isoDateString).toISOString().split("T")[0];
  }

  for (let i = 0; i < entity.Itens.length; i++) {
    entity.Itens[i].useserialnumber = !!entity.Itens[i].ProdutoSerieObrigatorio;
  }

  return entity;
};
