import {
  Gender,
  GenderState,
} from "@/models/interfaces/shared/gender.interface";

const gender: Gender = {
  id: "",
  description: "",
};

const genders: Array<Gender> = [];

export const state: GenderState = {
  gender,
  genders,
};
