import { API_URL } from "@/constants/env";
import { SaleConsumer } from "@/models/interfaces/commercial/saleConsumer.interface";
import axios from "axios";

export const get = async (id: string): Promise<SaleConsumer> => {
  if (id == "new" || id == "" || id == undefined) {
    const entity: SaleConsumer = {
      id: "",
      personId: 0,
      storeId: 0,
      salemanId: 0,
      userId: 0,
      canceldate: undefined,
      voucherId: undefined,
      noteofsale: [],
      saleitem: [],
      accountReceivable: [],
      ProfissionalId: 0,
      newInvoice: false,
      ContaId: 0,
      Id: "",
      MeioPagamentoId: 0,
      ClienteId: 0,
      ClienteIdCriptografado: "",
      Invoice: "",
      Presencial: 1,
      ConsumidorFinal: 1,
      TipoVenda: 2,
      VendaId: 0,
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/venda?id=${id}`)).data;

  entity.ConsumidorFinal = 1;
  entity.Presencial = 1;

  entity.saleitem = (
    await axios.get(`${API_URL}/venda/getitens?venda=${entity.Id}`)
  ).data;

  entity.accountReceivable = (
    await axios.get(`${API_URL}/receita/GetParcelasVenda?venda=${entity.Id}`)
  ).data;

  if (entity.accountReceivable.length == 1)
    entity.accountReceivable[0].installmentNumber = "À vista";
  else {
    entity.accountReceivable = entity.accountReceivable.map(
      (
        installment: {
          installmentNumber: any;
          DataVencimentoFormatada: string;
          DueDate: string;
        },
        index: number
      ) => {
        installment.installmentNumber = index == 0 ? "Entrada" : index;

        installment.DueDate = prepareDate(installment.DataVencimentoFormatada);

        return installment;
      }
    );
  }

  return entity;
};

function prepareDate(date: string) {
  // Se estiver no formato 'dd/mm/yyyy', converte para 'yyyy-mm-dd'
  const partes = date.split("/");
  const dataIso = partes[2] + "-" + partes[1] + "-" + partes[0];

  return dataIso;
}
