import {
  CostCenterState,
  CostCenter,
} from "@/models/interfaces/financial/costcenter.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CostCenterState> = {
  setCostsCenter(state, payload: CostCenter[]) {
    state.costsCenter = payload;
  },

  setCostCenter(state, payload: CostCenter) {
    state.costCenter = payload;
  },

  insertCostCenter(state, payload: CostCenter) {
    state.costCenter = payload;
  },

  removeCostCenter(state, payload: CostCenter) {
    state.costCenter = payload;
  },

  updateCostCenter(state, payload: CostCenter) {
    state.costCenter = payload;
  },
};
