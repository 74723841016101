export default (data: any) => {
  const { product, cenarioIcms } = data;
  return {
    origem: product.OrigemCodigo.toString(),
    cst: cenarioIcms.Cst.toString(),
    creditoSimplesNacional: {
      percentual: 0,
      valor: 0,
    },
  };
};
