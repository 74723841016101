import { RouteRecordRaw } from "vue-router";
import Loan from "../../views/commercial/loan/Index.vue";
import Loans from "../../views/commercial/loan/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/commercial/loan/:id",
  name: "Loan",
  component: Loan,
  beforeEnter: Auth,
});
itens.push({
  path: "/commercial/loan/list",
  name: "Loans",
  component: Loans,
  beforeEnter: Auth,
});

export default itens;
