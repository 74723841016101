import { RouteRecordRaw } from "vue-router";
import Brand from "@/views/config/material/brand/Index.vue";
import Brands from "@/views/config/material/brand/List.vue";
import MaterialMenu from "@/components/menu/MaterialMenu.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/config/material/brand/:id",
  name: "Brand",
  components: {
    default: Brand,
    sidebar: MaterialMenu,
  },
  beforeEnter: Auth,
});
itens.push({
  path: "/config/material/brand/list",
  name: "Brands",
  components: {
    default: Brands,
    sidebar: MaterialMenu,
  },
  beforeEnter: Auth,
});

export default itens;
