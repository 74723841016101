import {
  Service,
  ServiceState,
} from "@/models/interfaces/commercial/service.interface";

const service: Service = {
  id: "",
  description: "",
  code: "",
  price: 0,
  taxationId: undefined,
  cnaeId: undefined,
  Id: "",
  TempoMedio: 0,
};

const services: Array<Service> = [];

export const state: ServiceState = {
  service,
  services,
};
