import { StateState, State } from "@/models/interfaces/shared/state.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<StateState> = {
  setStates(state, payload: State[]) {
    state.states = payload;
  },

  setState(state, payload: State) {
    state.state = payload;
  },
};
