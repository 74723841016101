import {
  UnitOfMeasurementState,
  UnitOfMeasurement,
} from "@/models/interfaces/shared/unitofmeansurement.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<UnitOfMeasurementState> = {
  setUnitsOfMeasurement(state, payload: UnitOfMeasurement[]) {
    state.unitsOfMeasurement = payload;
  },

  setUnitOfMeasurement(state, payload: UnitOfMeasurement) {
    state.unitOfMeasurement = payload;
  },
};
