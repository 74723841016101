import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  user: yup
    .string()
    .required(messages.compile({ id: "required", value: "E-mail ou CPF" })),
  password: yup
    .string()
    .required(messages.compile({ id: "required", value: "Senha" })),
});
