import {
  PartnershipReport,
  PartnershipReportState,
} from "@/models/interfaces/medicalconsultation/partnershipreport.interface";

const partnershipreport: PartnershipReport = {
  id: "",
  description: "",
};

const partnershipreports: Array<PartnershipReport> = [];

export const state: PartnershipReportState = {
  partnershipreport,
  partnershipreports,
};
