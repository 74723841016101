import {
  MeanOfPaymentState,
  MeanOfPayment,
} from "@/models/interfaces/financial/meanofpayment.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<MeanOfPaymentState> = {
  setMeansOfPayment(state, payload: MeanOfPayment[]) {
    state.meansOfPayment = payload;
  },

  setMeanOfPayment(state, payload: MeanOfPayment) {
    state.meanOfPayment = payload;
  },

  insertMeanOfPayment(state, payload: MeanOfPayment) {
    state.meanOfPayment = payload;
  },

  removeMeanOfPayment(state, payload: MeanOfPayment) {
    state.meanOfPayment = payload;
  },

  updateMeanOfPayment(state, payload: MeanOfPayment) {
    state.meanOfPayment = payload;
  },
};
