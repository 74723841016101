import axios from "axios";
import { API_URL } from "@/constants/env";

export default function useLocalStorage(description: string, data?: any[]) {
  const getAll = async (url?: string) => {
    const r: any[] = [];

    const storedData = localStorage.getItem(description);

    if (!storedData) {
      if (data) {
        r.push(...data);
      } else {
        if (url) r.push(...(await axios.get(`${API_URL}${url}`)).data);
      }
      localStorage.setItem(description, JSON.stringify(r));
    } else {
      r.push(...JSON.parse(storedData));
    }

    return r;
  };

  const get = async (id: string, url?: string): Promise<any> => {
    const storedData = localStorage.getItem(description);

    if (!storedData) {
      const r: any[] = [];
      if (data) {
        return data.find(item => item.id === id);
      } else {
        if (url) r.push(...(await axios.get(`${API_URL}${url}`)).data);
        return r.find((item: any) => item.id === id);
      }
    } else {
      const parsedData = JSON.parse(storedData);
      return parsedData.find((item: any) => item.id === id);
    }
  };

  const getBy = async (
    field: string,
    value: string,
    url?: string
  ): Promise<any> => {
    const storedData = localStorage.getItem(description);

    if (!storedData) {
      const r: any[] = [];
      if (data) {
        return data.filter(item => item[field] === value);
      } else {
        if (url) r.push(...(await axios.get(`${API_URL}${url}`)).data);

        localStorage.setItem(description, JSON.stringify(r));
        return r.filter((item: any) => item[field] === value);
      }
    } else {
      const parsedData = JSON.parse(storedData);

      return parsedData.filter((item: any) => item[field] === value);
    }
  };

  return {
    getAll,
    get,
    getBy,
  };
}
